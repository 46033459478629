import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { DriverType, TransferType } from "lib/types/api-type";
import { useEffect, useState } from "react";

interface PropsType {
  open: boolean;
  transfers: TransferType[];
  type: string;
  drivers: DriverType[];
  onClose: () => void;
  onConfirm: (transfer: TransferType) => void;
}

const AddHPToTransfer = ({ transfers, open, type, onClose, onConfirm, drivers }: PropsType) => {
  const [transfer, setTransfer] = useState<TransferType>();

  const handleChangeCar = (event: SelectChangeEvent) => {
    setTransfer(transfers?.find((transfer) => transfer.id === event.target.value));
  };
  useEffect(() => {
    if (!open) {
      setTransfer(undefined);
    }
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <DialogContent>
        <MDBox mb={2}>
          <FormControl fullWidth>
            <InputLabel>select transfer</InputLabel>
            <Select
              sx={{
                ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                  minHeight: "46px",
                },
              }}
              value={transfer?.id || ""}
              label="select transfer"
              onChange={handleChangeCar}
            >
              {transfers?.map(
                (transfer, index) =>
                  transfer.side === type && (
                    <MenuItem key={index} value={transfer.id}>
                      {transfer.car_code} {transfer.driver_name} #{transfer.index}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        </MDBox>
        <MDButton fullWidth color="primary" onClick={() => onConfirm(transfer)}>
          add to transfer
        </MDButton>
      </DialogContent>
    </Dialog>
  );
};

export default AddHPToTransfer;
