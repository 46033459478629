import { PrepareTableType, TableRowType, FlightType, HotelType } from "lib/types";
import { formatDate, realDateHandler } from "./dateHandler";

// {
//   indra: "",
//   id: "",
//   arrival_time: "",
//   arrival_info: "",
//   departure_time: "",
//   departure_info: "",
//   hotel_id: "",
//   age_type: "",
//   phone: "",
//   fullname: "",
// };

// Pax: 3
// آژانس: "سفر توران"
// ایندرا: 1037677179
// بازه: 4
// برگشت: 44678
// تاریخ سفارش: 44672
// تغییرات: 0
// حمل و نقل برگشت: "قشم ایر"
// حمل و نقل رفت: "ماهان ایر"
// ردیف: 3
// رفت: 44674
// سن: "کودک بدون تخت"
// شماره برگشت: 2214
// شماره تماس سفارش: 9121831315
// شماره رفت: 112
// نام: "Sarehsadat Pourrafieian"
// نام سرپرست: "Sarehsadat Pourrafieian"
// هتل: "Four Sides Taksim Lion Hotel&Spa (استانبول)"

export const prepareTable: PrepareTableType = (data) => {
  const table: TableRowType[] = [];

  data.forEach((d) => {
    const row: TableRowType = {
      indra: "",
      id: "",
      arrival_time: "",
      arrival_info: "",
      departure_time: "",
      departure_info: "",
      hotel_id: "",
      age_type: "",
      phone: "",
      fullname: "",
    };
    row.id = d["ایندرا"] || d["شماره سفارش"];
    row.indra = d["ایندرا"] || d["شماره سفارش"];
    row.arrival_time = formatDate(d["رفت"]);
    row.arrival_info = d["شماره رفت"];
    row.departure_time = formatDate(d["برگشت"]);
    row.departure_info = d["شماره برگشت"];
    row.hotel_id = d["هتل"];
    row.age_type =
      d["سن"] === "بزرگسال"
        ? "adult"
        : d["سن"] === "کودک با تخت"
        ? "child"
        : d["سن"] === "کودک بدون تخت" || d["سن"] === "نوزاد"
        ? "infant"
        : null;
    row.phone = d["شماره تماس سفارش"];
    row.fullname = d["نام"];
    table.push(row);
  });
  return table;
};

export interface SyncBatchDataType {
  flights: FlightType[];
  hotels: HotelType[];
  indras: string[];
}
type PrepareBatchDataType = (data: TableRowType[]) => SyncBatchDataType;

export const prepareBatchData: PrepareBatchDataType = (data) => {
  // key/id/title/side
  const FS: FlightType[] = [];
  data.forEach((d) => {
    let index = FS?.findIndex((f) => f.key === d.arrival_info && f.side === "arrival");
    if (index === -1) {
      FS.push({ key: d.arrival_info, id: -1, title: "", side: "arrival" });
    }
    index = FS?.findIndex((f) => f.key === d.departure_info && f.side === "departure");
    if (index === -1) {
      FS.push({ key: d.departure_info, id: -1, title: "", side: "departure" });
    }
  });

  const HS: HotelType[] = [];
  data.forEach((d) => {
    let index = HS?.findIndex((f) => f.key === d.hotel_id);
    if (index === -1) {
      HS.push({ key: d.hotel_id, id: -1, title: "" });
    }
  });
  const IS: string[] = [];
  data.forEach((d) => {
    IS.push(d.indra);
  });

  return {
    flights: FS,
    hotels: HS,
    indras: IS,
  };
};
