import * as yup from "yup";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import Post from "lib/api/post";
import { useEffect, useState } from "react";
import { SELECT_USER } from "store/slices/profile";
import AddHotelType from "components/Forms/AddHotelType";

// active: 1
// address: "asdf"
// contacts: "[]"
// district: 25
// district_name: "Afyonkarahisar"
// fullname: "Homelike Hotel"
// hotel_code: "asdf"
// hotel_type: 1
// location: "asdf"
// meta_tags: "{}"
// session_id: 378
// state: 3
// state_name: "Afyonkarahisar"
// type_code: "3*"
// type_title: "3 Star"
// user_id: 7185

interface FormType {
  active: number;
  address: string;
  contacts: string;
  district: number;
  district_name: string;
  fullname: string;
  hotel_id: null;
  hotel_code: string;
  hotel_type: number;
  location: string;
  meta_tags: any;
  state: number;
  state_name: string;
  type_code: string;
  type_title: string;
  avatar: null | string;
}

interface OptionType {
  active: number;
  order: number;
  title: string;
  type_code: string;
  type_id: number;
  type_level: number;
}
interface DistrictOptionType {
  code: string;
  id: number;
  name: string;
  state: number;
  state_name: string;
}

interface FormSearchZone {
  count: number;
  key: string;
  page: number;
  session_id: number;
  user_id: number;
  zone_type: string;
}

const validationSchema = () =>
  yup.object().shape({
    district_name: yup.string().required("required"),
    fullname: yup.string().required("required"),
    hotel_code: yup.string().required("required"),
    state_name: yup.string().required("required"),
    type_title: yup.string().required("required"),
  });

interface PropsType {
  hotelAdded: (v: any) => void;
}

const AddHotel = ({ hotelAdded }: PropsType) => {
  const user = SELECT_USER();
  const [open, setOpen] = useState(false);
  const [open_hotel_type, setOpen_hotel_type] = useState(false);
  const [open_district, setOpen_district] = useState(false);
  const [options, setOptions] = useState<readonly OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const hotel_type_loading = open_hotel_type;

  const [district_options, set_district_options] = useState<DistrictOptionType[]>([]);
  const [district_search, set_district_search] = useState<string>("");
  const [district_loading, set_district_loading] = useState<boolean>(false);

  const {
    handleSubmit,
    resetForm,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik<FormType>({
    validationSchema,
    initialValues: {
      active: 0,
      avatar: null,
      contacts: "[]",
      address: "",
      district: 0,
      district_name: "",
      fullname: "",
      hotel_id: null,
      hotel_code: "",
      hotel_type: 0,
      location: "",
      meta_tags: "{}",
      state: 0,
      state_name: "",
      type_code: "",
      type_title: "",
    },
    //       active: 1
    // address: "12311"
    // avatar: null
    // district: 683
    // district_name: "Alaşehir"
    // fullname: "home"
    // hotel_code: "123"
    // hotel_id: null
    // hotel_type: 391
    // location: "123"
    // meta_tags: "{}"
    // session_id: 399
    // state: 56
    // state_name: "Manisa"
    // type_code: "maste"
    // type_title: "test_2"
    // user_id: 7185

    // active: 1
    // address: "1232"
    // contacts: "[]"
    // district: 752
    // district_name: "Çiftlik"
    // fullname: "Homelike Hotel"
    // hotel_code: "12312"
    // hotel_type: 388
    // location: "123"
    // meta_tags: "{}"
    // session_id: 398
    // state: 62
    // state_name: "Niğde"
    // type_code: "123"
    // type_title: "master"
    // user_id: 7185

    onSubmit: (values) => {
      setLoading(true);
      const newValue: any = { ...values, user_id: user.uid, session_id: user.session_id };
      Post.hotel(newValue)
        .then((res) => {
          const hotel: any = res.data.hotel;
          // response=====>
          // active: 1
          // address: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
          // avatar: null
          // district: 792
          // district_name: "Adapazarı"
          // fullname: "test_11"
          // hotel_code: "123"
          // hotel_id: null
          // hotel_type: 389
          // location: "vasd"
          // meta_tags: "{}"
          // state: 66
          // state_name: "Sakarya"
          // type_code: "asd"
          // type_title: "a123"
          hotelAdded({ label: hotel.fullname, id: hotel.hotel_id });
          setLoading(false);
          setOpen(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    },
  });

  useEffect(() => {
    let active = true;

    if (!hotel_type_loading) {
      return undefined;
    }

    Post.searchHotelType(user.session_id, user.uid).then((res) => {
      setOptions(res.data.items);
    });

    return () => {
      active = false;
    };
  }, [hotel_type_loading]);

  const searchZonde = (name: string) => {
    let form: FormSearchZone = {
      count: 20,
      key: name,
      page: 1,
      session_id: user.session_id,
      user_id: user.uid,
      zone_type: "district",
    };
    Post.searchZone(form).then((res) => {
      set_district_options(res.data.items);
      set_district_loading(false);
    });
  };
  useEffect(() => {
    if (district_search) {
      set_district_loading(true);
      searchZonde(district_search);
    }
  }, [district_search]);

  const addNewTypeHandler = (newType: OptionType) => {
    const newOptions = [...options];
    newOptions.unshift(newType);
    setOptions([...newOptions]);
  };

  useEffect(() => {
    resetForm();
  }, [open]);

  return (
    <>
      <MDButton onClick={() => setOpen(true)} color={"primary"}>
        + add a new hotel
      </MDButton>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AddHotelType typeAdded={addNewTypeHandler} />
            </Grid>
            <Grid item xs={12}>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Grid item xs={12} spacing={2} container>
                  <Grid item xs={12}>
                    <Autocomplete
                      open={open_hotel_type}
                      onOpen={() => {
                        setOpen_hotel_type(true);
                      }}
                      onClose={() => {
                        setOpen_hotel_type(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.title === value.title}
                      getOptionLabel={(option) => option.title}
                      options={options}
                      loading={hotel_type_loading}
                      onChange={(e, v) => {
                        const v2: any = v;
                        setFieldValue("active", v2.active);
                        setFieldValue("hotel_type", v2.type_id);
                        setFieldValue("type_code", v2.type_code);
                        setFieldValue("type_title", v2.title);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="hotel type"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {hotel_type_loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          value={values.type_title}
                          name="type_title"
                          error={touched.type_title && errors.type_title ? true : false}
                          helperText={touched.type_title && errors.type_title && errors.type_title}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="hotel name"
                      value={values.fullname}
                      name="fullname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.fullname && errors.fullname ? true : false}
                      helperText={touched.fullname && errors.fullname && errors.fullname}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      open={open_district}
                      onOpen={() => {
                        setOpen_district(true);
                      }}
                      onClose={() => {
                        setOpen_district(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => `${option.name} / ${option.state_name}`}
                      options={district_options}
                      loading={district_loading}
                      onChange={(e, v) => {
                        const v2: any = v;
                        setFieldValue("district", v2.id);
                        setFieldValue("district_name", v2.name);
                        setFieldValue("state", v2.state);
                        setFieldValue("state_name", v2.state_name);
                      }}
                      renderInput={(params) => (
                        <TextField
                          value={values.district}
                          onChange={(e) => set_district_search(e.target.value)}
                          error={touched.state_name && errors.state_name ? true : false}
                          helperText={touched.state_name && errors.state_name && errors.state_name}
                          name="state_name"
                          {...params}
                          label="hotel district"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {district_loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="hotel code"
                      value={values.hotel_code}
                      error={touched.hotel_code && errors.hotel_code ? true : false}
                      helperText={touched.hotel_code && errors.hotel_code && errors.hotel_code}
                      name="hotel_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="location"
                      value={values.location}
                      error={touched.location && errors.location ? true : false}
                      helperText={touched.location && errors.location && errors.location}
                      name="location"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="address"
                      value={values.address}
                      error={touched.address && errors.address ? true : false}
                      helperText={touched.address && errors.address && errors.address}
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item>
                      <MDButton loading={loading} variant="gradient" color="dark" type={"submit"}>
                        {(loading && <CircularProgress size="14px" color="primary" />) || (
                          <div>+ add</div>
                        )}
                      </MDButton>
                    </Grid>
                    <Grid item>
                      <MDButton
                        variant="outlined"
                        disabled={loading}
                        color={"error"}
                        onClick={() => setOpen(false)}
                      >
                        cancel
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddHotel;
