export const REPORT_FOR = [
  {
    id: 1,
    name: "leader",
  },
  {
    id: 2,
    name: "car",
  },
];

export const SIDES = [
  {
    id: 1,
    name: "arrival",
  },
  {
    id: 2,
    name: "departure",
  },
  {
    id: 3,
    name: "both",
  },
];
