export const just_persian = (str: string) => {
  var p = /^[\u0600-\u06FF\s]+$/;

  return !p.test(str);
};

export const justEnglish = (str: string) => {
  // const p = /^[A-Za-z][A-Za-z0-9]*$/;
  const p = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
  return p.test(str);
};
