import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

// @mui material components
import { Grid, Icon, Card, Menu } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDDatePicker from "components/MDDatePicker";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import NotificationItem from "examples/Items/NotificationItem";

import { SELECT_USER } from "store/slices/profile";

import Post from "lib/api/post";
import AddHotel from "components/Forms/AddHotel";
import RemoveItem from "./RemoveItem";
import AddContact from "./AddContact";
import RoomForm from "./rooms/RoomForm";
import EditHotel from "components/Forms/EditHotel";

interface Hotel {
  hotel_id: number;
  hotel_code: string;
  fullname: string;
  type_code: string;
  type_title: string;
  state_name: string;
  district_name: string;
  contacts: [];
  rooms: [];
  reservations: {
    count: number;
    page: number;
    per_page: number;
    items: [];
  };
}

interface HotelContactItem {
  contact_id: number;
  hotel_id: number;
  contact_type: "mobile" | "phone" | "mail" | "instagram" | "facebook" | "other";
  value: string;
  title: string;
  active: number;
}

interface HotelRoomItem {
  room_id: number;
  hotel_id: number;
  room_type1: number;
  room_type2: number;
  pax: string;
  room_type1_code: string;
  room_type2_code: string;
  room_type1_title: string;
  room_type2_title: string;
}

interface HotelReservationItem {
  id: number;
  voucher: string;
  hotel_id: number;
  hotel_name: string;
  reseller_id: number;
  reseller_name: string;
  reseller_company: string;
  added_by: number;
  adder_name: string;
  adder_avatar: string;
  modified_by: number;
  status: number;
  time: string;
}

interface OverNight {
  from: string;
  to: string;
  items: [];
}

interface OverNightItem {
  id: number;
  status: number;
  pax: number;
  time: string;
  voucher: string;
  room_index: number;
  room_type1: number;
  room_type: string;
  room_type2: number;
  room_subtype: string;
  check_in: string;
  check_out: string;
  nights: number;
  pansion: number;
  pansion_type: string;
  buy_price: number;
  buy_currency: number;
  buy_currency_code: string;
  sell_price: number;
  sell_currency: number;
  sell_currency_code: string;
}

interface InHouseCheckinCheckout {
  date: string;
  items: [];
  pax: number;
}

interface InHouseCheckinCheckoutItem {
  id: number;
  voucher: string;
  reseller_id: number;
  reseller_name: string;
  reseller_company: string;
  status: number;
  rooms: [];
}

interface RoomItem {
  room_index: number;
  room_type1: number;
  room_type: string;
  room_type2: number;
  room_subtype: string;
  buy_price: number;
  buy_currency: number;
  buy_currency_code: string;
  sell_price: number;
  sell_currency: number;
  sell_currency_code: string;
  check_in: string;
  check_out: string;
  nights: number;
  passengers: [];
}

interface PassengerItem {
  user_id: number;
  passenger_name: string;
}

function HotelProfile(): JSX.Element {
  type Color =
    | "light"
    | "dark"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error";

  const user = SELECT_USER();

  const { hotel_id } = useParams();

  const [_hotel, _setHotel] = useState<Hotel>();
  const [_updatePage, _setUpdatePage] = useState<number>(0);
  const [_alert, _setAlert] = useState<string>();
  const [_alertColor, _setAlertColor] = useState<Color>();
  const [_report, _setReport] = useState<InHouseCheckinCheckout>();
  const [_reportType, _setReportType] = useState<
    "overnight" | "in-house" | "check-in" | "check-out"
  >("overnight");

  const [_overNight, _setOverNight] = useState<OverNight>();
  const [_inHouse, _setInHouse] = useState<InHouseCheckinCheckout>();
  const [_checkIn, _setCheckIn] = useState<InHouseCheckinCheckout>();
  const [_checkOut, _setCheckOut] = useState<InHouseCheckinCheckout>();

  const [_overNightDate, _setOverNightDate] = useState<string>();
  const [_inHouseDate, _setInHouseDate] = useState<string>();
  const [_checkInDate, _setCheckInDate] = useState<string>();
  const [_checkOutDate, _setCheckOutDate] = useState<string>();
  const [_roomCount, _setRoomCount] = useState<number>(0);
  const [_openMenu, _setOpenMenu] = useState<any>(false);

  const [openModifyHotel, setOpenModifyHotel] = useState(false);

  const [openAddContact, setOpenAddContact] = useState(false);
  const [contactId, setContactId] = useState<number>();
  const [contactTitle, setContactTitle] = useState<string>();
  const [contactValue, setContactValue] = useState<string>();
  const [contactType, setContactType] = useState<
    "mobile" | "phone" | "mail" | "instagram" | "facebook" | "other"
  >();

  const [openRemoveContact, setOpenRemoveContact] = useState(false);
  const [openRemoveRoom, setOpenRemoveRoom] = useState(false);

  const [openRoomForm, setOpenRoomForm] = useState(false);
  const [room_id, set_room_id] = useState<number>();
  const [pax, set_pax] = useState<string>();
  const [room_type1, set_room_type1] = useState<number>();
  const [room_type1_code, set_room_type1_code] = useState<string>();
  const [room_type1_title, set_room_type1_title] = useState<string>();
  const [room_type2, set_room_type2] = useState<number>();
  const [room_type2_code, set_room_type2_code] = useState<string>();
  const [room_type2_title, set_room_type2_title] = useState<string>();

  let roomCount = 0;
  let totalPax = 0;
  let totalNights = 0;

  useEffect(() => {
    Post.hotelProfile(user.session_id, user.uid, parseInt(hotel_id)).then((res) => {
      _setHotel(res.data.hotel);
    });
  }, [hotel_id, user.session_id, user.uid, _updatePage]);

  const convertDate = useCallback((d: Date) => {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }, []);

  const convertTime = useCallback((time: string) => {
    const d = new Date(time);
    return `${d.getUTCDate()} ${d.toLocaleString("en-us", { month: "short", year: "numeric" })}`;
  }, []);

  const handleAlert = useCallback(
    (text: string, color: Color) => {
      _setAlert(text);
      _setAlertColor(color);
      return;
    },
    [_setAlert]
  );

  const handleReports = useCallback(
    (report: "overnight" | "in-house" | "check-in" | "check-out") => {
      _setReportType(report);
      return;
    },
    [_setReportType]
  );

  const handleReportRequest = useCallback(
    (e: any) => {
      if (_reportType === "overnight") {
        if (!e) return;
        if (!e[0]) return;
        if (!e[1]) return;

        const from = convertDate(e[0]);
        const to = convertDate(e[1]);
        _setOverNightDate(`${from} to ${to}`);
        Post.overNight(from, to, user.session_id, user.uid, parseInt(hotel_id)).then((res) => {
          _setOverNight(res.data.overnight);
        });
      } else {
        if (!e) return;
        if (!e[0]) return;
        const date = convertDate(e[0]);

        if (_reportType === "in-house") {
          _setInHouseDate(date);
          Post.inHouse(date, user.session_id, user.uid, parseInt(hotel_id)).then((res) => {
            _setInHouse(res.data.inhouse);
          });
        }
        if (_reportType === "check-in") {
          _setCheckInDate(date);
          Post.checkIn(date, user.session_id, user.uid, parseInt(hotel_id)).then((res) => {
            _setCheckIn(res.data.checkin);
          });
        }
        if (_reportType === "check-out") {
          _setCheckOutDate(date);
          Post.checkOut(date, user.session_id, user.uid, parseInt(hotel_id)).then((res) => {
            _setCheckOut(res.data.checkout);
          });
        }
      }
    },
    [_reportType, convertDate, hotel_id, user.session_id, user.uid]
  );

  const handleOpenMenu = useCallback(
    (event: any) => {
      _setOpenMenu(event.currentTarget);
    },
    [_setOpenMenu]
  );

  const handleOpenAddHotel = useCallback(() => {
    _setOpenMenu(false);
    setOpenModifyHotel(true);
  }, [_setOpenMenu, setOpenModifyHotel]);

  const handleAddContact = useCallback(() => {
    setContactId(null);
    setContactTitle(null);
    setContactValue(null);
    setContactType(null);
    setOpenAddContact(true);
  }, []);

  const handleEditContact = useCallback(
    (contact: HotelContactItem) => {
      setContactId(contact.contact_id);
      setContactTitle(contact.title);
      setContactValue(contact.value);
      setContactType(contact.contact_type);
      setOpenAddContact(true);
    },
    [setContactId, setContactTitle, setContactValue, setContactType, setOpenAddContact]
  );

  const handleDeleteContact = useCallback(
    (contact: HotelContactItem) => {
      setContactId(contact.contact_id);
      setOpenRemoveContact(true);
    },
    [setContactId, setOpenRemoveContact]
  );

  const handleDeleteRoom = useCallback(
    (room: HotelRoomItem) => {
      set_room_id(room.room_id);
      setOpenRemoveRoom(true);
    },
    [set_room_id, setOpenRemoveRoom]
  );

  const handleEditRoom = useCallback(
    (room: HotelRoomItem) => {
      set_room_id(room.room_id);
      set_pax(room.pax);
      set_room_type1(room.room_type1);
      set_room_type1_code(room.room_type1_code);
      set_room_type1_title(room.room_type1_title);
      set_room_type2(room.room_type2);
      set_room_type2_code(room.room_type2_code);
      set_room_type2_title(room.room_type2_title);
      setOpenRoomForm(true);
    },
    [
      set_pax,
      set_room_type1,
      set_room_type1_code,
      set_room_type1_title,
      set_room_type2,
      set_room_type2_code,
      set_room_type2_title,
      setOpenRoomForm,
    ]
  );

  const handleAddRoom = useCallback(() => {
    set_room_id(null);
    set_pax(null);
    set_room_type1(null);
    set_room_type1_code(null);
    set_room_type1_title(null);
    set_room_type2(null);
    set_room_type2_code(null);
    set_room_type2_title(null);
    setOpenRoomForm(true);
  }, [
    set_pax,
    set_room_type1,
    set_room_type1_code,
    set_room_type1_title,
    set_room_type2,
    set_room_type2_code,
    set_room_type2_title,
    setOpenRoomForm,
  ]);

  const renderOvernight = useMemo(
    () => (
      <>
        <Grid container p={3} height="100%" alignItems="center" justifyContent="space-between">
          <Grid item xs={12} lg={6}>
            <MDTypography fontWeight="bold" variant="inherit">
              Overnight Stay
            </MDTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12}>
                <MDDatePicker
                  value={_overNightDate}
                  input={{ placeholder: "Select a range between two dates", sx: { width: "100%" } }}
                  options={{ mode: "range" }}
                  onChange={(e: { target: { value: string } }) => handleReportRequest(e)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            isSorted={false}
            table={{
              columns: [
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      (#)
                    </MDTypography>
                  ),
                  accessor: "voucher",
                  width: "5%",
                },
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      Room Type
                    </MDTypography>
                  ),
                  accessor: "room_type",
                },
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      CheckIn/Out
                    </MDTypography>
                  ),
                  accessor: "check_in_check_out",
                },
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      Nights
                    </MDTypography>
                  ),
                  accessor: "nights",
                },
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      Buy Price
                    </MDTypography>
                  ),
                  accessor: "buy_price",
                },
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      Sell Price
                    </MDTypography>
                  ),
                  accessor: "sell_price",
                },
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      Status
                    </MDTypography>
                  ),
                  accessor: "status",
                },
                {
                  accessor: "action",
                  width: "8%",
                },
              ],
              rows: _overNight
                ? _overNight.items.map((item: OverNightItem, i: number) => {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    roomCount = roomCount + 1;
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    totalPax += item.pax;
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    totalNights += item.nights;

                    const hotelObj = {
                      voucher: (
                        <>
                          <MDTypography variant="inherit" color="text" fontWeight="bold">
                            {item.voucher}
                          </MDTypography>
                        </>
                      ),
                      room_type: (
                        <>
                          <MDTypography variant="inherit" color="text" fontWeight="bold">
                            {item.room_type} / {item.room_subtype}{" "}
                            <MDBadge
                              badgeContent={`Pax ${item.pax}`}
                              color="primary"
                              variant="contained"
                            />
                          </MDTypography>
                        </>
                      ),
                      check_in_check_out: (
                        <>
                          <MDTypography variant="inherit" color="text" fontWeight="bold">
                            {convertTime(item.check_in)} - {convertTime(item.check_out)}
                          </MDTypography>
                        </>
                      ),
                      nights: (
                        <>
                          <MDTypography variant="inherit" color="text" fontWeight="bold">
                            {item.nights} Night(s)
                          </MDTypography>
                        </>
                      ),
                      buy_price: (
                        <>
                          <MDTypography variant="inherit" color="text" fontWeight="bold">
                            {item.buy_price} {item.buy_currency_code}
                          </MDTypography>
                        </>
                      ),
                      sell_price: (
                        <>
                          <MDTypography variant="inherit" color="text" fontWeight="bold">
                            {item.sell_price} {item.sell_currency_code}
                          </MDTypography>
                        </>
                      ),
                      status: (
                        <>
                          <MDBadge
                            badgeContent={item.status === 1 ? "Confirmed" : "unverified"}
                            color="success"
                            variant="contained"
                            container
                          />
                        </>
                      ),
                      action: (
                        <Grid container gap="1rem">
                          <MDButton id={item.id.toString()} key={i} variant="outlined" color="info">
                            <Icon sx={{ fontSize: "67px !important" }}>monitor</Icon>
                          </MDButton>
                        </Grid>
                      ),
                    };
                    return hotelObj;
                  })
                : [],
            }}
            entriesPerPage={false}
            pagination={{
              variant: "contained",
              color: "primary",
            }}
            showTotalEntries={false}
          />
        </Grid>
        <Grid
          container
          p={3}
          spacing={3}
          height="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item>
            <MDTypography fontWeight="bold" fontSize={"medium"} variant="inherit">
              {roomCount ? `Rooms: ${roomCount}` : null}
            </MDTypography>
          </Grid>
          <Grid item>
            <MDTypography fontWeight="bold" fontSize={"medium"} variant="inherit">
              {totalPax ? `Total Pax: ${totalPax}` : null}
            </MDTypography>
          </Grid>
          <Grid item>
            <MDTypography fontWeight="bold" fontSize={"medium"} variant="inherit">
              {totalNights ? `Total Nights: ${totalNights}` : null}
            </MDTypography>
          </Grid>
        </Grid>
      </>
    ),
    [_overNight, roomCount, convertTime, handleReportRequest]
  );

  const renderReportRows = useMemo(() => {
    if (_reportType === "in-house") _setReport(_inHouse);
    if (_reportType === "check-in") _setReport(_checkIn);
    if (_reportType === "check-out") _setReport(_checkOut);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    roomCount = 0;
    _setRoomCount(0);

    return _report
      ? _report.items.map((item: InHouseCheckinCheckoutItem, i: number) => {
          const hotelObj = {
            voucher: (
              <>
                <Grid container gap="1rem" alignItems={"center"}>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {item.voucher}
                  </MDTypography>
                  <MDButton
                    id={item.id.toString()}
                    key={i}
                    variant="outlined"
                    color="info"
                    // onClick={handleOpenMenu}
                  >
                    <Icon sx={{ fontSize: "70px !important" }}>monitor</Icon>
                  </MDButton>
                </Grid>
              </>
            ),
            reseller_company: (
              <>
                <MDTypography variant="inherit" color="text" fontWeight="bold">
                  {item.reseller_company}
                </MDTypography>
              </>
            ),
            rooms: item
              ? item.rooms.map((room: RoomItem, i: number) => {
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                  roomCount = roomCount + 1;
                  _setRoomCount(roomCount);

                  return (
                    <Grid key={i} container sx={{ maxWidth: "40rem", minWidth: "35rem" }}>
                      <Grid item pb={1} xs={12}>
                        <MDBox
                          p={1}
                          borderRadius="4px"
                          bgColor="light"
                          variant="gradient"
                          color="white"
                        >
                          <MDTypography fontWeight="bold" variant="inherit">
                            {room.room_type} / {room.room_subtype}{" "}
                            <MDBadge
                              badgeContent={`${room.nights} night(s)`}
                              color="info"
                              variant="contained"
                            />
                          </MDTypography>
                          <MDTypography fontWeight="bold" variant="inherit">
                            {convertTime(room.check_in)} - {convertTime(room.check_out)}
                          </MDTypography>
                          {room.passengers.map((passenger: PassengerItem, i: number) => {
                            return (
                              <MDTypography fontSize="small" key={i} variant="inherit">
                                {passenger.passenger_name}
                              </MDTypography>
                            );
                          })}
                        </MDBox>
                      </Grid>
                    </Grid>
                  );
                })
              : [],
          };
          return hotelObj;
        })
      : [];
  }, [_reportType, _inHouse, _checkIn, _checkOut, _report, roomCount]);

  const renderReports = useMemo(
    () => (
      <>
        <Grid container p={3} height="100%" alignItems="center" justifyContent="space-between">
          <Grid item xs={12} lg={6}>
            <MDTypography fontWeight="bold" variant="inherit">
              {_reportType === "in-house"
                ? "In House Report"
                : _reportType === "check-in"
                ? "Check-in Report"
                : _reportType === "check-out"
                ? "Check-out Report"
                : null}
            </MDTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12}>
                {}
                <MDDatePicker
                  value={
                    _reportType === "in-house"
                      ? _inHouseDate
                      : _reportType === "check-in"
                      ? _checkInDate
                      : _reportType === "check-out"
                      ? _checkOutDate
                      : null
                  }
                  input={{ placeholder: "Select a date", sx: { width: "100%" } }}
                  options={{ mode: "single" }}
                  onChange={(e: { target: { value: string } }) => handleReportRequest(e)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataTable
            isSorted={false}
            table={{
              columns: [
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      (#)
                    </MDTypography>
                  ),
                  accessor: "voucher",
                  width: "5%",
                },
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      Reseller
                    </MDTypography>
                  ),
                  accessor: "reseller_company",
                },
                {
                  Header: (
                    <MDTypography variant="h6" fontWeight="bold">
                      Rooms
                    </MDTypography>
                  ),
                  accessor: "rooms",
                },
              ],
              rows: renderReportRows,
            }}
            entriesPerPage={false}
            pagination={{
              variant: "contained",
              color: "primary",
            }}
            showTotalEntries={false}
          />
        </Grid>
        <Grid
          container
          p={3}
          spacing={3}
          height="100%"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item>
            <MDTypography fontWeight="bold" fontSize={"medium"} variant="inherit">
              {_roomCount ? `Rooms: ${_roomCount}` : null}
            </MDTypography>
          </Grid>
          <Grid item>
            <MDTypography fontWeight="bold" fontSize={"medium"} variant="inherit">
              {_report ? `Total Pax: ${_report.pax}` : null}
            </MDTypography>
          </Grid>
        </Grid>
      </>
    ),
    [
      _roomCount,
      _report,
      _reportType,
      _checkInDate,
      _checkOutDate,
      _inHouseDate,
      renderReportRows,
      handleReportRequest,
    ]
  );

  const renderActionMenu = useMemo(() => {
    return (
      <Menu
        anchorEl={_openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(_openMenu)}
        onClose={() => _setOpenMenu(false)}
        sx={{ mt: 2 }}
      >
        <NotificationItem onClick={handleOpenAddHotel} icon={<Icon>edit</Icon>} title="Modify" />
        <NotificationItem
          onClick={() => _setOpenMenu(false)}
          icon={<Icon>headset_mic</Icon>}
          title="Reservation"
        />
      </Menu>
    );
  }, [_openMenu, _setOpenMenu, handleOpenAddHotel]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>
            {_alert ? (
              <Grid container>
                <Grid item xs={12}>
                  <MDAlert dismissible={true} color={_alertColor} onClose={() => _setAlert(null)}>
                    {_alert}
                  </MDAlert>
                </Grid>
              </Grid>
            ) : null}
            <Card sx={{ width: "100%" }}>
              <Grid container p={3} spacing={3}>
                <Grid item>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="5rem"
                    height="5rem"
                    variant="gradient"
                    bgColor="primary"
                    color="white"
                    shadow="md"
                    borderRadius="8px"
                    // ml={3}
                    mt={1}
                  >
                    <Icon fontSize="large" color="inherit">
                      hotel
                    </Icon>
                  </MDBox>
                </Grid>
                <Grid item sx={{ paddingLeft: "2rem" }}>
                  <MDTypography variant="inherit" fontWeight="bold">
                    {_hotel ? _hotel.fullname : "..."}
                  </MDTypography>
                  <MDTypography variant="inherit">{_hotel ? _hotel.hotel_code : ""}</MDTypography>
                  <MDTypography variant="inherit">{_hotel ? _hotel.type_title : ""}</MDTypography>
                </Grid>
                <Grid item sx={{ marginLeft: "auto" }}>
                  <Grid container height="100%" alignItems="center" justifyContent="flex-end">
                    <Grid item>
                      <Grid container display="flex">
                        <MDButton
                          size="small"
                          variant="gradient"
                          color="primary"
                          onClick={handleOpenMenu}
                        >
                          <Icon>view_list</Icon>
                          &nbsp;Actions
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {renderActionMenu}
              </Grid>
            </Card>
            <Grid item py={3} xs={12}>
              <Card sx={{ width: "100%" }}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <MDTypography p={3} fontWeight="bold" variant="inherit">
                      Contact Data
                    </MDTypography>
                  </Grid>
                  <Grid item p={3} sx={{ marginLeft: "auto" }}>
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={3}>
                        <Grid container height="100%" alignItems="center" justifyContent="flex-end">
                          <Grid item>
                            <Grid container display="flex">
                              <MDButton
                                size="large"
                                variant="gradient"
                                color="primary"
                                onClick={handleAddContact}
                              >
                                <Icon fontSize="large">add</Icon>
                              </MDButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <DataTable
                    isSorted={false}
                    table={{
                      columns: [
                        {
                          accessor: "icon",
                          width: "5%",
                        },
                        {
                          Header: (
                            <MDTypography variant="h6" fontWeight="bold">
                              Info
                            </MDTypography>
                          ),
                          accessor: "phone",
                        },
                        {
                          Header: (
                            <MDTypography variant="h6" fontWeight="bold">
                              Edit
                            </MDTypography>
                          ),
                          accessor: "actions",
                          width: "12%",
                        },
                      ],
                      rows: _hotel
                        ? _hotel.contacts.map((contact: HotelContactItem, i) => {
                            const hotelObj = {
                              icon: (
                                <>
                                  {contact.contact_type === "mobile" ? (
                                    <Icon fontSize="medium">smartphone</Icon>
                                  ) : contact.contact_type === "phone" ? (
                                    <Icon fontSize="medium">call</Icon>
                                  ) : contact.contact_type === "mail" ? (
                                    <Icon fontSize="medium">mail</Icon>
                                  ) : contact.contact_type === "instagram" ? (
                                    <Icon fontSize="medium">center_focus_strong</Icon>
                                  ) : contact.contact_type === "facebook" ? (
                                    <Icon fontSize="medium">person</Icon>
                                  ) : contact.contact_type === "other" ? (
                                    <Icon fontSize="medium">more_horiz</Icon>
                                  ) : null}
                                </>
                              ),
                              phone: (
                                <>
                                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                                    {contact.title}
                                  </MDTypography>
                                  <MDTypography variant="Inherit">{contact.value}</MDTypography>
                                </>
                              ),
                              actions: (
                                <Grid key={i} container gap="1rem">
                                  <MDButton
                                    id={contact.contact_id.toString()}
                                    variant="outlined"
                                    color="info"
                                    onClick={() => handleEditContact(contact)}
                                  >
                                    <Icon sx={{ fontSize: "67px !important" }}>edit</Icon>
                                  </MDButton>
                                  <MDButton
                                    id={contact.contact_id.toString()}
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDeleteContact(contact)}
                                  >
                                    <Icon sx={{ fontSize: 40 }} fontSize="large">
                                      close
                                    </Icon>
                                  </MDButton>
                                </Grid>
                              ),
                            };
                            return hotelObj;
                          })
                        : [],
                    }}
                    entriesPerPage={false}
                    pagination={{
                      variant: "contained",
                      color: "primary",
                    }}
                    showTotalEntries={false}
                  />
                </Grid>
              </Card>
            </Grid>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item xs={12} lg={4}>
                <Card sx={{ width: "100%" }}>
                  <MDTypography p={3} fontWeight="bold" variant="inherit">
                    Rooms
                  </MDTypography>
                  <Grid sx={{ maxHeight: "23rem", overflow: "scroll" }}>
                    {_hotel
                      ? _hotel.rooms.map((room: HotelRoomItem, i) => (
                          <Grid container pl={2} pr={2} spacing={1} mb={3} key={room.room_id}>
                            <Grid item display="flex" alignItems="center">
                              <Grid container>
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  width="3rem"
                                  height="3rem"
                                  variant="contained"
                                  bgColor="light"
                                  color="error"
                                  borderRadius="8px"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => handleDeleteRoom(room)}
                                >
                                  <Icon fontSize="large" color="inherit">
                                    close
                                  </Icon>
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid item display="flex" alignItems="center">
                              <Grid container>
                                <MDBox
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  width="3rem"
                                  height="3rem"
                                  variant="contained"
                                  bgColor="light"
                                  color="dark"
                                  borderRadius="8px"
                                >
                                  <Icon fontSize="large" color="inherit">
                                    bed
                                  </Icon>
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid item display="flex" sx={{ flexGrow: 1 }}>
                              <Grid container>
                                <MDBox
                                  key={i}
                                  sx={{ cursor: "pointer" }}
                                  display="flex"
                                  width="100%"
                                  variant="contained"
                                  bgColor="light"
                                  color="white"
                                  borderRadius="4px"
                                  onClick={() => handleEditRoom(room)}
                                >
                                  <Grid p={1}>
                                    <MDTypography variant="inherit" fontSize="small">
                                      {room.room_type1_title} ({room.room_type1_code})
                                    </MDTypography>
                                    <MDTypography variant="inherit" fontSize="small">
                                      {room.room_type2_title} ({room.room_type2_code})
                                    </MDTypography>
                                  </Grid>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      : null}
                  </Grid>
                  <Grid p={2}>
                    <MDButton
                      variant="contained"
                      color="primary"
                      sx={{ width: "100%" }}
                      onClick={() => handleAddRoom()}
                    >
                      Add New Room
                    </MDButton>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Card sx={{ width: "100%" }}>
                  <MDTypography p={3} fontWeight="bold" variant="inherit">
                    Latest Reservations
                  </MDTypography>
                  <Grid item xs={12}>
                    <DataTable
                      isSorted={false}
                      table={{
                        columns: [
                          {
                            Header: (
                              <MDTypography variant="h6" fontWeight="bold">
                                (#)
                              </MDTypography>
                            ),
                            accessor: "voucher",
                            width: "5%",
                          },
                          {
                            Header: (
                              <MDTypography variant="h6" fontWeight="bold">
                                Created by
                              </MDTypography>
                            ),
                            accessor: "adder_name",
                          },
                          {
                            Header: (
                              <MDTypography variant="h6" fontWeight="bold">
                                Reseller
                              </MDTypography>
                            ),
                            accessor: "reseller_company",
                          },
                          {
                            Header: (
                              <MDTypography variant="h6" fontWeight="bold">
                                Date
                              </MDTypography>
                            ),
                            accessor: "Date",
                          },
                          {
                            Header: (
                              <MDTypography variant="h6" fontWeight="bold">
                                Status
                              </MDTypography>
                            ),
                            accessor: "status",
                          },
                          {
                            accessor: "action",
                            width: "8%",
                          },
                        ],
                        rows: _hotel
                          ? _hotel.reservations.items.map((item: HotelReservationItem, i) => {
                              const hotelObj = {
                                voucher: (
                                  <>
                                    <MDTypography variant="inherit" color="text" fontWeight="bold">
                                      {item.voucher}
                                    </MDTypography>
                                  </>
                                ),
                                adder_name: (
                                  <>
                                    <MDTypography variant="inherit" color="text" fontWeight="bold">
                                      {item.adder_name}
                                    </MDTypography>
                                  </>
                                ),
                                reseller_company: (
                                  <>
                                    <MDTypography variant="inherit" color="text" fontWeight="bold">
                                      {item.reseller_company}
                                    </MDTypography>
                                  </>
                                ),
                                Date: (
                                  <>
                                    <MDTypography variant="inherit" color="text" fontWeight="bold">
                                      {item.time ? convertTime(item.time) : null}
                                    </MDTypography>
                                  </>
                                ),
                                status: (
                                  <>
                                    <MDBadge
                                      badgeContent={item.status === 1 ? "Confirmed" : "unverified"}
                                      color="success"
                                      variant="contained"
                                      container
                                    />
                                  </>
                                ),
                                action: (
                                  <Grid container gap="1rem">
                                    <MDButton
                                      id={item.id.toString()}
                                      key={i}
                                      variant="outlined"
                                      color="info"
                                      // onClick={handleOpenMenu}
                                    >
                                      <Icon sx={{ fontSize: "67px !important" }}>monitor</Icon>
                                    </MDButton>
                                  </Grid>
                                ),
                              };
                              return hotelObj;
                            })
                          : [],
                      }}
                      entriesPerPage={false}
                      pagination={{
                        variant: "contained",
                        color: "primary",
                      }}
                      showTotalEntries={false}
                    />
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" py={3}>
              <Card sx={{ width: "100%" }}>
                <Grid container pl={3} pr={3} pt={3}>
                  <Grid item pb={1}>
                    <MDTypography fontWeight="bold" variant="h5">
                      Reports
                    </MDTypography>
                  </Grid>
                  <Grid item sx={{ marginLeft: "auto" }}>
                    <Grid container spacing={1} justifyContent="flex-end">
                      <Grid item>
                        <MDButton
                          size="medium"
                          variant="contained"
                          color={_reportType === "overnight" ? "dark" : "light"}
                          onClick={() => handleReports("overnight")}
                        >
                          <Icon>keyboard_double_arrow_right</Icon>
                          &nbsp;Overnight
                        </MDButton>
                      </Grid>
                      <Grid item>
                        <MDButton
                          size="medium"
                          variant="contained"
                          color={_reportType === "in-house" ? "dark" : "light"}
                          onClick={() => handleReports("in-house")}
                        >
                          <Icon>keyboard_double_arrow_right</Icon>
                          &nbsp;In House
                        </MDButton>
                      </Grid>
                      <Grid item>
                        <MDButton
                          size="medium"
                          variant="contained"
                          color={_reportType === "check-in" ? "dark" : "light"}
                          onClick={() => handleReports("check-in")}
                        >
                          <Icon>keyboard_double_arrow_right</Icon>
                          &nbsp;Check-In
                        </MDButton>
                      </Grid>
                      <Grid item>
                        <MDButton
                          size="medium"
                          variant="contained"
                          color={_reportType === "check-out" ? "dark" : "light"}
                          onClick={() => handleReports("check-out")}
                        >
                          <Icon>keyboard_double_arrow_right</Icon>
                          &nbsp;Check-Out
                        </MDButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {_reportType === "overnight" ? renderOvernight : renderReports}
              </Card>
            </Grid>

            <Grid item xs={12}>
              {/* <AddHotel
              hotelAdded={(item: any): void => {
                if (item) {
                  handleAlert("Hotel added successfully!", "success");
                }
                _setUpdatePage(_updatePage + 1);
              }}
            /> */}
            </Grid>

            {openAddContact ? (
              <Grid item xs={12}>
                <AddContact
                  open={openAddContact}
                  setOpen={setOpenAddContact}
                  contactAdded={(item: any): void => {
                    if (item) {
                      handleAlert("Contact added successfully!", "success");
                    }
                    _setUpdatePage(_updatePage + 1);
                  }}
                  hotel_id={parseInt(hotel_id)}
                  contact_id={contactId}
                  contact_title={contactTitle}
                  contact_value={contactValue}
                  contact_type={contactType}
                />
              </Grid>
            ) : null}
            {openRemoveContact ? (
              <Grid item xs={12}>
                <RemoveItem
                  contact_id={contactId}
                  removedContact={(item: any): void => {
                    if (item) {
                      handleAlert("Contact removed successfully!", "error");
                    }
                    _setUpdatePage(_updatePage + 1);
                  }}
                  open={openRemoveContact}
                  setOpen={setOpenRemoveContact}
                />
              </Grid>
            ) : null}
            {openRemoveRoom ? (
              <Grid item xs={12}>
                <RemoveItem
                  room_id={room_id}
                  removedRoom={(item: any): void => {
                    if (item) {
                      handleAlert("Room removed successfully!", "error");
                    }
                    _setUpdatePage(_updatePage + 1);
                  }}
                  open={openRemoveRoom}
                  setOpen={setOpenRemoveRoom}
                />
              </Grid>
            ) : null}
            {openRoomForm ? (
              <Grid item xs={12}>
                <RoomForm
                  roomAdded={(item: any): void => {
                    if (item) {
                      handleAlert("Room added successfully!", "success");
                    }
                    _setUpdatePage(_updatePage + 1);
                  }}
                  hotel_id={parseInt(hotel_id)}
                  room_id={room_id}
                  set_room_id={set_room_id}
                  pax={pax}
                  set_pax={set_pax}
                  room_type1={room_type1}
                  room_type1_code={room_type1_code}
                  room_type1_title={room_type1_title}
                  room_type2={room_type2}
                  room_type2_code={room_type2_code}
                  room_type2_title={room_type2_title}
                  set_room_type1={set_room_type1}
                  set_room_type1_code={set_room_type1_code}
                  set_room_type1_title={set_room_type1_title}
                  set_room_type2={set_room_type2}
                  set_room_type2_code={set_room_type2_code}
                  set_room_type2_title={set_room_type2_title}
                  open={openRoomForm}
                  setOpen={setOpenRoomForm}
                />
              </Grid>
            ) : null}
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {openModifyHotel ? (
        <Grid item xs={12}>
          <EditHotel
            open={openModifyHotel}
            setOpen={setOpenModifyHotel}
            hotelAdded={() => handleAlert("Hotel added successfully!", "success")}
            hotelId={parseInt(hotel_id)}
          />
        </Grid>
      ) : null}
    </>
  );
}

export default HotelProfile;
