import { SxProps } from "@mui/material";
import useStyles from "components/customs/core/useStyles";
import { PickTimeType } from "components/customs/Overview";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { PassengerType, PaxType, TransferType } from "lib/types/api-type";
import { memo, useMemo } from "react";
import { SELECT_DESCRIPTION } from "store/slices/operations";
import { FlightPrintType, TableStyleType } from "../../types";
import { getPassengersCount } from "../../utils";
import { columnStyle, hotelStyle, textStyle } from "../constant";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
interface PropsType {
  transfer: TransferType;
  pax: PaxType;
  flight: FlightPrintType;
  styles: TableStyleType;
  showLeader: boolean;
  pickTime: PickTimeType;
}

const HotelRow = ({ styles, showLeader, flight, transfer, pax, pickTime }: PropsType) => {
  const classes = useStyles({ linesToShow: 1 });

  const timeHandler = (flight_id?: number, passengers?: PassengerType | PassengerType[]) => {
    if (flight_id && passengers)
      return Array.isArray(passengers)
        ? pickTime("hotel", flight_id, passengers[0]?.hotel_id)
        : pickTime("hotel", flight_id, passengers?.hotel_id);
  };

  const h: SxProps = useMemo(
    () => ({ ...hotelStyle, ...styles?.hotelStyle }),
    [styles?.hotelStyle]
  );
  const c2: SxProps = useMemo(
    () => ({ ...columnStyle, ...styles?.hotelColumnStyle }),
    [styles?.hotelColumnStyle]
  );
  const t2: SxProps = useMemo(
    () => ({ ...textStyle, ...styles?.hotelTextStyle }),
    [styles?.hotelTextStyle]
  );

  const isDescription = () => {
    let is;
    if (Array.isArray(pax?.passengers)) {
      for (const p of pax?.passengers || []) {
        is = SELECT_DESCRIPTION(p);
        if (is) {
          break;
        }
      }
    } else {
      is = SELECT_DESCRIPTION(pax?.passengers);
    }
    return Boolean(is);
  };

  return (
    <MDBox sx={h}>
      <MDBox sx={{ ...c2, gridColumn: "1/6", textAlign: "left" }}>
        <MDTypography className={classes.multiLineEllipsis} sx={t2}>
          {isDescription() ? <DoneRoundedIcon /> : null} {pax.hotel_name}
        </MDTypography>
      </MDBox>

      <MDBox sx={{ ...c2, gridColumn: "6/7" }}>
        <MDTypography className={classes.multiLineEllipsis} sx={t2}>
          {getPassengersCount(pax.passengers)}
        </MDTypography>
      </MDBox>
      {/* depar */}
      <MDBox sx={{ ...c2, gridColumn: "9/10" }}>
        {transfer.side === "departure" ? (
          <MDTypography sx={t2}>{timeHandler(flight?.flight_id, pax?.passengers)}</MDTypography>
        ) : null}
      </MDBox>
      {/* leader */}
      {showLeader ? (
        <MDBox sx={{ ...c2, gridColumn: "10/11" }}>
          <MDTypography className={classes.multiLineEllipsis} sx={{ ...t2 }}>
            <MDTypography sx={t2}>{transfer?.leader_name}</MDTypography>
          </MDTypography>
        </MDBox>
      ) : null}
      {/* car */}
      <MDBox sx={{ ...c2, gridColumn: "11/12" }}>
        <MDTypography className={classes.multiLineEllipsis} sx={{ ...t2 }}>
          {pax.car_code}
          <MDBox component={"span"}>({pax.driver_name})</MDBox>
        </MDTypography>
      </MDBox>
      {/* arrival-lead */}
      <MDBox sx={{ ...c2, gridColumn: "13/14" }}>
        {/* <MDTypography className={classes.multiLineEllipsis} sx={{ ...t2 }}>
          {transfer.side === "departure" ? <MDTypography sx={t2}>-</MDTypography> : null}
        </MDTypography> */}
      </MDBox>
    </MDBox>
  );
};

export default memo(HotelRow);
