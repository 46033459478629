import { FlyPassengerDataType, GetPassengersType } from "lib/types/api-type";

export interface ElementType {
  flights: number;
  pax: number;
}
export interface ElementsType {
  normal: ElementType;
  vip: ElementType;
  no_transfer: ElementType;
}

export interface DataType {
  arrivalPassengers: ElementsType;
  departurePassengers: ElementsType;
}

const fliesDataHandler: (flies: GetPassengersType) => DataType = (flies) => {
  const data: DataType = {
    arrivalPassengers: {
      normal: { flights: 0, pax: 0 },
      vip: { flights: 0, pax: 0 },
      no_transfer: { flights: 0, pax: 0 },
    },
    departurePassengers: {
      normal: { flights: 0, pax: 0 },
      vip: { flights: 0, pax: 0 },
      no_transfer: { flights: 0, pax: 0 },
    },
  };
  const getElement: (vals: FlyPassengerDataType[]) => ElementType = (vals) => {
    if (!vals) return { flights: 0, pax: 0 };
    let el: ElementType = { flights: vals?.length, pax: 0 };
    vals?.forEach((v) => {
      el.pax = el.pax + v.active_pax;
    });
    return el;
  };
  data.arrivalPassengers.normal = getElement(flies?.arrivalPassengers?.normal);
  data.arrivalPassengers.vip = getElement(flies?.arrivalPassengers?.vip);
  data.arrivalPassengers.no_transfer = getElement(flies?.arrivalPassengers?.no_transfer);
  data.departurePassengers.normal = getElement(flies?.departurePassengers?.normal);
  data.departurePassengers.vip = getElement(flies?.departurePassengers?.vip);
  data.departurePassengers.no_transfer = getElement(flies?.departurePassengers?.no_transfer);
  return data;
};

export default fliesDataHandler;
