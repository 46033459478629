import Post from "lib/api/post";
import React, { useState, createContext, useEffect, useContext } from "react";
import { SELECT_USER } from "store/slices/profile";

type RequirmentContextProps = {
  cost_types: [];
  currencies: [];
  pansion_types: [];
  result: string;
  room_subtypes: [];
  room_types: [];
  voucher_images: string;
  vouchers: [];
};

type ContextValue = {
  requirmentContext: RequirmentContextProps;
};

const RequirmentContext = createContext<ContextValue | undefined>(undefined);

const RequirmentContextProvider: React.FC = ({ children }) => {
  const [requirmentContext, setRequirmentContext] = useState<RequirmentContextProps>(undefined);

  const user = SELECT_USER();

  useEffect(() => {
    Post.loadRequirements({ user_id: user?.uid, session_id: user?.session_id }).then((res) => {
      setRequirmentContext(res?.data);
    });
  }, [user?.session_id, user?.uid]);

  return (
    <RequirmentContext.Provider value={{ requirmentContext }}>
      {children} {/* Ensure that the 'children' prop is passed here */}
    </RequirmentContext.Provider>
  );
};

export default RequirmentContextProvider;

export const useRequirmentContext = () => {
  const context = useContext(RequirmentContext);
  if (!context) {
    throw new Error("useFormContext must be used inside the RequirmentContextProvider");
  }
  return context;
};
