import MDBox from "components/MDBox";

interface Props {
  height: number;
}
const EndLine = ({ height }: Props) => {
  return (
    <>
      <MDBox height={height} display={"flex"} flexDirection={"column-reverse"}>
        <MDBox
          sx={{
            mt: `2px`,
            borderRadius: `4px`,
            width: "100%",
            height: `2px`,
            background: "black",
          }}
        />
        <MDBox
          sx={{
            mt: `1px`,
            borderRadius: `4px`,
            width: "100%",
            height: `1.5px`,
            background: "black",
          }}
        />
      </MDBox>
    </>
  );
};

export default EndLine;
