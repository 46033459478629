import { SxProps } from "@mui/material";
import MDBox from "components/MDBox";
import { useMemo } from "react";
import { PickTimeType } from "..";
import { LeaderType } from "../types";
import Row from "./row";
import { TableStyle } from "./row/constant";
import HeaderRow from "./row/HeaderRow";
import LeaderRow from "./row/LeaderRow";
import { ValuesType } from "./Settings/useSetting";
import { FlightPrintType, RowTitleType } from "./types";

interface PropsType {
  flights: FlightPrintType[];
  values: ValuesType;
  totalCount?: any;
  showTick: boolean;
  showDescription: boolean;
  showLeader: boolean;
  leader?: LeaderType;
  page?: number;
  titles?: RowTitleType;
  pickTime: PickTimeType;
}

const Table = ({
  showTick,
  showDescription,
  flights,
  values,
  leader,
  page,
  totalCount,
  titles,
  showLeader,
  pickTime,
}: PropsType) => {
  const { styles, logo } = values;

  // table
  const table: SxProps = useMemo(
    () => ({ ...TableStyle, ...(styles?.tableStyle || {}) }),
    [styles?.tableStyle]
  );
  // header

  return (
    <>
      <MDBox gap={1} sx={table}>
        {leader ? (
          <LeaderRow
            styles={styles}
            leader={leader}
            totalPax={totalCount ? totalCount(leader.flights) : 0}
            logo={logo}
            page={page}
          />
        ) : null}
        <HeaderRow {...titles} />
        {flights?.map((flight, index) => (
          <Row
            showTick={showTick}
            showDescription={showDescription}
            key={index}
            flight={flight}
            styles={styles}
            pickTime={pickTime}
            showLeader={showLeader}
          />
        ))}
      </MDBox>
    </>
  );
};

export default Table;
