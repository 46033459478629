import { SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import { DispatchKeyType, TableStyleType } from "../types";

interface PropsType {
  s: TableStyleType;

  saveTo: string;
  logoSaveTo?: string;
}

export type DispatchStyleType = (key: DispatchKeyType, sx: SxProps) => void;
export type EventType =
  | "UPDATE_LOGO"
  | "RESET"
  | "CHANGE_HEIGHT_RADIO"
  | "CHANGE_CUSTOM_HEIGHT_RADIO";
export type DispatchType = (event: EventType, value?: any) => void;
export type ValuesType = { logo?: string; styles: TableStyleType };
export enum HeightRadioName {
  A4 = "A4",
  CUSTOM = "CUSTOM",
}

export interface HeightRadio {
  name: HeightRadioName;
  value: string;
}

const A4: HeightRadio = {
  name: HeightRadioName.A4,
  value: "",
};

const A4_Height = "28.7cm";

//
//
const useSetting = ({ s, saveTo, logoSaveTo }: PropsType) => {
  const heightRadioSaveTo = `${saveTo}-heightRadio`;

  const [styles, setStyles] = useState<TableStyleType>();
  const [logo, setLogo] = useState<string>();
  const [heightRadio, setHeightRadio] = useState<HeightRadio>(A4);

  const dispatchStyle: DispatchStyleType = (key, sx) => {
    if (!styles) return;
    let newStyle = { ...styles };
    newStyle[key] = { ...newStyle[key], ...(sx as any) };
    setStyles({ ...newStyle });
    localStorage.setItem(saveTo, JSON.stringify({ ...newStyle }));
  };

  useEffect(() => {
    if (heightRadio.name === HeightRadioName.A4 && styles) {
      dispatchStyle("tableStyle", { height: A4_Height });
    } else {
      dispatchStyle("tableStyle", { height: heightRadio.value });
    }
  }, [heightRadio.name, heightRadio.value]);

  useEffect(() => {
    const localheightRadio = localStorage.getItem(heightRadioSaveTo);
    if (localheightRadio) {
      const newHeightRadio = JSON.parse(localheightRadio) as HeightRadio;

      if (newHeightRadio?.name) {
        setHeightRadio(newHeightRadio);
      } else {
        localStorage.setItem(heightRadioSaveTo, JSON.stringify(A4));
        setHeightRadio(A4);
      }
    }
    const savedStyle = localStorage.getItem(saveTo);
    if (savedStyle) {
      setStyles(JSON.parse(savedStyle));
    } else {
      setStyles(s);
    }
  }, []);

  useEffect(() => {
    if (logoSaveTo) {
      const newLogo = localStorage.getItem(logoSaveTo);
      if (newLogo) {
        setLogo(newLogo);
      }
    }
  }, [logoSaveTo]);

  const dispatch: DispatchType = (event, value) => {
    switch (event) {
      case "UPDATE_LOGO":
        {
          setLogo(value);
          localStorage.setItem(logoSaveTo, value);
        }
        break;
      case "RESET": {
        localStorage.setItem(saveTo, "");
        break;
      }
      case "CHANGE_HEIGHT_RADIO": {
        const name = value as HeightRadioName;
        const newHeightRadio: HeightRadio = { name, value: heightRadio.value };
        setHeightRadio(newHeightRadio);
        localStorage.setItem(heightRadioSaveTo, JSON.stringify(newHeightRadio));
        break;
      }
      case "CHANGE_CUSTOM_HEIGHT_RADIO": {
        const newHeightRadio: HeightRadio = { name: heightRadio.name, value };
        setHeightRadio(newHeightRadio);
        localStorage.setItem(heightRadioSaveTo, JSON.stringify(newHeightRadio));
        break;
      }
      default:
        break;
    }
  };

  const values = { logo, styles };
  return {
    values,
    logoSaveTo,
    heightRadio,
    dispatchStyle,
    dispatch,
  };
};

export default useSetting;
