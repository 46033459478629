import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { Box, CircularProgress, Dialog, DialogContent, Divider, Grid, Icon, TextField } from "@mui/material";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { SELECT_USER } from "store/slices/profile";
import { HotelTypeFormType } from "lib/api/types";
import Post from "lib/api/post";

interface PropsType {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  type_level: number
}

const validationSchema = () =>
  yup.object().shape({
    title: yup.string().required("required"),
    type_code: yup.string().required("required"),
    order: yup.string().required("required"),
  });

const AddRoomType = ({ open, setOpen, type_level }: PropsType) => {
  const user = SELECT_USER();
  
  const [loading, setLoading] = useState<boolean>(false);
  
  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik<HotelTypeFormType>({
      validationSchema,
      initialValues: {
        type_level : type_level,
        type_code: "",
        title: "",
        order: "0"
      },
      onSubmit: (values) => {
        setLoading(true);
        const newValue: any = { ...values, user_id: user.uid, session_id: user.session_id };

        Post.hotelType(newValue)
          .then((res) => {
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      },
    });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <MDTypography variant="h5" fontWeight="bold">Add Room Type</MDTypography>
                    <Divider orientation="horizontal" flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Type Code"
                      value={values.type_code}
                      name="type_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.type_code && errors.type_code ? true : false}
                      helperText={touched.type_code && errors.type_code && errors.type_code}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      value={values.title}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && errors.title ? true : false}
                      helperText={touched.title && errors.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <TextField
                      type="number"
                      label="Order"
                      value={values.order}
                      name="order"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.order && errors.order ? true : false}
                      helperText={touched.order && errors.order && errors.order}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item>
                    <MDButton color={"dark"} type={"submit"}>
                      {(loading && <CircularProgress size="14px" color="primary" />) || (
                        'Submit'
                      )}
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="outlined"
                      disabled={loading}
                      color={"error"}
                      onClick={() => setOpen(false)}
                    >
                      cancel
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddRoomType;
