export const MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "100%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
  p: 2,
  borderRadius: "8px",
};
export const SELECT_STYLE = {
  "&.Mui-focused legend": { visibility: "initial!important", height: 20 },
  height: "44px",
  "& input": { height: "50px" },
  "& legend": {
    transition: ".3s",
    visibility: "initial!important",
    height: 20,
  },
  "& legend span": { background: "transparent", transition: ".3s" },
};
