import { SxProps } from "@mui/material";
import useStyles from "components/customs/core/useStyles";
import { HotelPrintType } from "components/customs/Overview/PreOrganized/types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { memo, useMemo } from "react";
import { TableStyleType } from "../../types";
import { getPassengersCount } from "../../utils";
import { columnStyle, hotelStyle, textStyle } from "../constant";

interface PropsType {
  styles: TableStyleType;
  hotel: HotelPrintType;
}

const HotelRow = ({ styles, hotel }: PropsType) => {
  const classes = useStyles({ linesToShow: 1 });

  const h: SxProps = useMemo(
    () => ({ ...hotelStyle, ...styles?.hotelStyle }),
    [styles?.hotelStyle]
  );
  const c2: SxProps = useMemo(
    () => ({ ...columnStyle, ...styles?.hotelColumnStyle }),
    [styles?.hotelColumnStyle]
  );
  const t2: SxProps = useMemo(
    () => ({ ...textStyle, ...styles?.hotelTextStyle }),
    [styles?.hotelTextStyle]
  );

  return (
    <MDBox sx={h}>
      <MDBox sx={{ ...c2, gridColumn: "1/6", textAlign: "left" }}>
        <MDTypography className={classes.multiLineEllipsis} sx={t2}>
          {hotel.hotel_name}
        </MDTypography>
      </MDBox>
      <MDBox sx={{ ...c2 }}>
        <MDTypography sx={t2}>{getPassengersCount(hotel.passengers)}</MDTypography>
      </MDBox>
      <MDBox sx={{ ...c2, gridColumn: "14/15" }}>
        <MDTypography color={"info"} sx={{ ...t2, fontWeight: "bold" }}>
          {hotel.vip ? "VIP" : ""}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default memo(HotelRow);
