import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";
import { useAppSelector } from "../hooks";

export interface NotificationsType {}

export interface ContactType {
  contact_id: number;
  user_id: number;
  contact_type: string;
  value: string;
  title: string;
  allow_session: number;
  active: number;
}

export interface UserType {
  uid: number;
  uname: string;
  session_id: number;
  cts?: Date;
  avatar: string;
  accessToken: string;
  user_type: string;
  company: string;
  notifications: NotificationsType;
  permissions: string;
  contact: ContactType[];
  meta: any[];
}

export interface CommonStateTypes {
  user: UserType;
}

const initialState: CommonStateTypes = {
  user: {
    uid: 0,
    uname: "",
    session_id: 0,
    avatar: "",
    accessToken: "",
    user_type: "",
    company: "",
    notifications: [],
    permissions: "",
    contact: [],
    meta: [],
  },
};

export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateUser: (state: CommonStateTypes, action: PayloadAction<UserType>) => {
      state.user = action.payload;
    },
  },
});

//
export const { updateUser } = profile.actions;
//
// call in component
export const SELECT_USER = () => useAppSelector((state: AppState) => state.profile.user);

export default profile.reducer;
