export const CHECK_IN = "Check-in";
export const CHECK_OUT = "Check-out";
export const VOUCHERS = "Vouchers";
export const ROOM_COUNT = "ROOM COUNT";
export const RESERVATIONS_COUNT = "RESERVATIONS COUNT";
export const HOTEL = "Hotel";
export const RESELLER_AGENCY = "Reseller/Agency";
export const SELECT_HOTEL = "Select Hotel";
export const SEARCH = "Search";
export const NAME = "Name";
export const DESCRIPTION = "Description";
export const CANCEL = "Cancel";
export const SELECT_AGENCY_RESELLER = "Select Agency/Reseller";
export const TOTAL_IN_HOUSE = "Total In House: ";
export const TOTAL_ROOM_NIGHTS = "Total Room Nights: ";
export const CHECK_IN_EXCEL = "Check-in Excel";
export const CHECK_INHOUSE_EXCEL = "Check-in House";
export const ADULT_BOARD = "Adult: ";
export const INFANT_BOARD = "Infant: ";
export const CHILD_BOARD = "Child: ";
export const No_TRF = "-NoTRF: ";
export const ROOM_NIGHT = "Room Night";
