import { Autocomplete, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AutocompleteType } from "lib/types";
import { memo } from "react";

import TextFieldColumn from "./TextFieldColumn";

export type ColumnType = {
  value: any;
  type?: "autoComplete" | "textField" | "addHotel";
  justEnglish?: boolean;
  options?: AutocompleteType[];
  grid?: { xs?: number; md?: number };
  defaultValue?: AutocompleteType | any;
};
export type KeyType = string | number;
export type RowsType = RowType[];
export type RowType = { [key: KeyType]: ColumnType };

export type OnChangeType = (indexRow: number, key: KeyType, v: AutocompleteType | any) => void;

interface PropsType {
  rows: RowsType;
  spacing?: number;
  onChange?: OnChangeType;
  addHotel?: (indexRow: number) => void;
}

const Table = ({ rows, spacing, onChange, addHotel }: PropsType) => {
  return (
    <MDBox borderRadius="10px" bgColor="#fff" p={3}>
      {rows?.map((row, indexRow) => (
        <MDBox key={indexRow} display="flex" gap={1} pt={2} pb={2} borderBottom={"1px solid #ddd8"}>
          <Grid spacing={spacing || 1} container>
            {Object.keys({ ...row }).map((c, indexColumn) => (
              <Grid
                key={indexColumn}
                item
                {...(row[c].grid || { xs: 3, md: 3 })}
                overflow={"scroll"}
                display="flex"
                alignItems={"center"}
                sx={{
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                }}
              >
                <MDBox width="100%" borderRadius={"10px"}>
                  {row[c].type === "autoComplete" ? (
                    // && row[c].options && row[c].options
                    <div>
                      <MDTypography fontSize={12}>{row[c].value || "-"}</MDTypography>
                      <Autocomplete
                        fullWidth
                        onChange={(e, t) => onChange(indexRow, c, t)}
                        disableClearable
                        options={row[c]?.options || []}
                        value={row[c]?.defaultValue || { label: "", value: "" }}
                        isOptionEqualToValue={(option) => option?.id === option?.id}
                        getOptionLabel={(option) => option?.label || ""}
                        renderInput={(props) => <TextField {...props} />}
                      />
                    </div>
                  ) : row[c].type === "textField" ? (
                    <TextFieldColumn
                      value={row[c]?.value}
                      onChange={(v) => onChange(indexRow, c, v)}
                      defaultValue={row[c]?.defaultValue}
                      justEnglish={row[c]?.justEnglish}
                    />
                  ) : (
                    <>
                      <MDTypography fontSize={12}>{row[c].value}</MDTypography>
                      {row[c]?.defaultValue?.id ? (
                        <>
                          <MDTypography fontSize={12} sx={{ color: "darkCyan" }}>
                            {row[c]?.defaultValue?.label}
                          </MDTypography>
                        </>
                      ) : null}
                    </>
                  )}
                </MDBox>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      ))}
    </MDBox>
  );
};

export default memo(Table);
