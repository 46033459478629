import { configureStore, ThunkAction, combineReducers } from "@reduxjs/toolkit";
import { Action } from "redux";
import common from "./slices/common";
import profile from "./slices/profile";
import operations from "./slices/operations";
const combineReducer = combineReducers({
  common,
  profile,
  operations,
});

export const store = (ctx?: any) => {
  const store = configureStore({
    reducer: combineReducer,
    devTools: true,
  });

  return store;
};
// typeof makeStore.dispatch
export type AppStore = ReturnType<typeof store>;
export type AppDispatch = AppStore["dispatch"];
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store();
