import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  DispatchStyleType,
  DispatchType,
  HeightRadio,
  HeightRadioName,
  ValuesType,
} from "./useSetting";

interface PropsType {
  values: ValuesType;
  logoSaveTo?: string;
  heightRadio: HeightRadio;
  dispatchStyle: DispatchStyleType;
  dispatch: DispatchType;
}

const Settings = ({ values, logoSaveTo, heightRadio, dispatchStyle, dispatch }: PropsType) => {
  const { styles, logo }: { styles: any; logo?: string } = values;

  return (
    <Card
      sx={{
        position: "fixed",
        top: "24px",
        left: "16px",
        width: "250px",
        background: "#fff",
        p: 3,
        zIndex: 90000,
        height: "calc(100vh - 40px)",
        overflow: "scroll",
      }}
    >
      <MDBox>
        <MDBox display="flex" flexWrap={"wrap"} gap={2}>
          {/* table */}
          <MDTypography fontSize={"14px"}>table height</MDTypography>
          <RadioGroup
            value={heightRadio.name}
            onChange={(e, value) => {
              dispatch("CHANGE_HEIGHT_RADIO", value);
            }}
          >
            <FormControlLabel value={HeightRadioName.A4} control={<Radio />} label="A4" />
            <FormControlLabel
              sx={{ display: "flex" }}
              value={HeightRadioName.CUSTOM}
              control={<Radio />}
              label={
                <TextField
                  disabled={heightRadio.name !== HeightRadioName.CUSTOM}
                  type={"number"}
                  value={heightRadio?.value?.replace("cm", "") || ""}
                  onChange={(e) => {
                    dispatch("CHANGE_CUSTOM_HEIGHT_RADIO", `${e.target.value}cm`);
                  }}
                />
              }
            />
          </RadioGroup>
          <Divider sx={{ borderColor: "#aaa", width: "100%", margin: "4px" }} />
          {/* row */}

          {/* t1 */}
          <MDBox width="100%" display="flex" gap={2}>
            <MDBox component="label">
              <MDTypography fontSize={"10px"}>header size</MDTypography>
              <TextField
                type={"number"}
                value={styles?.textStyle?.fontSize}
                onChange={(e) => {
                  dispatchStyle("textStyle", { fontSize: parseInt(e.target.value) });
                }}
              />
            </MDBox>
            <MDBox component="label" px={2} pt={3} display="flex" alignItems={"center"}>
              <MDTypography fontSize={"10px"}>header bold?</MDTypography>
              <Checkbox
                checked={styles?.textStyle?.fontWeight === "800"}
                onChange={(e) => {
                  dispatchStyle("textStyle", { fontWeight: e.target.checked ? "800" : "normal" });
                }}
              />
            </MDBox>
          </MDBox>
          {/* t2 */}
          <MDBox width="100%" display="flex" gap={2}>
            <MDBox component="label">
              <MDTypography fontSize={"10px"}>hotel size</MDTypography>
              <TextField
                type={"number"}
                value={styles?.hotelTextStyle?.fontSize}
                onChange={(e) => {
                  dispatchStyle("hotelTextStyle", { fontSize: parseInt(e.target.value) });
                }}
              />
            </MDBox>
            <MDBox component="label" px={2} pt={3} display="flex" alignItems={"center"}>
              <MDTypography fontSize={"10px"}>header bold?</MDTypography>
              <Checkbox
                checked={styles?.hotelTextStyle?.fontWeight === "500"}
                onChange={(e) => {
                  dispatchStyle("hotelTextStyle", {
                    fontWeight: e.target.checked ? "500" : "normal",
                  });
                }}
              />
            </MDBox>
          </MDBox>
          {/* t3 */}
          <MDBox width="100%" display="flex" gap={2}>
            <MDBox component="label">
              <MDTypography fontSize={"10px"}>passenger size</MDTypography>
              <TextField
                type={"number"}
                value={styles?.passengerTextStyle?.fontSize}
                onChange={(e) => {
                  dispatchStyle("passengerTextStyle", { fontSize: parseInt(e.target.value) });
                }}
              />
            </MDBox>
          </MDBox>
          {values ? (
            <>
              {logoSaveTo && (
                <MDBox width="100%" display="flex" gap={2}>
                  <MDBox component="label">
                    <MDTypography fontSize={"10px"}>log-url</MDTypography>
                    <TextField
                      type={"text"}
                      value={logo || ""}
                      onChange={(e) => {
                        dispatch("UPDATE_LOGO", e.target.value);
                      }}
                    />
                  </MDBox>
                </MDBox>
              )}
            </>
          ) : null}
        </MDBox>
        <Button sx={{ position: "absolute", bottom: 10 }} onClick={() => dispatch("RESET")}>
          clean
        </Button>
      </MDBox>
    </Card>
  );
};

export default Settings;
