export const convertDate = (d: Date) => {
  if (!d) return "";
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const realDateHandler: (time: Date) => string = (time) => {
  if (!time) return "";
  const y = time.getFullYear();
  const m = time.getMonth();
  const d = time.getDate();
  // sample: "2022-07-17"
  const newDate = `${y}-${m < 9 ? `0${m}` : m}-${d < 9 ? `0${d}` : d}`;
  return newDate;
};

const dateHandler: (time: string) => string = (time) => {
  if (!time) return "";
  let newTime = time.slice(0, 10);
  return newTime || "";
};
export default dateHandler;

//
//
//
//
//
//
//

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function addHoursToDate(date: Date, hours: number): Date {
  return new Date(new Date(date).setHours(date.getHours() + hours));
}

export function formatDate(date: Date) {
  const newDate = addHoursToDate(date, 1);

  return [
    newDate.getFullYear(),
    padTo2Digits(newDate.getMonth() + 1),
    padTo2Digits(newDate.getDate()),
  ].join("-");
}
