import { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { Box, CircularProgress, Dialog, DialogContent, Divider, Grid, Icon } from "@mui/material";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { SELECT_USER } from "store/slices/profile";
import { RoomType } from "lib/api/types";
import SelectRoomType from "./SelectRoomType";
import Post from "lib/api/post";
import Put from "lib/api/put";

interface PropsType {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  hotel_id: number,
  room_id: number,
  pax?: string,
  room_type1?: number,
  room_type1_code?: string,
  room_type1_title?: string,
  room_type2?: number,
  room_type2_code?: string,
  room_type2_title?: string,
  set_room_id: React.Dispatch<React.SetStateAction<number>>,
  set_pax?: React.Dispatch<React.SetStateAction<string>>,
  set_room_type1: React.Dispatch<React.SetStateAction<number>>,
  set_room_type1_code: React.Dispatch<React.SetStateAction<string>>,
  set_room_type1_title: React.Dispatch<React.SetStateAction<string>>,
  set_room_type2: React.Dispatch<React.SetStateAction<number>>,
  set_room_type2_code: React.Dispatch<React.SetStateAction<string>>,
  set_room_type2_title: React.Dispatch<React.SetStateAction<string>>,
  roomAdded: (v: any) => void;
}

const validationSchema = () =>
  yup.object().shape({
    room_type1_title: yup.string().required("required"),
    room_type2_title: yup.string().required("required"),
  });

const RoomForm = ({ roomAdded, open, setOpen, room_id, hotel_id, pax, room_type1, room_type1_code, room_type1_title, room_type2, room_type2_code, room_type2_title, set_room_id, set_pax, set_room_type1, set_room_type1_code, set_room_type1_title, set_room_type2, set_room_type2_code, set_room_type2_title }: PropsType) => {
  const user = SELECT_USER();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [open_room_type, set_open_room_type] = useState(false);
  const [open_room_subtype, set_open_room_subtype] = useState(false);
  
  const { handleSubmit, errors, values } =
    useFormik<RoomType>({
      validationSchema,
      initialValues: {
        hotel_id: hotel_id ? hotel_id : null,
        room_id: room_id ? room_id : null,
        pax: pax ? pax : "{}",  // Where should we fill the pax?
        room_type1: room_type1 ? room_type1 : null,
        room_type1_code: room_type1_code ? room_type1_code : null,
        room_type1_title: room_type1_title ? room_type1_title : null,
        room_type2: room_type2 ? room_type2 : null,
        room_type2_code: room_type2_code ? room_type2_code : null,
        room_type2_title: room_type2_title ? room_type2_title : null
      },
      onSubmit: (values) => {
        setLoading(true);
        const newValue: any = { ...values, user_id: user.uid, session_id: user.session_id };

        if (hotel_id && room_id) {
          Put.updateRoom(newValue)
          .then((res) => {
            setLoading(false);
            setOpen(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
          return;
        }

        Post.addRoom(newValue)
          .then((res) => {
            setLoading(false);
            roomAdded(res.data);
            setOpen(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      },
    });

  const handleOpenRoomType = useCallback(() => {
    set_open_room_type(true)
  },[set_open_room_type]);
  
  const handleRemoveRoomType = useCallback(() => {
    values.room_type1_title = null;
    values.room_type1_code = null;
    values.room_type1_title = null;
    set_room_type1(null);
    set_room_type1_code(null);
    set_room_type1_title(null);
  },[values, set_room_type1, set_room_type1_code, set_room_type1_title]);
  
  const handleOpenRoomSubType = useCallback(() => {
    set_open_room_subtype(true)
  },[set_open_room_subtype]);
  
  const handleRemoveRoomSubType = useCallback(() => {
    values.room_type2_title = null;
    values.room_type2_code = null;
    values.room_type2_title = null;
    set_room_type2(null)
    set_room_type2_code(null)
    set_room_type2_title(null)
  },[values, set_room_type2, set_room_type2_code, set_room_type2_title]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <MDTypography variant="h5" fontWeight="bold">Room Form</MDTypography>
                    <Divider orientation="horizontal" flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={9}>
                        <Grid container>
                          <Grid item xs={4}>
                          <MDTypography variant="inherit">Room Type:</MDTypography>
                          </Grid>
                          <Grid item xs={8}>
                            {room_type1_title ? 
                              <MDTypography variant="inherit">{room_type1_title}</MDTypography>
                            : 
                              <MDTypography variant="inherit" color="text">Select Room Type</MDTypography>
                            }
                          </Grid>
                        </Grid>
                        {errors.room_type1_title ? 
                          <MDTypography variant="inherit" color="error">Choose a room type</MDTypography>
                          : null}
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container justifyContent="space-between">
                          <MDButton variant="outlined" color="info" onClick={handleOpenRoomType}><Icon>list</Icon></MDButton>
                          <MDButton variant="outlined" color="error" onClick={handleRemoveRoomType}><Icon>close</Icon></MDButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={9}>
                        <Grid container>
                          <Grid item xs={4}>
                          <MDTypography variant="inherit">Room Subtype:</MDTypography>
                          </Grid>
                          <Grid item xs={8}>
                            {room_type2_title ? 
                              <MDTypography 
                              variant="inherit" 
                              >{room_type2_title}</MDTypography>
                            : 
                              <MDTypography variant="inherit" color="text">Select Room Sub-Type</MDTypography>
                            }
                          </Grid>
                        </Grid>
                        {errors.room_type2_title ? 
                          <MDTypography variant="inherit" color="error">Choose a room sub-type</MDTypography>
                          : null}
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container justifyContent="space-between">
                          <MDButton variant="outlined" color="info" onClick={handleOpenRoomSubType}><Icon>list</Icon></MDButton>
                          <MDButton ml={3} variant="outlined" color="error" onClick={handleRemoveRoomSubType}><Icon>close</Icon></MDButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item>
                    <MDButton color={"dark"} type={"submit"}>
                      {(loading && <CircularProgress size="14px" color="primary" />) || (
                        'Submit'
                      )}
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="outlined"
                      disabled={loading}
                      color={"error"}
                      onClick={() => setOpen(false)}
                    >
                      cancel
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {open_room_type ?
        <Grid item xs={12}>
          <SelectRoomType
            open = {open_room_type}
            setOpen = {set_open_room_type}
            type_level = {1}
            roomTypeSelected = {(item: any):void => {
              values.room_type1 = item.type_id;
              values.room_type1_code = item.type_code;
              values.room_type1_title = item.title;
              set_room_type1(item.type_id);
              set_room_type1_code(item.type_code);
              set_room_type1_title(item.title);
            }}
          />
        </Grid>
      :null}
      {open_room_subtype ?
        <Grid item xs={12}>
          <SelectRoomType
            open = {open_room_subtype}
            setOpen = {set_open_room_subtype}
            type_level = {2}
            roomTypeSelected = {(item: any):void => {
              values.room_type2 = item.type_id;
              values.room_type2_code = item.type_code;
              values.room_type2_title = item.title;
              set_room_type2(item.type_id);
              set_room_type2_code(item.type_code);
              set_room_type2_title(item.title);
            }}
          />
        </Grid>
      :null}
    </>
  );
};

export default RoomForm;
