import { LeaderType, PageTableType } from "./types";

export type GeneratePageCallbackType = (table: PageTableType[]) => void;

export const generatePages = ({
  leaders,
  tableStyle,
  callback,
}: {
  leaders: LeaderType[];
  tableStyle: any;
  callback: GeneratePageCallbackType;
}) => {
  const HEADER_HEIGHT = 170;
  const height = convertCmToPx(+tableStyle.height.replace("cm", "")) - HEADER_HEIGHT;

  if (tableStyle.height.length < 2) {
    return;
  }
  const newLeaders = [...leaders];

  let tables: PageTableType[] = [];

  newLeaders.forEach((leader) => {
    let adds = 0;
    let page = 0;
    const newTable: PageTableType = { leader, pages: [] };
    leader.flights.forEach((flight) => {
      if (adds + flight.height < height) {
        adds = adds + flight.height;
      } else {
        adds = flight.height;
        page++;
      }
      if (!newTable.pages[page]) {
        newTable.pages.push([flight]);
      } else {
        newTable.pages[page].push(flight);
      }
    });
    tables.push(newTable);
  });
  callback(tables);
};

export const convertPxtoCm = (px: number) => px * 0.026458;
export const convertCmToPx = (cm: number) => cm * 37.7952755906;
