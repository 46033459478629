import { useCallback, useState } from "react";

import { FlightPrintType, PassengerRowType } from "../Table/types";
import { convertCmToPx } from "../utils";
import { PageDataType, PageType, PaxRowType } from "./types";
import { FlightRowType } from "../Table/types";
import { addEndLine, addRow } from "./utils";
import { ENDLINE_HEIGHT } from "../Table/row/constant";
interface PropsType {
  styles: any;
  headHeight?: number;
  flies: FlightPrintType[];
  endlineHeight?: number;
}

export const usePrint = ({
  styles,
  headHeight = 90 + 30,
  flies,
  endlineHeight = ENDLINE_HEIGHT,
}: PropsType) => {
  const [data, setData] = useState<PageType[]>();
  const prepareTable = useCallback(
    (flies: FlightPrintType[]) => {

      if (!styles?.tableStyle?.height) return;
      const rowHeight = styles.textStyle.fontSize * 1.625;
      const hotelHeight = styles.hotelTextStyle.fontSize * 1.625;
      const passengerHeight = styles.passengerTextStyle.fontSize * 1.625;

      const pageHeight = convertCmToPx(+styles.tableStyle.height.replace("cm", "")) - headHeight;
      const newFlies = [...flies];

      let pageData: PageDataType = {
        pages: [],
        pageIndex: 0,
        adds: 0,
      };
      newFlies.forEach((flight) => {
        /*
        add flight-row
        */
        const flightRow = { ...flight, type: "flight" } as FlightRowType;
        pageData = addRow(pageData, pageHeight, flightRow, rowHeight, flightRow, rowHeight);

        flight.transfers.forEach((transfer) => {
          transfer.paxs.forEach((pax) => {
            /*
             pax in hotel
            */
            const paxRow = { pax, flight, transfer, type: "hotel" } as PaxRowType;
            pageData = addRow(pageData, pageHeight, paxRow, hotelHeight, flightRow, rowHeight);
            /*
             passengers
            */
            if (Array.isArray(pax.passengers)) {
              pax.passengers.forEach((passenger) => {
                const passengerRow = { ...passenger, type: "passenger" } as PassengerRowType;
                pageData = addRow(
                  pageData,
                  pageHeight,
                  passengerRow,
                  passengerHeight,
                  flightRow,
                  rowHeight
                );
              });
            } else {
              const passengerRow = { ...pax.passengers, type: "passenger" } as PassengerRowType;
              pageData = addRow(
                pageData,
                pageHeight,
                passengerRow,
                passengerHeight,
                flightRow,
                rowHeight
              );
            }
            /*
            
            */
          });
        });
      });

      const pages = addEndLine(
        pageData.pages,
        pageHeight,
        rowHeight,
        hotelHeight,
        passengerHeight,
        endlineHeight
      );

      setData(pages);
    },
    [styles]
  );

  const reset = () => {
    setData(undefined);
  };

  return {
    data,
    reset,
    prepareTable,
  };
};
