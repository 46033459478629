import { useCallback, useState } from "react";
import { convertCmToPx } from "../utils";
import { FlyPrintType, PageDataType, PageType } from "./types";
import { addRow, addEndLine } from "./utils";
import { ENDLINE_HEIGHT } from "../Table/row/constant";

interface PropsType {
  styles: any;
  headHeight?: number;
  endLineHeight?: number;
}

export const usePrint = ({
  styles,
  headHeight = 90 + 30,
  endLineHeight = ENDLINE_HEIGHT,
}: PropsType) => {
  const [data, setData] = useState<PageType[]>();
  const prepareTable = useCallback(
    (flies: FlyPrintType[]) => {
      if (!styles?.tableStyle?.height) return;
      const rowHeight = styles.textStyle.fontSize * 1.625;
      const hotelHeight = styles.hotelTextStyle.fontSize * 1.625;
      const pageHeight = convertCmToPx(+styles.tableStyle.height.replace("cm", "")) - headHeight;

      const newFlies = [...flies];

      let pageData: PageDataType = {
        pages: [],
        pageIndex: 0,
        adds: 0,
      };

      newFlies.forEach((fly) => {
        pageData = addRow(pageData, pageHeight, fly, rowHeight, fly, rowHeight);

        fly.hotels.forEach((hotel) => {
          pageData = addRow(pageData, pageHeight, hotel, hotelHeight, fly, rowHeight);
        });
      });

      const pages = addEndLine(pageData.pages, pageHeight, endLineHeight, rowHeight, hotelHeight);

      setData(pages);
    },
    [styles]
  );

  const reset = () => {
    setData(undefined);
  };

  return {
    data,
    reset,
    prepareTable,
  };
};
