export const PENDING = "Pending";
export const CONFIRM = "Confirm";
export const CANCELED = "Canceled";
export const NO_SHOW = "No Show";
export const STATUS = "Status";
export const VOUCHER = "Voucher";
export const AGENCY_RESELLER = "Agency/Reseller";
export const RESELLER = "Reseller";
export const HOTELS = "Hotels";
export const HOTEL = "Hotel";
export const ADD = "Add";
export const FULL_NAME = "Full Name";
export const USER_TYPE = "User Type";
export const COMPANY = "Company";
export const BIRTHDAY = "Birthday";
export const CITY = "City";
export const TELEGRAM_ID = "Telegram ID";
export const GENDER = "Gender";
export const ADD_USER = "Add User";
export const ADD_CONTACT = "Add Contact";
export const ADD_CONTACT_ITEM = "Add Contact Item";
export const TITLE = "Title";
export const VALUE = "Value";
export const LOGIN_ALLOWED = "Login allowed?";
export const CANCEL = "Cancel";
export const SUBMIT = "Submit";
export const CONTACT_TYPE = "Contact Type";
export const CONTACT_DATA = "Contact Data";
export const YES = "Yes";
export const NO = "No";
export const MALE = "Male";
export const FEMALE = "Female";
export const NEW_ROOM = "New Room";
export const SELECT_TYPE = "Select Type";
export const SELECT_SUB_TYPE = "Select SubType";
export const COUNT = "Count";
export const HOTEL_NAME = "Hotel Name";
export const ROOM_TYPE = "Room Type";
export const REMOVE = "Remove";
export const ADD_ROOM_TYPE = "Add Room Type";
export const SELECT_HOTEL = "Select Hotel";
export const SEARCH = "Search";
export const NAME = "Name";
export const DESCRIPTION = "Description";
export const SELECT_AGENCY_RESELLER = "Select Agency/Reseller";
