import { AddEndLineType, AddRowType } from "./types";

export const addRow: AddRowType = (
  pageData,
  pageHeight,
  row,
  rowHeight,
  flightRow,
  flightHeight
) => {
  const isRowFlight = row.type === "flight";
  let { adds, pageIndex, pages } = { ...pageData };

  if (adds + rowHeight < pageHeight) {
    adds += rowHeight;
  } else {
    pageIndex++;
    adds = isRowFlight ? rowHeight : flightHeight + rowHeight;
  }
  if (pages[pageIndex]) {
    pages[pageIndex].push(row);
  } else {
    pages.push(isRowFlight ? [row] : [flightRow, row]);
  }
  return { adds, pageIndex, pages };
};

export const addEndLine: AddEndLineType = (
  pages,
  pageHeight,
  rowHeight,
  hotelHeight,
  passengerHeight,
  endlineHeight
) => {
  const newPages = [...pages];
  if (!newPages.length) return newPages;
  let adds = 0;
  newPages[newPages.length - 1].forEach((p) => {
    switch (p.type) {
      case "flight":
        adds += rowHeight;
        break;
      case "hotel":
        adds += hotelHeight;
        break;
      case "passenger":
        adds += passengerHeight;
        break;
      default:
        break;
    }
  });

  const freeSpace = pageHeight - adds;
  if (freeSpace >= endlineHeight) {
    newPages[newPages.length - 1].push({ type: "endLine" });
  }
  return newPages;
};
