import React, { useState, createContext, Dispatch, SetStateAction, useContext } from "react";

type FormContextProps = any;

type ContextValue = {
  formContext: FormContextProps;
  setFormContext: Dispatch<SetStateAction<FormContextProps>>;
};

const FormContext = createContext<ContextValue | undefined>(undefined);

const FormContextProvider: React.FC = ({ children }) => {
  const [formContext, setFormContext] = useState<FormContextProps>({
    hotel_note: "",
    operation_note: "",
    status: 1,
    rooms: [],
    extras: [],
  });

  return (
    <FormContext.Provider value={{ formContext, setFormContext }}>{children}</FormContext.Provider>
  );
};

export default FormContextProvider;

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used inside the FormContextProvider");
  }
  return context;
};
