import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Calendar from "components/customs/Calendar";
import { useCallback, useEffect, useState } from "react";
import { DateClickArg } from "@fullcalendar/interaction";
import { CalendarEventType } from "lib/types/calenderType";
import {
  ReservationsBoardResponseType,
  ReservationsEXCELBoardResponseType,
} from "lib/types/api-type";
import Post from "lib/api/post";
import Get from "lib/api/get";
import {
  CHECK_IN,
  CHECK_OUT,
  VOUCHERS,
  RESERVATIONS_COUNT,
  ROOM_COUNT,
  TOTAL_IN_HOUSE,
  CHECK_IN_EXCEL,
  TOTAL_ROOM_NIGHTS,
  CHECK_INHOUSE_EXCEL,
  ADULT_BOARD,
  INFANT_BOARD,
  CHILD_BOARD,
  No_TRF,
  ROOM_NIGHT,
} from "./constants";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import MDDatePicker from "components/MDDatePicker";
import { SELECT_USER } from "store/slices/profile";
import HotelFilter from "./HotelFilter";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ResellerAgencyFilter from "./ResellerAgencyFilter";
import { Button, Typography } from "@mui/material";
import { BASE_URL } from "lib/constants/urls";

function Board(): JSX.Element {
  const user = SELECT_USER();

  const [events, setEvents] = useState<CalendarEventType[]>([]);
  const [data, setData] = useState<ReservationsBoardResponseType>();
  const [dataExcel, setDataExcel] = useState<ReservationsEXCELBoardResponseType>();
  const [datePickerValues, setDatePickerValues] = useState({
    from: sessionStorage.getItem("from")
      ? moment(sessionStorage.getItem("from")).format("YYYY-MM-DD")
      : moment().startOf("month").format("YYYY-MM-DD"),
    to: sessionStorage.getItem("to")
      ? moment(sessionStorage.getItem("to")).format("YYYY-MM-DD")
      : moment().endOf("month").format("YYYY-MM-DD"),
  });
  const [filerHotelValue, setFilerHotelValue] = useState(
    sessionStorage.getItem("hotel_fullname")
      ? {
          fullname: sessionStorage.getItem("hotel_fullname"),
          hotel_id: +sessionStorage.getItem("hotel_id"),
        }
      : {
          fullname: null,
          hotel_id: null,
        }
  );
  const [filterResellerValue, setFilterResellerValue] = useState(sessionStorage.getItem("user_fullname")
  ? {
      fullname: sessionStorage.getItem("user_fullname"),
      user_id: +sessionStorage.getItem("user_id"),
    }
  : {
      fullname: null,
      user_id: null,
    });

  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      hotel_id: filerHotelValue?.hotel_id ?? -1,
      reseller_id: filterResellerValue?.user_id ?? -1,
      ...datePickerValues,
    };
    datePickerValues?.from &&
      datePickerValues?.to &&
      Post.reservationsBoard(payload)
        .then((res) => {
          const { data } = res;
          setData(data);
        })
        .catch((err) => console.log(err));
  }, [
    datePickerValues,
    filerHotelValue?.hotel_id,
    filterResellerValue?.user_id,
    user?.session_id,
    user?.uid,
  ]);

  useEffect(() => {
    setFilerHotelValue(
      sessionStorage.getItem("hotel_fullname")
        ? {
            fullname: sessionStorage.getItem("hotel_fullname"),
            hotel_id: +sessionStorage.getItem("hotel_id"),
          }
        : {
            fullname: null,
            hotel_id: null,
          }
    );
  }, []);

  const createEvents = (data: ReservationsBoardResponseType) => {
    const customCheckin: CalendarEventType[] = [];
    const customCheckout: CalendarEventType[] = [];
    const customReservations: CalendarEventType[] = [];
    data?.checkin?.forEach((item: { count: number; date: string }) => {
      customCheckin.push({
        title: `${CHECK_IN}: ${item?.count}`,
        start: item?.date,
        end: item?.date,
        className: "success",
      });
    });
    data?.checkout?.forEach((item: { count: number; date: string }) => {
      customCheckout.push({
        title: `${CHECK_OUT}: ${item?.count}`,
        start: item?.date,
        end: item?.date,
        className: "dark",
      });
    });

    data?.reservations?.forEach((item: { count: number; date: string }) => {
      customReservations.push({
        title: `${VOUCHERS}: ${item?.count}`,
        start: item?.date,
        end: item?.date,
        className: "info",
      });
    });

    setEvents([...customCheckin, ...customCheckout, ...customReservations]);
  };

  useEffect(() => {
    createEvents(data);
  }, [data]);

  const handleNavigate = useCallback(
    (date: string) => {
      return navigate(
        `/board-detail?${`date=${date}&&hotel_id=${filerHotelValue?.hotel_id ?? -1}&&reseller_id=${
          filterResellerValue?.user_id ?? -1
        }`}`
      );
    },
    [filerHotelValue?.hotel_id, filterResellerValue?.user_id, navigate]
  );
  const dateClickHandler = useCallback(
    (e: DateClickArg) => {
      handleNavigate(e?.dateStr);
    },
    [handleNavigate]
  );

  const eventClickHandler = useCallback(
    (e: any) => {
      const date = moment(e).format("YYYY-MM-DD");
      handleNavigate(date);
    },
    [handleNavigate]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid>
        <Grid bgcolor={"#fff"} borderRadius="10px" p={2} mb={2}>
          <MDDatePicker
            input={{ placeholder: "Reserve Date", sx: { width: "100%" } }}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              defaultDate: [datePickerValues?.from, datePickerValues?.to],
            }}
            value={[datePickerValues?.from || "", datePickerValues?.to || ""]}
            onChange={(e: any) => {
              if (e.length === 2) {
                const fromDate = moment(e[0]).format("YYYY-MM-DD");
                const toDate = moment(e[1]).format("YYYY-MM-DD");
                setDatePickerValues({
                  from: fromDate,
                  to: toDate,
                });
                sessionStorage.setItem("from", e[0]);
                sessionStorage.setItem("to", e[1]);
              }
            }}
          />
          <Grid display="flex">
            <HotelFilter
              setFilerHotelValue={setFilerHotelValue}
              filerHotelValue={filerHotelValue}
            />
            <ResellerAgencyFilter
              filterResellerValue={filterResellerValue}
              setFilterResellerValue={setFilterResellerValue}
            />
          </Grid>
          <Grid display="flex">
            <Grid item display="flex" py={2} xs={4}>
              <Typography>
                {TOTAL_ROOM_NIGHTS} {data?.room_nights}
              </Typography>
            </Grid>
            <Grid item display="flex" py={2} xs={4}>
              <Typography>
                {TOTAL_IN_HOUSE} {data?.in_houses}
              </Typography>
            </Grid>
            <Grid item display="flex" py={2} xs={4}>
              <Grid display="flex" justifyContent="flex-end">
                <Button variant="contained" color="primary">
                  <a
                    href={`${BASE_URL}/reservations/checkinExcelReport?from=${
                      datePickerValues?.from
                    }&to=${datePickerValues?.to}&type=${"checkin"}&hotel_id=${
                      filerHotelValue?.hotel_id ?? -1
                    }&reseller_id=${filterResellerValue?.user_id ?? -1}&sid=${
                      user?.session_id
                    }&tk=${user?.accessToken}`}
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    {CHECK_IN_EXCEL}
                  </a>
                </Button>
              </Grid>
            </Grid>
            <Grid item display="flex" py={2} xs={4}>
              <Grid display="flex" justifyContent="flex-end">
                <Button variant="contained" color="primary">
                  <a
                    href={`${BASE_URL}/reservations/checkinExcelReport?from=${
                      datePickerValues?.from
                    }&to=${datePickerValues?.to}&type=${"inhouse"}&hotel_id=${
                      filerHotelValue?.hotel_id ?? -1
                    }&reseller_id=${filterResellerValue?.user_id ?? -1}&sid=${
                      user?.session_id
                    }&tk=${user?.accessToken}`}
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    {CHECK_INHOUSE_EXCEL}
                  </a>
                </Button>
              </Grid>
            </Grid>
            <Grid item display="flex" py={2} xs={4}>
              <Grid display="flex" justifyContent="flex-end">
                <Button variant="contained" color="primary">
                  <a
                    href={`${BASE_URL}/reservations/checkinExcelReport?from=${
                      datePickerValues?.from
                    }&to=${datePickerValues?.to}&type=${"room_night"}&hotel_id=${
                      filerHotelValue?.hotel_id ?? -1
                    }&reseller_id=${filterResellerValue?.user_id ?? -1}&sid=${
                      user?.session_id
                    }&tk=${user?.accessToken}`}
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    {ROOM_NIGHT}
                  </a>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid display="flex">
            <Grid item display="flex" py={2} xs={4} justifyContent={"start"}>
              <Typography>
                {ADULT_BOARD} {data?.pass_statis?.adult} {No_TRF} {data?.pass_statis?.adult_notrf}
              </Typography>
            </Grid>
            <Grid item display="flex" py={2} xs={4} justifyContent={"center"}>
              <Typography>
                {INFANT_BOARD} {data?.pass_statis?.infant} {No_TRF}{" "}
                {data?.pass_statis?.infant_notrf}
              </Typography>
            </Grid>
            <Grid item display="flex" py={2} xs={4} justifyContent={"center"}>
              <Typography>
                {CHILD_BOARD} {data?.pass_statis?.child} {No_TRF} {data?.pass_statis?.child_notrf}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Calendar
            sx={{
              "& .fc-daygrid-day": {
                height: "110px",
              },
            }}
            dateClick={dateClickHandler}
            events={events}
            eventClicked={eventClickHandler}
          />
        </Grid>

        <Grid bgcolor={"#fff"} borderRadius="10px" p={2} mt={2}>
          <MDBox display="flex" gap={1}>
            <MDButton color="success">{`${CHECK_IN}: ${ROOM_COUNT}`}</MDButton>
            <MDButton color="dark">{`${CHECK_OUT}: ${ROOM_COUNT}`}</MDButton>
            <MDButton color="info">{`${RESERVATIONS_COUNT}`}</MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Board;
