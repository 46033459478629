import Modal from "@mui/material/Modal";
import {
  Box,
  FormControl,
  Select,
  Typography,
  Divider,
  Grid,
  Button,
  InputLabel,
} from "@mui/material";
import { CANCEL, SELECT_NEW_ROOM_TYPE, UPDATE } from "./const";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { MODAL_STYLE, SELECT_STYLE } from "../../style";
import { SELECT_SUB_TYPE, SELECT_TYPE } from "../../HotelTab/const";
import { useRequirmentContext } from "../../RequirmentContextProvider";
import { useFormContext } from "../../FormContextProvide";

interface EditRoomTypeDialogType {
  open: boolean;
  onClose: () => void;
  roomIndex: number;
}

const EditRoomTypeDialog = ({ open, onClose, roomIndex }: EditRoomTypeDialogType) => {
  const { formContext, setFormContext } = useFormContext();
  const { requirmentContext } = useRequirmentContext();

  const { handleSubmit, handleChange, handleBlur, values } = useFormik<any>({
    initialValues: {
      select_type: JSON.stringify({
        type_id: formContext.rooms[roomIndex].room_type1,
        title: formContext.rooms[roomIndex].room_type1_title,
        type_code: formContext.rooms[roomIndex].room_type1_code,
        capacity: formContext.rooms[roomIndex].room_type1_capacity,
      }),
      select_subtype: JSON.stringify({
        type_id: formContext.rooms[roomIndex].room_type2,
        title: formContext.rooms[roomIndex].room_type2_title,
        type_code: formContext.rooms[roomIndex].room_type2_code,
      }),
    },

    onSubmit: (values) => {
      const select_type = JSON.parse(values?.select_type);
      const select_subtype = JSON.parse(values?.select_subtype);
      const count = formContext.rooms[roomIndex].count;

      const passengers = [];
      for (let i = 0; i < select_type.capacity * count; i++) {
        passengers.push({ id: uuidv4(), label: "", user_id: "" });
      }
      const payload = {
        room_type1: select_type?.type_id,
        room_type1_title: select_type?.title,
        room_type1_code: select_type?.type_code,
        room_type1_capacity: select_type.capacity,
        room_type2: select_subtype?.type_id,
        room_type2_title: select_subtype?.title,
        room_type2_code: select_subtype?.type_code,
        passengers,
      };
      const newRooms = formContext.rooms;

      newRooms[roomIndex] = { ...newRooms[roomIndex], ...payload };

      setFormContext({ ...formContext, rooms: newRooms });

      onClose();
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={MODAL_STYLE} component={"form"} onSubmit={handleSubmit}>
        <Typography>{SELECT_NEW_ROOM_TYPE}</Typography>

        <Divider sx={{ borderWidth: "1px" }} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="select_type">{SELECT_TYPE}</InputLabel>
              <Select
                native
                label={SELECT_TYPE}
                fullWidth
                name="select_type"
                id="select_type"
                required
                value={values.select_type}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option aria-label="None" value="" />

                {requirmentContext?.room_types?.map((item: any) => (
                  <optgroup label={item.title}>
                    <option
                      value={JSON.stringify({
                        type_id: item.type_id,
                        title: item.title,
                        type_code: item.type_code,
                        capacity: item.capacity,
                      })}
                    >
                      {item.type_code}
                    </option>
                  </optgroup>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="select_subtype">{SELECT_SUB_TYPE}</InputLabel>
              <Select
                native
                label={SELECT_SUB_TYPE}
                fullWidth
                name="select_subtype"
                id="select_subtype"
                required
                value={values.select_subtype}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option aria-label="None" value="" />
                {requirmentContext?.room_subtypes?.map((item: any) => (
                  <optgroup label={item.title}>
                    <option
                      value={JSON.stringify({
                        type_id: item.type_id,
                        title: item.title,
                        type_code: item.type_code,
                      })}
                    >
                      {item.type_code}
                    </option>
                  </optgroup>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Divider sx={{ borderWidth: "1px" }} />
        <Grid display="flex" justifyContent="flex-end">
          <Box sx={{ mr: 2 }}>
            <Button variant="contained" color="secondary" onClick={onClose}>
              {CANCEL}
            </Button>
          </Box>

          <Box>
            <Button variant="contained" color="primary" type="submit">
              {UPDATE}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditRoomTypeDialog;
