import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { SheetJSFT } from "lib/types";
const XLSX = require("xlsx");
interface PropsType {
  setData: (data: any) => void;
}

const ExcelReader = ({ setData }: PropsType) => {
  const handleChange = (e: any) => {
    const files = e.target.files;
    handleFile(files[0]);
  };

  const handleFile = (f: any) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: true,
      });
      /* Get first worksheet */

      var result: any = {};
      wb.SheetNames.forEach(function (sheetName: any) {
        var roa = XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
        if (roa.length > 0) {
          result[sheetName] = roa;
        }
      });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */


      setData(data);
    };

    if (rABS) {
      reader.readAsBinaryString(f);
    } else {
      reader.readAsArrayBuffer(f);
    }
  };

  return (
    <MDButton color={"info"} sx={{ position: "relative", cursor: "pointer" }}>
      <MDBox
        sx={{ cursor: "pointer" }}
        position={"absolute"}
        component={"input"}
        opacity={0}
        width="100%"
        height="100%"
        type="file"
        className="form-control"
        id="file"
        accept={SheetJSFT}
        onChange={handleChange}
      />
      upload file
      <FileUploadRoundedIcon />
    </MDButton>
  );
};

export default ExcelReader;
