import { Grid, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import { FlightTimeType, GetPassengersType, HotelTimeType } from "lib/types/api-type";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { useEffect, useMemo, useRef, useState } from "react";
import { TabType } from "../ArrivalAndDeparture";
import { prepareData } from "./utils";
import { usePrint } from "./usePrint";
import useSetting from "../Table/Settings/useSetting";

import MDButton from "components/MDButton";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import ReactToPrint from "react-to-print";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Settings from "../Table/Settings";
import { defaultStyle, ENDLINE_HEIGHT, TableStyle } from "../Table/row/constant";
import FlightRowV2 from "../Table/row/FlightRowV2";
import HotelRowV2 from "../Table/row/HotelRowV2";
import HeadRow from "../Table/row/HeadRow";
import { FlyPrintType } from "./types";
import HeaderRow from "../Table/row/HeaderRow";
import { SELECT_TOTAL_PASSENGERS } from "store/slices/operations";
import EndLine from "../Table/row/EndLine";

const SAVE_TO = "PRE_ORGANIZED";
const LOGO_SAVE_TO = "PRE_ORGANIZED_LOGO";

interface PropsType {
  flightTimes: FlightTimeType[];
  hotelTimes: HotelTimeType[];
  flies: GetPassengersType;
  date: string;
}

const PreOrganized = ({ flightTimes, flies, date }: PropsType) => {
  const [tabValue, setTabValue] = useState<TabType>("arrival");
  const [arrivals, setArrivals] = useState<FlyPrintType[]>();
  const [departures, setDepartures] = useState<FlyPrintType[]>();
  const [settings, setSettings] = useState<boolean>(false);

  const handleSetTabValue = (event: any, newValue: any) => {
    if (print.data) {
      print.reset();
    }
    setTabValue(newValue);
  };

  useEffect(() => {
    const result = prepareData(flies, flightTimes);
    setArrivals(result.arrivals);
    setDepartures(result.departures);
  }, [flies]);
  const { values, dispatchStyle, dispatch, heightRadio, logoSaveTo } = useSetting({
    s: defaultStyle,
    saveTo: SAVE_TO,
    logoSaveTo: LOGO_SAVE_TO,
  });

  const print = usePrint({
    styles: values.styles,
  });

  const componentRef = useRef(null);
  const tableStyle = useMemo(
    () => ({ ...TableStyle, ...values?.styles?.tableStyle }),
    [values.styles?.tableStyle, TableStyle]
  );

  const currentData = tabValue === "arrival" ? arrivals : departures;
  const totalPax = SELECT_TOTAL_PASSENGERS(tabValue);

  return (
    <MDBox>
      <Tabs
        value={tabValue}
        onChange={handleSetTabValue}
        sx={{
          maxWidth: "300px",
          margin: "auto",
          mt: 2,
          ".MuiTabs-indicator": { background: "#000000" },
          ".MuiButtonBase-root.Mui-selected .MuiBox-root": { color: "#fff" },
        }}
      >
        <Tab
          value={"arrival"}
          label={
            <MDBox
              minWidth={"150px"}
              justifyContent="center"
              py={0.5}
              px={2}
              color="inherit"
              display={"flex"}
              alignItems="center"
            >
              Arrival
              <FlightLandIcon style={{ margin: "0 10px" }} />
            </MDBox>
          }
        />
        <Tab
          value={"departure"}
          label={
            <MDBox
              minWidth={"150px"}
              justifyContent="center"
              py={0.5}
              px={2}
              color="inherit"
              display={"flex"}
              alignItems="center"
            >
              Departure
              <FlightTakeoffIcon style={{ margin: "0 10px" }} />
            </MDBox>
          }
        />
      </Tabs>
      <MDBox mt={2}>
        <MDBox display="flex" gap={1} px={4}>
          <MDButton variant="contained" onClick={() => setSettings((v) => !v)}>
            <SettingsRoundedIcon />
          </MDButton>
          <Grid>
            {settings ? (
              <Settings
                heightRadio={heightRadio}
                logoSaveTo={logoSaveTo}
                values={values}
                dispatchStyle={dispatchStyle}
                dispatch={dispatch}
              />
            ) : null}
          </Grid>
          <MDButton
            varinat={"text"}
            onClick={() => {
              print.data
                ? print.reset()
                : print.prepareTable(tabValue === "arrival" ? arrivals : departures);
            }}
            sx={{ ml: "auto" }}
          >
            {print.data ? "reset" : "prepare to print"}
          </MDButton>
          <ReactToPrint
            trigger={() => (
              <MDButton variant="contained">
                print
                <PrintRoundedIcon />
              </MDButton>
            )}
            content={() => componentRef.current}
          />
        </MDBox>
        <MDBox ref={componentRef} sx={{ mx: 1 }}>
          {print.data ? (
            print.data.map((page, pageIndex) => (
              <MDBox key={pageIndex} py={2}>
                <MDBox sx={tableStyle}>
                  <HeadRow
                    totalPax={pageIndex === 0 ? totalPax : undefined}
                    title={tabValue}
                    styles={values.styles}
                    logo={values.logo}
                    page={pageIndex + 1}
                    date={date}
                  />
                  <HeaderRow hotel flight flight-time pax info />

                  {page.map((row, rowIndex) => (
                    <MDBox key={rowIndex}>
                      {row.type === "fly" ? (
                        <FlightRowV2 flight={row} styles={values.styles} />
                      ) : row.type === "hotel" ? (
                        <HotelRowV2 hotel={row} styles={values.styles} />
                      ) : row.type === "endLine" ? (
                        <EndLine height={ENDLINE_HEIGHT} />
                      ) : null}
                    </MDBox>
                  ))}
                </MDBox>
              </MDBox>
            ))
          ) : (
            <>
              <HeaderRow hotel flight flight-time pax info />
              {currentData?.map((fly, index) => (
                <MDBox key={index}>
                  <FlightRowV2 flight={fly} styles={values.styles} />
                  {fly.hotels.map((hotel, hotelIndex) => (
                    <HotelRowV2 key={hotelIndex} hotel={hotel} styles={values.styles} />
                  ))}
                </MDBox>
              ))}
            </>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default PreOrganized;
