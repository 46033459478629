import * as yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";

import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";

import MDButton from "components/MDButton";

import { SELECT_USER } from "store/slices/profile";
import { AddContactType } from "lib/api/types";
import Post from "lib/api/post";
import Put from "lib/api/put";

interface PropsType {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contactAdded: (v: any) => void;
  hotel_id: number;
  contact_id?: number;
  contact_title?: string;
  contact_value?: string;
  contact_type?: "mobile" | "phone" | "mail" | "instagram" | "facebook" | "other";
}

const validationSchema = () =>
  yup.object().shape({
    title: yup.string().required("required"),
    value: yup.string().required("required"),
    contact_type: yup.string().required("required"),
  });

const AddContact = ({
  open,
  setOpen,
  contactAdded,
  hotel_id,
  contact_id,
  contact_title,
  contact_value,
  contact_type,
}: PropsType) => {
  const user = SELECT_USER();

  const [loading, setLoading] = useState<boolean>(false);
  const [open_contact_type, setOpen_contact_type] = useState(false);
  const [val, setVal] = useState<"mobile" | "phone" | "mail" | "instagram" | "facebook" | "other">(
    contact_type ? contact_type : "mail"
  );
  const [options] = useState<readonly string[]>([
    "mobile",
    "phone",
    "mail",
    "instagram",
    "facebook",
    "other",
  ]);

  const { handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue } =
    useFormik<AddContactType>({
      validationSchema,
      initialValues: {
        title: contact_title ? contact_title : "",
        value: contact_value ? contact_value : "",
        contact_type: contact_type ? contact_type : "mail",
        hotel_id: null,
      },
      onSubmit: (values) => {
        setLoading(true);
        const newValue: any = {
          ...values,
          contact_id,
          hotel_id,
          user_id: user.uid,
          session_id: user.session_id,
        };

        if (contact_id) {
          Put.updateContact(newValue)
            .then((res) => {
              setLoading(false);
              contactAdded(res.data);
              setOpen(false);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
          return;
        }

        Post.addContact(newValue)
          .then((res) => {
            setLoading(false);
            contactAdded(res.data);
            setOpen(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      },
    });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      value={values.title}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && errors.title ? true : false}
                      helperText={touched.title && errors.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Value"
                      value={values.value}
                      name="value"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.value && errors.value ? true : false}
                      helperText={touched.value && errors.value && errors.value}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      open={open_contact_type}
                      onOpen={() => {
                        setOpen_contact_type(true);
                      }}
                      onClose={() => {
                        setOpen_contact_type(false);
                      }}
                      isOptionEqualToValue={(option, value: any) => option === value}
                      getOptionLabel={(option) => option}
                      options={options}
                      loading={open_contact_type}
                      onChange={(e, v: string | any) => {
                        setFieldValue("contact_type", v);
                        setVal(v);
                      }}
                      defaultValue={val}
                      value={val}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Contact Type"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {open_contact_type ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          value={values.contact_type}
                          name="type_title"
                          error={touched.contact_type && errors.contact_type ? true : false}
                          helperText={
                            touched.contact_type && errors.contact_type && errors.contact_type
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <MDButton color={"dark"} type={"submit"}>
                      {(loading && <CircularProgress size="14px" color="primary" />) ||
                        "+ add new contact"}
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="outlined"
                      disabled={loading}
                      color={"error"}
                      onClick={() => setOpen(false)}
                    >
                      cancel
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddContact;
