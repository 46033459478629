import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { STATUS as STATUSENUMS } from "components/customs/ReservationFilter";
import { Box, FormControl, InputLabel, NativeSelect } from "@mui/material";
import { CLOSE, STATUS, UPDATE } from "./constants";
import { SELECT_USER } from "store/slices/profile";
import { updateStatus } from "lib/api/put";
import { UpdateStatusIProps } from "./interfaces";
import { v4 as uuidv4 } from "uuid";
const UpdateStatus = ({ setOpen, open, currentRow }: UpdateStatusIProps) => {
  var options = STATUSENUMS.slice(1, STATUSENUMS.length);

  const [inputValue, setInputValue] = useState(
    options.find((s) => s.value === currentRow?.status)?.value
  );
  const user = SELECT_USER();
  const handleClose = () => {
    setOpen(false);
    setInputValue(options.find((s) => s.value === currentRow?.status)?.value);
  };

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleUpdate = () => {
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      status: Number(inputValue),
      id: currentRow.id,
    };
    updateStatus(payload).then(() => {
      document.location.reload();
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box sx={{ minWidth: "23rem" }}>
          <DialogContentText id="alert-dialog-description">{currentRow?.voucher}</DialogContentText>
          <FormControl>
            <InputLabel htmlFor="demo-customized-select-native">{STATUS}</InputLabel>
            <NativeSelect
              id="demo-customized-select-native"
              value={inputValue}
              onChange={handleChange}
              defaultValue={options.find((s) => s.value === currentRow?.status)?.value}
              size="medium"
            >
              {options.map((item) => (
                <option key={uuidv4()} value={item?.value}>
                  {item?.title}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          {CLOSE}
        </Button>
        <Button onClick={handleUpdate} color="primary" autoFocus>
          {UPDATE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStatus;
