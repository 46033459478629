import { Grid, Icon, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import Flies from "../Flies";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Transfer from "../Transfer";
import AddHPToTransfer from "../../Forms/AddH&PToTransfer";
import AddTransfer from "components/Forms/AddTransfer";
import MDSnackbar from "components/MDSnackbar";
import { Props } from "./types";
import useCar, { createNewTransfer } from "./useCar";
const _ = require("lodash");

const CarsAndLeaders = (props: Props) => {
  const {
    date,
    cars,
    leaders,
    openMessage,
    tabValue,
    selectedPax,
    arrivalPassengers,
    departurePassengers,
    arrival,
    departure,
    isArrival,
    content,
    drivers,
    loading,
    openAdd,
    addHPToTransfer,
    changeDriverAndCar,
    updateTransfer,
    removeTransfer,
    setCurrentItem,
    setOpenAdd,
    setOpenMessage,
    handleSetTabValue,
    onSelect,
    save,
    addTransfer,
    removePax,
    removeFlyOrHotelFromTransfer,
  } = useCar(props);

  return (
    <>
      <MDSnackbar
        color="success"
        icon="notifications"
        title="your data has updated"
        content={"success"}
        dateTime=""
        open={openMessage}
        close={() => setOpenMessage(false)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Tabs
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{
              ".MuiTabs-indicator": { background: "#000000" },
              ".MuiButtonBase-root.Mui-selected .MuiBox-root": { color: "#fff" },
            }}
          >
            <Tab
              value={"departure"}
              label={
                <MDBox py={0.5} px={2} color="inherit" display={"flex"} alignItems="center">
                  departure
                  <FlightTakeoffIcon style={{ margin: "0 10px" }} />
                </MDBox>
              }
            />
            <Tab
              value={"arrival"}
              label={
                <MDBox py={0.5} px={2} color="inherit" display={"flex"} alignItems="center">
                  arrival
                  <FlightLandIcon style={{ margin: "0 10px" }} />
                </MDBox>
              }
            />
          </Tabs>
          <MDBox mt={2}>
            {tabValue ? (
              <>
                <Flies
                  side={tabValue}
                  selectedPax={selectedPax}
                  data={isArrival ? arrivalPassengers?.normal : departurePassengers?.normal}
                  date={date}
                  isAction={false}
                  title={
                    isArrival
                      ? { text: "normal transfer passengers", ...arrival?.normal }
                      : { text: "normal transfer passengers", ...departure?.normal }
                  }
                  onSelect={onSelect}
                  removeFlyOrHotelFromTransfer={removeFlyOrHotelFromTransfer}
                />
                <Flies
                  side={tabValue}
                  selectedPax={selectedPax}
                  data={isArrival ? arrivalPassengers?.vip : departurePassengers.vip}
                  date={date}
                  isAction={false}
                  title={
                    isArrival
                      ? { text: "vip transfer passengers", ...arrival?.vip }
                      : { text: "vip transfer passengers", ...departure?.vip }
                  }
                  onSelect={onSelect}
                  removeFlyOrHotelFromTransfer={removeFlyOrHotelFromTransfer}
                />
              </>
            ) : (
              <></>
            )}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <MDBox display="flex" justifyContent={"space-between"} mb={3}>
            <MDTypography>Transfers:</MDTypography>

            <AddTransfer
              onConfirm={({ driver, car }) => {
                const newTransfer = createNewTransfer(driver, car, tabValue);
                addTransfer(newTransfer);
              }}
              transfers={content?.items}
              drivers={drivers}
              cars={cars}
              button={
                <>
                  ADD
                  <Icon>add</Icon>
                </>
              }
            />

            <MDButton variant="contained" onClick={save}>
              {loading ? "wait..." : "save"}
              <Icon sx={{ ml: 1 }}>save</Icon>
            </MDButton>
          </MDBox>

          <MDBox display="flex" flexDirection="column">
            {content?.items?.map((item, index) => (
              <MDBox key={index}>
                {item.side === tabValue && (
                  <MDBox mb={2}>
                    <Transfer
                      content={content}
                      drivers={drivers}
                      cars={cars}
                      changeDriverAndCar={changeDriverAndCar}
                      itemIndex={index}
                      updateTransfer={updateTransfer}
                      removePax={removePax}
                      removeTransfer={removeTransfer}
                      leaders={leaders}
                      transfer={item}
                      driver={drivers?.find((d) => d.user_id === item.driver_id)}
                    />
                  </MDBox>
                )}
              </MDBox>
            ))}
          </MDBox>
        </Grid>
        {content && (
          <AddHPToTransfer
            drivers={drivers}
            type={tabValue}
            transfers={[...content.items]}
            open={openAdd}
            onClose={() => {
              setCurrentItem(null);
              setOpenAdd(false);
            }}
            onConfirm={addHPToTransfer}
          />
        )}
      </Grid>
    </>
  );
};

export default CarsAndLeaders;
