import React, { useState } from "react";
import { styled } from "@mui/material/styles";
// import {TableBody,Table,TableCell,TableContainer,TableHead,TableRow,Paper} from "@material-ui/core";

import {
  ARRIVAL_FLIGHT,
  DEPARTURE_FLIGHT,
  NO_TRANSFER,
  RESELLER_HOTEL,
  ROOMS,
  STATUS,
  TOTAL_NIGHTS,
  TOTAL_PAX,
  TRANSFER,
} from "./constants";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { STATUS as STATUSENUMS } from "components/customs/ReservationFilter";
import UpdateStatus from "./UpdateStatus";
import Reports from "./Reports";
import { ItemIProps, PassengersIProps, TabPanelContentIProps, RoomIProps } from "./interfaces";
import { DesktopWindows } from "@mui/icons-material";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { CHECK_INHOUSE_EXCEL, CHECK_IN_EXCEL } from "../board/constants";
import MDButton from "components/MDButton";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import { ReservationsEXCELBoardResponseType } from "lib/types/api-type";
import Get from "lib/api/get";
import { BASE_URL } from "lib/constants/urls";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    padding: "0 8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const TabPanelContent = ({
  items,
  financeData,
  roomData,
  pax,
  date,
  session_id,
  reseller_id,
  hotel_id,
}: TabPanelContentIProps) => {
  const navigate = useNavigate();
  const user = SELECT_USER();
  const [dataExcel, setDataExcel] = useState<ReservationsEXCELBoardResponseType>();
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const handleClickOpen = (item: ItemIProps) => {
    setOpen(true);
    setCurrentRow(item);
  };

  const iconHandleClick = (id: number) => {
    return navigate(`/reservation/${id}`);
  };

  return (
    <>
      {!items && (
        <Grid display="flex" justifyContent="center">
          <CircularProgress size="32px" color="primary" />
        </Grid>
      )}
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: "67.5vh" }}>
          <Table sx={{ minWidth: 700 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>(#)</StyledTableCell>
                <StyledTableCell>{STATUS}</StyledTableCell>
                <StyledTableCell>{RESELLER_HOTEL}</StyledTableCell>
                <StyledTableCell>{TRANSFER}</StyledTableCell>
                <StyledTableCell>{ROOMS}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item: ItemIProps) => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell component="th" scope="row">
                    <Box display="flex" onClick={() => iconHandleClick(item?.id)}>
                      <DesktopWindows
                        color="info"
                        sx={{ cursor: "pointer", mr: 1 }}
                        fontSize="small"
                      />
                      <Box>{item.voucher}</Box>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ cursor: "pointer" }} onClick={() => handleClickOpen(item)}>
                      <Typography color="primary" fontSize={14}>
                        {STATUSENUMS.find((s) => s.value === item.status)?.title}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    {item.reseller_company} - {item?.hotel_name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {!!item?.arr_flight || !!item?.depar_flight ? (
                      <Grid>
                        {!!item?.arr_flight && (
                          <Box>{`${ARRIVAL_FLIGHT}: ${item?.arr_flight}`}</Box>
                        )}
                        {!!item?.depar_flight && (
                          <Box>{`${DEPARTURE_FLIGHT}: ${item?.depar_flight}`}</Box>
                        )}
                      </Grid>
                    ) : (
                      NO_TRANSFER
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {item?.rooms?.map((room: RoomIProps) => (
                      <Card
                        sx={{
                          p: 1,
                          m: 1,
                          boxShadow: "none !important",
                          border: "1px solid rgba(224, 224, 224, 1)",
                        }}
                        key={uuidv4()}
                      >
                        <Grid display="flex">
                          <Typography fontSize={14} fontWeight={600}>
                            {room?.room_type} / {room?.room_subtype}
                          </Typography>
                          <Chip label={`${room?.nights} night(s)`} size="small" sx={{ ml: 1 }} />
                        </Grid>
                        <Typography fontSize={14} fontWeight={600}>
                          {moment(room?.check_in).format("ll")}
                          {` - `}
                          {moment(room?.check_out).format("ll")}
                        </Typography>
                        {room?.passengers?.map((item: PassengersIProps) => (
                          <Typography key={uuidv4()} fontSize={12}>
                            {item?.passenger_name}
                          </Typography>
                        ))}
                      </Card>
                    ))}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StyledTableCell>
                  <Box fontSize={14} fontWeight={500} display="flex">
                    {TOTAL_PAX} : {pax}
                  </Box>
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>
                  <Grid item display="flex">
                    <Grid display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#1A73E8",
                          color: "#fff",
                          borderRadius: "8px",
                          padding: "10px 24px",
                        }}
                        color="primary"
                      >
                        <a
                          href={`${BASE_URL}/reservations/checkinExcelReport?from=${date}&to=${date}&type=${"checkin"}&hotel_id=${-1}&reseller_id=${-1}&sid=${
                            user?.session_id
                          }&tk=${user?.accessToken}`}
                          target="_blank"
                          style={{ color: "#fff" }}
                        >
                          {CHECK_IN_EXCEL}
                        </a>
                      </Button>
                    </Grid>
                  </Grid>
                </StyledTableCell>

                <StyledTableCell>
                  <Box color="red" fontSize={14} fontWeight={500}>
                    {TOTAL_NIGHTS} : {roomData?.total_night}
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
      <Reports financeData={financeData} roomData={roomData} />
      <UpdateStatus setOpen={setOpen} open={open} currentRow={currentRow} />
    </>
  );
};

export default TabPanelContent;
