import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { GetPassengersType } from "lib/types/api-type";
import { useEffect, useState } from "react";
import { UserType } from "store/slices/profile";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import Flies from "../Flies";
import fliesDataHandler, { DataType } from "utils/flies-data-handler";
import { useDispatch } from "react-redux";
import { updateFlightTimes, updateHotelTimes } from "store/slices/operations";
interface PropsType {
  user: UserType;
  date: string;
  flies: GetPassengersType;
}

const Passengers = ({ user, date, flies }: PropsType) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<DataType>();

  useEffect(() => {
    return () => {
      dispatch(updateFlightTimes([]));
      dispatch(updateHotelTimes([]));
      setData(undefined);
    };
  }, []);

  useEffect(() => {
    if (flies) {
      setData(fliesDataHandler(flies));
    }
  }, [flies]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MDTypography color="info">
            departure
            <FlightTakeoffIcon style={{ margin: "0 10px" }} />
          </MDTypography>
          <Flies
            side={"departure"}
            date={date}
            data={flies?.departurePassengers?.normal}
            title={{ text: "normal transfer passengers", ...data?.departurePassengers?.normal }}
          />
          <Flies
            side={"departure"}
            date={date}
            data={flies?.departurePassengers?.vip}
            title={{ text: "vip transfer passengers", ...data?.departurePassengers?.vip }}
          />
          <Flies
            side={"departure"}
            date={date}
            data={flies?.departurePassengers?.no_transfer}
            title={{
              text: "no transfer transfer passengers",
              ...data?.departurePassengers?.no_transfer,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDTypography color="info">
            arrival
            <FlightLandIcon style={{ margin: "0 10px" }} />
          </MDTypography>
          <Flies
            side={"arrival"}
            date={date}
            data={flies?.arrivalPassengers?.normal}
            title={{ text: "normal transfer passengers", ...data?.arrivalPassengers?.normal }}
          />
          <Flies
            side={"arrival"}
            date={date}
            data={flies?.arrivalPassengers?.vip}
            title={{ text: "vip transfer passengers", ...data?.arrivalPassengers?.vip }}
          />
          <Flies
            side={"arrival"}
            date={date}
            data={flies?.arrivalPassengers?.no_transfer}
            title={{
              text: "no transfer transfer passengers",
              ...data?.arrivalPassengers?.no_transfer,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Passengers;
