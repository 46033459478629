export const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

export interface TableRowType {
  indra: string;
  id: string;
  arrival_time: string;
  arrival_info: string;
  departure_time: string;
  departure_info: string;
  hotel_id: string;
  age_type: string;
  phone: string;
  fullname: string;
}

export type PrepareTableType = (data: any[]) => TableRowType[];
//
//
//
export interface FlightType {
  key: string;
  id: any;
  title: string;
  side: "departure" | "arrival";
}

export interface HotelType {
  key: string;
  id: any;
  title: string;
}

export interface FType {
  flight_code: string;
  flight_destination: string;
  flight_id: number;
  flight_origin: string;
}
export interface HType {
  fullname: string;
  hotel_code: string;
  hotel_id: number;
}

export type FlightRecordsType = FType[][];
export type HotelRecordsType = HType[][];

export interface Row1Type extends HotelType {
  options?: HType[];
}

export type AutocompleteType = { label: string; id: string | number };
