import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  FormControl,
  TextField,
  Select,
  Typography,
  Divider,
  Grid,
  FormControlLabel,
  FormLabel,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import {
  CANCEL,
  BATCH_JOBS,
  APPLY,
  ARRIVAL,
  DEPARTURE,
  ARRIVAL_TRANSFER,
  DEPARTURE_TRANSFER,
  PASSENGERS,
  SELECT_TIME,
  VIP_TRANSFER,
  VIT_CONFIRM,
  CONFIRM,
} from "./const";
import { useFormik } from "formik";
import { MODAL_STYLE, SELECT_STYLE } from "../../style";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FlightFilter from "./FlightFilter";
import MDDatePicker from "components/MDDatePicker";
import moment from "moment";
import { Switch } from "@material-ui/core";
import { useFormContext } from "../../FormContextProvide";
import { useRequirmentContext } from "../../RequirmentContextProvider";

interface BatchJobsDialogType {
  open: boolean;
  onClose: any;
}

const BatchJobsDialog = ({ open, onClose }: BatchJobsDialogType) => {
  const [arrivalFilterValue, setArrivalFilterValue] = useState(null);
  const [departureFilterValue, setDepartureFilterValue] = useState(null);
  const [confirmVIPData, setConfirmVIPData] = useState({ isOpen: false, name: "" });

  const { formContext, setFormContext } = useFormContext();
  const { requirmentContext } = useRequirmentContext();

  const [passengers, setPassengers] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    arrival_time: null,
    departure_time: null,
    arrival_flight: null,
    arrival_flight_code: null,
    departure_flight_code: null,
    departure_flight: null,
    arrival_flight_origin: null,
    arrival_flight_destination: null,
    departure_flight_destination: null,
    departure_flight_origin: null,

    arrival_transfer_price: null,
    arrival_transfer_vip: null,

    departure_transfer_price: null,
    departure_transfer_vip: null,

    transferPriceCurrency: null,
    transferPriceCurrencyCode: null,

    arrival_transfer: null,
    departure_transfer: null,
  });

  useEffect(() => {
    const passengersList: any = [];
    formContext?.rooms?.forEach((room: any) =>
      room?.passengers.forEach((passenger: any) => {
        passengersList.push({ ...passenger, isSelect: true });
      })
    );
    setPassengers(passengersList);
  }, [formContext]);
  useEffect(() => {
    setSelectedItem(
      passengers.find(
        (item) =>
          item.isSelect === true && (item?.arrival_time !== "" || item?.departure_time !== "")
      )
    );
  }, [passengers]);
  useEffect(() => {
    setArrivalFilterValue({
      flight_id: selectedItem?.arrival_flight,
      flight_code: selectedItem?.arrival_flight_code,
      arrival_time: selectedItem?.arrival_time,
      flight_destination: selectedItem?.arrival_flight_destination,
      flight_origin: selectedItem?.arrival_flight_origin,
    });
    setDepartureFilterValue({
      flight_code: selectedItem?.departure_flight_code,
      flight_id: selectedItem?.departure_flight,
      departure_time: selectedItem?.departure_time,
      flight_destination: selectedItem?.departure_flight_destination,
      flight_origin: selectedItem?.departure_flight_origin,
    });
  }, [selectedItem]);
  const isSelectAll = passengers.every((item) => item.isSelect === true);

  const onSubmit = async (values: any) => {
    const newPassengers = passengers?.map((passenger) => {
      return passenger?.isSelect ? { ...passenger, ...values } : { ...passenger };
    });
    const newRooms = formContext.rooms;

    newPassengers.forEach((passenger) => {
      newRooms.forEach((room: any) => {
        room.passengers.forEach((roomPassenger: any) => {
          if (roomPassenger.id === passenger.id) {
            Object.assign(roomPassenger, passenger); // Set the roomPassenger to the passenger object
          }
        });
      });
    });

    await setFormContext({ ...formContext, rooms: newRooms });
    onClose?.();
  };

  const { handleSubmit, handleChange, handleBlur, values, setValues } = useFormik<any>({
    initialValues: {
      arrival_time: selectedItem?.arrival_time ? selectedItem?.arrival_time : "",
      departure_time: selectedItem?.departure_time ? selectedItem?.departure_time : "",
      arrival_transfer_price: selectedItem?.arrival_transfer_price
        ? selectedItem?.arrival_transfer_price
        : "",
      arrival_transfer_vip: selectedItem?.arrival_transfer_vip
        ? selectedItem?.arrival_transfer_vip
        : "",

      departure_transfer_price: selectedItem?.departure_transfer_price
        ? selectedItem?.departure_transfer_price
        : "",
      departure_transfer_vip: selectedItem?.departure_transfer_vip
        ? selectedItem?.departure_transfer_vip
        : "",

      transferPriceCurrency: `{"id":${
        selectedItem?.transferPriceCurrency ? selectedItem?.transferPriceCurrency : 3
      },"code":${
        selectedItem?.transferPriceCurrencyCode ? selectedItem?.transferPriceCurrencyCode : "USD"
      }}`,
      transferPriceCurrencyCode: selectedItem?.transferPriceCurrencyCode
        ? selectedItem?.transferPriceCurrencyCode
        : "",

      departure_transfer: selectedItem?.departure_transfer
        ? selectedItem?.departure_transfer
        : false,
      arrival_transfer: selectedItem?.arrival_transfer ? selectedItem?.arrival_transfer : false,
    },
    onSubmit: onSubmit,
    enableReinitialize: true,
  });
  const handleArrivalFilter = (filterValues: any) => {
    setValues({
      ...values,
      arrival_flight: filterValues?.flight_id,
      arrival_flight_code: filterValues?.flight_code,
      arrival_flight_origin: filterValues?.flight_origin,
      arrival_flight_destination: filterValues?.flight_destination,
    });
  };
  const handleDepartureFilter = (filterValues: any) => {
    setValues({
      ...values,
      departure_flight: filterValues?.flight_id,
      departure_flight_code: filterValues?.flight_code,
      departure_flight_origin: filterValues?.flight_origin,
      departure_flight_destination: filterValues?.flight_destination,
    });
  };

  const handleOpenVip = (name: string) => {
    setConfirmVIPData({ isOpen: true, name: name });
  };
  const handleConfirmVip = (name: string) => {
    if (name === "arrival_transfer_vip") {
      return setValues({ ...values, arrival_transfer_vip: !values.arrival_transfer_vip });
    }
    setValues({ ...values, departure_transfer_vip: !values.departure_transfer_vip });
  };

  const activeHandleChange = (item: any) => {
    const currentPassenger = passengers?.filter((passenger: any) => passenger?.id === item.id)?.[0];
    const filteredPassengers = passengers?.filter((passenger: any) => passenger?.id !== item.id);

    setPassengers([
      ...filteredPassengers,
      { ...currentPassenger, isSelect: !currentPassenger?.isSelect },
    ]);
  };

  const handleSelect = () => {
    const editedPassengers: any[] = [];
    passengers?.map((passenger: any) => {
      editedPassengers.push({ ...passenger, isSelect: isSelectAll ? false : true });
    });
    return setPassengers(editedPassengers);
  };

  // const orderedPassengers = passengers.sort((a: any, b: any) => {
  //   return a.order - b.order;
  // });
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={MODAL_STYLE} component={"form"} onSubmit={handleSubmit}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography>{BATCH_JOBS}</Typography>
            <Box>
              <Button variant="contained" color="primary" type="submit" endIcon={<CheckIcon />}>
                {APPLY}
              </Button>

              <IconButton aria-label="delete" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ borderWidth: "1px" }} />
          <Grid container>
            <Grid xs={12} p={1}>
              <Box display="flex">
                <FlightFilter
                  setFlightValue={setArrivalFilterValue}
                  flightValue={arrivalFilterValue}
                  title={ARRIVAL}
                  callbackFunction={handleArrivalFilter}
                />
                {arrivalFilterValue &&
                  arrivalFilterValue?.flight_origin &&
                  arrivalFilterValue?.flight_destination && (
                    <Box pt={1.2} ml={2}>
                      <Chip
                        label={arrivalFilterValue?.flight_origin}
                        color="secondary"
                        sx={{ mr: 1 }}
                        size="small"
                      />
                      <Chip
                        label={arrivalFilterValue?.flight_destination}
                        color="info"
                        size="small"
                      />
                    </Box>
                  )}
              </Box>
              {arrivalFilterValue && arrivalFilterValue?.flight_code && (
                <MDDatePicker
                  label={SELECT_TIME}
                  input={{ placeholder: SELECT_TIME, sx: { width: "100%" } }}
                  name="arrival_time"
                  onBlur={handleBlur}
                  options={{ mode: "single" }}
                  value={values?.arrival_time || ""}
                  onChange={(e: any) => {
                    e?.[0] &&
                      setValues({
                        ...values,
                        arrival_time: moment(e?.[0]).format("YYYY-MM-DD"),
                      });
                  }}
                />
              )}
            </Grid>
            <Grid xs={12} p={1}>
              <Box display="flex">
                <FlightFilter
                  setFlightValue={setDepartureFilterValue}
                  flightValue={departureFilterValue}
                  title={DEPARTURE}
                  callbackFunction={handleDepartureFilter}
                />
                {departureFilterValue &&
                  departureFilterValue?.flight_origin &&
                  departureFilterValue?.flight_destination && (
                    <Box pt={1.2} ml={2}>
                      <Chip
                        label={departureFilterValue?.flight_origin}
                        color="secondary"
                        sx={{ mr: 1 }}
                        size="small"
                      />
                      <Chip
                        label={departureFilterValue?.flight_destination}
                        color="info"
                        size="small"
                      />
                    </Box>
                  )}
              </Box>
              {departureFilterValue && departureFilterValue?.flight_code && (
                <MDDatePicker
                  label={SELECT_TIME}
                  input={{ placeholder: SELECT_TIME, sx: { width: "100%" } }}
                  name="departure_time"
                  onBlur={handleBlur}
                  options={{ mode: "single" }}
                  value={values?.departure_time || ""}
                  onChange={(e: any) => {
                    e?.[0] &&
                      setValues({
                        ...values,
                        departure_time: moment(e?.[0]).format("YYYY-MM-DD"),
                      });
                  }}
                />
              )}
            </Grid>

            <Grid xs={6} p={1}>
              <Box>
                <FormLabel>{ARRIVAL_TRANSFER}</FormLabel>
              </Box>

              <Box display="flex">
                <Box width={"50%"}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.arrival_transfer}
                        value={values.arrival_transfer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="arrival_transfer"
                        color="primary"
                      />
                    }
                    label={""}
                  />
                </Box>
                {values.arrival_transfer && (
                  <Box width={"50%"}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.arrival_transfer_vip}
                          name="arrival_transfer_vip"
                          color="primary"
                          onChange={() => handleOpenVip("arrival_transfer_vip")}
                        />
                      }
                      label={"VIP"}
                    />
                  </Box>
                )}
              </Box>

              {values.arrival_transfer && (
                <>
                  <FormControl fullWidth required sx={{ mb: 1 }}>
                    <TextField
                      required
                      type="number"
                      variant="outlined"
                      name="arrival_transfer_price"
                      value={values.arrival_transfer_price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>

                  <FormControl fullWidth required>
                    <Select
                      native
                      required
                      sx={SELECT_STYLE}
                      fullWidth
                      name="transferPriceCurrency"
                      value={values.transferPriceCurrency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {requirmentContext?.currencies?.map((item: any) => (
                        <option value={item.id}>{item.code}</option>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Grid>

            <Grid xs={6} p={1}>
              <Box>
                <FormLabel>{DEPARTURE_TRANSFER}</FormLabel>
              </Box>
              <Box display="flex">
                <Box width={"50%"}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.departure_transfer}
                        value={values.departure_transfer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="departure_transfer"
                        color="primary"
                      />
                    }
                    label=""
                  />
                </Box>
                {values.departure_transfer && (
                  <Box width={"50%"}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.departure_transfer_vip}
                          name="departure_transfer_vip"
                          color="primary"
                          onChange={() => handleOpenVip("departure_transfer_vip")}
                        />
                      }
                      label={"VIP"}
                    />
                  </Box>
                )}
              </Box>

              {values.departure_transfer && (
                <>
                  <FormControl fullWidth required sx={{ mb: 1 }}>
                    <TextField
                      required
                      type="number"
                      variant="outlined"
                      name="departure_transfer_price"
                      value={values.departure_transfer_price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>

                  <FormControl fullWidth required>
                    <Select
                      native
                      required
                      sx={SELECT_STYLE}
                      fullWidth
                      name="transferPriceCurrency"
                      value={values.transferPriceCurrency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {requirmentContext?.currencies?.map((item: any) => (
                        <option value={item.id}>{item.code}</option>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Grid>
            <Divider sx={{ borderWidth: "1px" }} />

            <Grid xs={12} p={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography>{PASSENGERS}</Typography>
                <Button
                  style={{ paddingRight: "1.8rem" }}
                  variant="contained"
                  color={isSelectAll ? "primary" : "inherit"}
                  endIcon={<CheckIcon />}
                  onClick={handleSelect}
                />
              </Box>

              {passengers?.map((item) => (
                <Box key={item?.id}>
                  <Divider sx={{ borderWidth: "1px" }} />
                  <Box display="flex" justifyContent="space-between">
                    <Typography>{item?.label}</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={item.isSelect}
                          name="departure_transfer_vip"
                          color="primary"
                          onChange={() => activeHandleChange(item)}
                        />
                      }
                      label=""
                    />
                  </Box>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Dialog
        open={confirmVIPData?.isOpen}
        onClose={() => setConfirmVIPData({ isOpen: false, name: "" })}
      >
        <DialogTitle>{VIP_TRANSFER}</DialogTitle>
        <DialogContent>
          <DialogContentText>{VIT_CONFIRM}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmVIPData({ isOpen: false, name: "" })}>{CANCEL}</Button>
          <Button
            onClick={() => {
              handleConfirmVip(confirmVIPData?.name);
              setConfirmVIPData({ isOpen: false, name: "" });
            }}
          >
            {CONFIRM}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BatchJobsDialog;

// const test = {
//   rooms: [
//     {
//       hotel_id: 1270,
//       hotel_title: " Actuel Life Hotel",
//       room_type1: 378,
//       room_type1_title: " Suite Room",
//       room_type1_code: "SUIT",
//       room_type1_capacity: 0,
//       room_type2: 388,
//       room_type2_title: "City View",
//       room_type2_code: "CTY WIE",
//       count: 2,
//       passengers: [
//         {
//           id: "6cfcb516-c5f6-45cf-a7d4-35a1f4924898",
//           user_id: 3903,
//           label: "  GHAHANI AMIRHOSSEIN",
//         },
//       ],
//       financial: [],
//       extras: [],
//       id: "6ccd5ca4-a9bb-46af-8a63-80b621c21032",
//       order: 1,
//     },
//     {
//       hotel_id: 1270,
//       hotel_title: " Actuel Life Hotel",
//       room_type1: 397,
//       room_type1_title: "2 Bedroom City View",
//       room_type1_code: "2 BDRM CTY VIW",
//       room_type1_capacity: 0,
//       room_type2: 400,
//       room_type2_title: "Apartment With Balcony And View",
//       room_type2_code: "APRTMN WHT BLCNY VIEW",
//       count: 2,
//       passengers: [
//         { id: "38b879fb-5c18-4a9e-aa11-eee595d018d7", user_id: 7871, label: " AHAD GHAIOUR" },
//       ],
//       financial: [],
//       extras: [],
//       id: "91750a05-1b22-41a6-ad3b-535c9a66ae70",
//       order: 2,
//     },
//   ],
//   extras: [],
// };
