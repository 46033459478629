import { Dialog, DialogContent, FormControl, Grid, InputLabel, MenuItem } from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CarType, DriverType, TransferType } from "lib/types/api-type";
interface PropsType {
  onConfirm: ({ driver, car }: { driver: DriverType; car: CarType }) => void;
  cars: CarType[];
  drivers: DriverType[];
  transfers: TransferType[];
  button?: React.ReactNode;
  transfer?: TransferType;
}

const AddTransfer = ({ drivers, cars, button, transfer, transfers, onConfirm }: PropsType) => {
  const [open, setOpen] = useState<boolean>(false);
  const [car, setCar] = useState<CarType>();
  const [driver, setDriver] = useState<DriverType>();

  const handleChangeCar = (event: SelectChangeEvent) => {
    setCar(cars?.find((c) => c.car_id.toString() === event.target.value));
  };

  const handleChangeDriver = (event: SelectChangeEvent) => {
    setDriver(drivers?.find((d) => d?.user_id.toString() === event.target.value));
  };

  useEffect(() => {
    if (!transfer) return;
    const { car_id, driver_id } = transfer;
    if (!car_id || !driver_id) return;

    setCar(cars?.find((c) => c.car_id.toString() === car_id));
    setDriver(drivers?.find((d) => d?.user_id.toString() === driver_id));
  }, [transfer]);

  return (
    <>
      <MDButton
        // variant="text"
        variant="outlined"
        color={"secondary"}
        onClick={() => setOpen(true)}
      >
        {button}
      </MDButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={"sm"}>
        <DialogContent>
          <Grid container spacing={2} pb={3}>
            <Grid xs={6} item>
              <FormControl fullWidth>
                <InputLabel>driver</InputLabel>
                <Select
                  sx={{
                    ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                      minHeight: "46px",
                    },
                  }}
                  value={driver?.user_id.toString() || ""}
                  label="driver"
                  onChange={handleChangeDriver}
                >
                  {drivers?.map((driver, index) => (
                    <MenuItem key={index} value={driver?.user_id.toString()}>
                      {driver.fullname} #{" "}
                      {transfers?.find((d) => d?.driver_id === driver?.user_id)?.index || 0}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6} item>
              <FormControl fullWidth>
                <InputLabel>Car</InputLabel>
                <Select
                  sx={{
                    ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                      minHeight: "46px",
                    },
                  }}
                  value={car?.car_id.toString() || ""}
                  label="car"
                  onChange={handleChangeCar}
                >
                  {cars?.map((car, index) => (
                    <MenuItem key={index} value={car?.car_id.toString()}>
                      {car.car_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <MDButton
            fullWidth
            color="primary"
            onClick={() => {
              if (driver && car) onConfirm({ driver, car });
              setOpen(false);
            }}
          >
            confirm
          </MDButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddTransfer;
