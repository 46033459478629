import { Grid, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import {
  CarType,
  ContentType,
  DriverType,
  GetPassengersType,
  TransferType,
} from "lib/types/api-type";
import { useEffect, useState } from "react";
import { UserType } from "store/slices/profile";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import ArrivalAndDeparture, { TabType } from "./ArrivalAndDeparture";
import Overal from "./Overal";
import { convertToHours } from "./Table/row";
import { SELECT_FLIGHT_TIMES, SELECT_HOTEL_TIMES } from "store/slices/operations";
import PreOrganized from "./PreOrganized";

const _ = require("lodash");

export type PickTimeType = (
  type: "flight" | "hotel",
  flight_id: number,
  hotel_id?: number
) => string;

interface PropsType {
  user: UserType;
  date: string;
  flies: GetPassengersType;
  cars: CarType[];
  drivers: DriverType[];
  leaders: DriverType[];
  data: ContentType;
}

const Overview = ({ data, date, user, flies }: PropsType) => {
  const [tabValue, setTabValue] = useState<TabType | "overal" | "preOrganized">("preOrganized");
  const [arrivals, setArrivals] = useState<TransferType[]>([]);
  const [departures, setDepartures] = useState<TransferType[]>([]);
  const flightTimes = SELECT_FLIGHT_TIMES();
  const hotelTimes = SELECT_HOTEL_TIMES();

  //
  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
  };
  //

  useEffect(() => {
    if (!data) return;

    const a: TransferType[] = [];
    const d: TransferType[] = [];
    data?.items?.forEach((transfer) => {
      if (transfer.side === "departure") {
        d.push(transfer);
      } else if (transfer.side === "arrival") {
        a.push(transfer);
      }
    });

    setArrivals(a);
    setDepartures(d);
  }, [data]);

  const pickTime: PickTimeType = (type, flight_id, hotel_id) => {
    let time;
    if (type === "flight") {
      time = flightTimes?.find((f) => f.flight_id == flight_id)?.flight_time;
    } else {
      time = hotelTimes?.find(
        (h) => h.flight_id == flight_id && h.hotel_id == hotel_id
      )?.flight_time;
    }
    if (time) {
      return convertToHours(time);
    } else return "??";
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} justifyContent="center" container>
          <Tabs
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{
              ".MuiTabs-indicator": { background: "#000000" },
              ".MuiButtonBase-root.Mui-selected .MuiBox-root": { color: "#fff" },
            }}
          >
            <Tab
              value={"preOrganized"}
              label={
                <MDBox
                  minWidth={"150px"}
                  justifyContent="center"
                  py={0.5}
                  px={2}
                  color="inherit"
                  display={"flex"}
                  alignItems="center"
                >
                  Pre Organized
                  <AnalyticsRoundedIcon style={{ margin: "0 10px" }} />
                </MDBox>
              }
            />
            <Tab
              value={"arrival"}
              label={
                <MDBox
                  minWidth={"150px"}
                  justifyContent="center"
                  py={0.5}
                  px={2}
                  color="inherit"
                  display={"flex"}
                  alignItems="center"
                >
                  Arrival
                  <FlightLandIcon style={{ margin: "0 10px" }} />
                </MDBox>
              }
            />
            <Tab
              value={"departure"}
              label={
                <MDBox
                  minWidth={"150px"}
                  justifyContent="center"
                  py={0.5}
                  px={2}
                  color="inherit"
                  display={"flex"}
                  alignItems="center"
                >
                  Departure
                  <FlightTakeoffIcon style={{ margin: "0 10px" }} />
                </MDBox>
              }
            />
            <Tab
              value={"overal"}
              label={
                <MDBox
                  minWidth={"150px"}
                  justifyContent="center"
                  py={0.5}
                  px={2}
                  color="inherit"
                  display={"flex"}
                  alignItems="center"
                >
                  Overal
                  <SummarizeRoundedIcon style={{ margin: "0 10px" }} />
                </MDBox>
              }
            />
          </Tabs>
        </Grid>
      </Grid>
      {/* actions */}
      {/* tabs */}
      <MDBox width={"100%"}>
        {tabValue === "overal" ? (
          <Overal transfers={[...arrivals, ...departures]} date={date} pickTime={pickTime} />
        ) : tabValue === "arrival" || tabValue === "departure" ? (
          <ArrivalAndDeparture
            date={date}
            tab={tabValue}
            arrivals={arrivals}
            departures={departures}
            pickTime={pickTime}
          />
        ) : (
          <></>
        )}
      </MDBox>
      <MDBox width={"100%"}>
        {tabValue === "preOrganized" && (
          <PreOrganized
            flies={flies}
            date={date}
            flightTimes={flightTimes}
            hotelTimes={hotelTimes}
          />
        )}
      </MDBox>
    </>
  );
};

export default Overview;
