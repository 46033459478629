/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, JSXElementConstructor, Key, ReactElement } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React TS routes
import routes from "routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import MDSnackbar from "components/MDSnackbar";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { Axios, setupAxios } from "utils/axios";
import { AxiosError } from "axios";
import Cover from "layouts/authentication/sign-in/cover";
import { SELECT_USER, updateUser, UserType } from "store/slices/profile";
import { useDispatch } from "react-redux";

const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const dispatchStore = useDispatch();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const user = SELECT_USER();
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [message, setMessage] = useState<any>("");
  const [openErrorMessage, setOpenErrorMessage] = useState<boolean>(false);
  const [isCall, setIsCall] = useState(true);
  const { pathname } = useLocation();
  // axios

  if (isCall) {
    const d = localStorage.user;
    if (d) {
      let user: UserType = JSON.parse(d);
      dispatchStore(updateUser(user));
      setIsCall(false);
      setupAxios({
        axiosInstance: Axios,
        token: user?.accessToken || "",
        errorHandler: (error: AxiosError) => {
          const data: any = error.response?.data;
          if (data?.message) {
            setMessage(data.message);
          } else {
            setMessage("something is wrong!");
          }
          setOpenErrorMessage(true);
        },
      });
    } else {
      setupAxios({
        axiosInstance: Axios,
        token: "",
        errorHandler: (error: AxiosError) => {
          const data: any = error.response?.data;
          if (data?.message) {
            setMessage(data.message);
          } else {
            setMessage("something is wrong!");
          }
          setOpenErrorMessage(true);
        },
      });
    }
  }

  useEffect(() => {}, [localStorage.token]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Material Dashboard PRO"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator />
        {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <MDSnackbar
        color="error"
        icon="notifications"
        title="error from server"
        content={message}
        dateTime=""
        open={openErrorMessage}
        close={() => setOpenErrorMessage(false)}
      />
      <Routes>
        <>
          {user?.accessToken ? (
            <>
              {getRoutes(routes)}
              {/* <Route path="*" element={<Navigate to="/dashboard/management" />} /> */}
            </>
          ) : (
            <>
              <Route path={"/login"} element={<Cover />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
