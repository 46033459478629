import { InputAdornment, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { memo, useEffect, useRef, useState } from "react";
import MDTypography from "components/MDTypography";
import { ClearRounded } from "@mui/icons-material";
import { justEnglish } from "utils/just-language";

interface PropsType {
  onChange: (value: any) => void;
  value: any;
  defaultValue: any;
  justEnglish: boolean;
}

const TextFieldColumn = ({
  onChange,
  defaultValue,
  justEnglish: englishOnly,
  value,
}: PropsType) => {
  const [v, setV] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const clickHandler = () => {
    setEdit(true);
  };

  useEffect(() => {
    setV(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (edit) {
      inputRef.current.focus();
    }
  }, [edit]);
  const valid = justEnglish(v);
  const hasChanged = defaultValue !== value;
  return (
    <MDBox display={"flex"} alignItems="center" tabIndex={-1}>
      <MDBox>
        {(hasChanged || edit === true) && (
          <MDTypography width={"100%"} fontSize={12}>
            {value}
          </MDTypography>
        )}
        {edit ? (
          <TextField
            inputRef={inputRef}
            fullWidth
            value={v}
            onChange={(e) => setV(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CheckRoundedIcon
                    sx={{ cursor: "pointer", color: "green" }}
                    onClick={() => {
                      onChange(v);
                      setEdit(false);
                    }}
                  />
                  <ClearRounded
                    sx={{ cursor: "pointer", marginLeft: 1, color: "red" }}
                    onClick={() => {
                      setV(defaultValue);
                      setEdit(false);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <MDBox
            sx={{ cursor: "pointer" }}
            onClick={clickHandler}
            display={"flex"}
            alignItems="center"
          >
            {englishOnly && !valid ? (
              <ClearRounded
                sx={{ cursor: "pointer", marginLeft: 1, color: "red" }}
                onClick={() => {
                  setEdit(true);
                }}
              />
            ) : null}
            <MDTypography
              width={"100%"}
              fontSize={12}
              sx={{ color: valid && hasChanged ? "green" : "" }}
            >
              {defaultValue}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

export default memo(TextFieldColumn);
