import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TabType } from "components/customs/Overview/ArrivalAndDeparture";
import { FlightTimeType, FlyPassengerType, HotelTimeType, PassengerType } from "lib/types/api-type";
import { AppState } from "..";
import { useAppSelector } from "../hooks";
const _ = require("lodash");

export interface DescriptionType {
  id: number;
  voucher_id: string;
  date: string;
  description: string;
  desc_user_id: number;
}

export interface PassengersType {
  arrivalPassengers: FlyPassengerType;
  departurePassengers: FlyPassengerType;
}

export interface ArrivalLeader {
  id: number;
  leader_id: number;
  leader_name: number;
  user_id: number;
  voucher: string;
}
export interface OperationsStateTypes {
  flightTimes: FlightTimeType[];
  hotelTimes: HotelTimeType[];
  descriptions: DescriptionType[];
  vouchers: VoucherType[];
  passengers: PassengersType;
  arrivalLeaders: ArrivalLeader[];
}

export interface VoucherType {
  check_in: string;
  check_out: string;
  type_code: string;
  voucher: string;
}

const initialState: OperationsStateTypes = {
  flightTimes: [],
  hotelTimes: [],
  descriptions: [],
  vouchers: [],
  passengers: {
    arrivalPassengers: {
      vip: [],
      no_transfer: [],
      normal: [],
    },
    departurePassengers: {
      vip: [],
      no_transfer: [],
      normal: [],
    },
  },
  arrivalLeaders: [],
};

export const operations = createSlice({
  name: "operations",
  initialState,
  reducers: {
    updateFlightTimes: (state: OperationsStateTypes, action: PayloadAction<FlightTimeType[]>) => {
      state.flightTimes = action.payload;
    },
    updateFlightTime: (
      state: OperationsStateTypes,
      action: PayloadAction<{ flight_id: number; flight_time: string }>
    ) => {
      const newFlightTimes = [...state.flightTimes];

      const index = newFlightTimes?.findIndex((f) => f.flight_id == action.payload.flight_id);
      if (index === -1) {
        newFlightTimes.push({
          flight_id: action.payload.flight_id,
          flight_time: action.payload.flight_time,
          id: -1,
        });
      } else {
        newFlightTimes[index] = { ...newFlightTimes[index], ...action.payload };
      }
      state.flightTimes = [...newFlightTimes];
    },
    updateVouchers: (state: OperationsStateTypes, action: PayloadAction<VoucherType[]>) => {
      state.vouchers = action.payload;
    },
    updateHotelTimes: (state: OperationsStateTypes, action: PayloadAction<HotelTimeType[]>) => {
      state.hotelTimes = action.payload;
    },
    updateHotelTime: (state: OperationsStateTypes, action: PayloadAction<HotelTimeType>) => {
      const newHotelTimes = [...state.hotelTimes];
      const newHotelTime = action.payload;
      const index = newHotelTimes?.findIndex(
        (f) => f.hotel_id == newHotelTime.hotel_id && f.flight_id == newHotelTime.flight_id
      );
      if (index === -1) {
        newHotelTimes.push(newHotelTime);
      } else {
        newHotelTimes[index] = newHotelTime;
      }
      state.hotelTimes = newHotelTimes;
    },
    updateDescriptions: (state: OperationsStateTypes, action: PayloadAction<DescriptionType[]>) => {
      state.descriptions = action.payload;
    },
    updateDescription: (state: OperationsStateTypes, action: PayloadAction<DescriptionType>) => {
      const description = action.payload;
      const descriptions = [...state.descriptions];
      const index = descriptions?.findIndex(
        (d) =>
          d.voucher_id === description.voucher_id && d.desc_user_id === description.desc_user_id
      );
      if (index === -1) {
        descriptions.push(description);
      } else {
        descriptions[index] = { ...description };
      }
      state.descriptions = [...descriptions];
    },
    updateFlies: (state: OperationsStateTypes, action: PayloadAction<PassengersType>) => {
      state.passengers = action.payload;
    },
    updateArrivalLeaders: (state: OperationsStateTypes, action: PayloadAction<ArrivalLeader[]>) => {
      state.arrivalLeaders = action.payload || [];
    },
  },
});

//
export const {
  updateFlightTimes,
  updateFlightTime,
  updateHotelTimes,
  updateHotelTime,
  updateDescriptions,
  updateDescription,
  updateVouchers,
  updateFlies,
  updateArrivalLeaders,
} = operations.actions;
//
//
export const SELECT_FLIGHT_TIMES = () =>
  useAppSelector((state: AppState) => state.operations.flightTimes);
export const SELECT_FLIGHT_TIME = (flight_id: number) =>
  useAppSelector((state: AppState) =>
    state.operations.flightTimes?.find((f) => f.flight_id == flight_id)
  );
export const SELECT_HOTEL_TIMES = () =>
  useAppSelector((state: AppState) => state.operations.hotelTimes);
export const SELECT_HOTEL_TIME = (hotel_id: number, flight_id: number) =>
  useAppSelector((state: AppState) =>
    state.operations.hotelTimes?.find((h) => h.hotel_id == hotel_id && h.flight_id == flight_id)
  );
export const SELECT_DESCRIPTIONS = () =>
  useAppSelector((state: AppState) => state.operations.descriptions);

export const SELECT_DESCRIPTION = (passenger: PassengerType) =>
  useAppSelector((state: AppState) =>
    state.operations.descriptions?.find(
      (d) => d.desc_user_id === passenger?.user_id && d.voucher_id === passenger?.voucher
    )
  );

export const SELECT_VOUCHER = (voucher_id: string) =>
  useAppSelector((state: AppState) => {
    let voucher: VoucherType;
    state.operations.vouchers.forEach((v) => {
      if (voucher_id === v.voucher) {
        voucher = {
          ...v,
          type_code: voucher?.type_code ? `${voucher?.type_code}/${v.type_code}` : v.type_code,
        };
      }
    });
    return voucher;
  });

export const IS_VIP_PASSENGER = (passenger: PassengerType) =>
  useAppSelector((state: AppState) => {
    const { departurePassengers, arrivalPassengers } = state.operations.passengers;
    const vips = [...departurePassengers?.vip, ...arrivalPassengers.vip];
    let findPassenger: PassengerType;
    for (const vip of vips) {
      for (const hotel of vip.hotels) {
        findPassenger = hotel.passengers.find((p) => p.id === passenger.id);
        if (findPassenger) break;
      }
      if (findPassenger) break;
    }
    return Boolean(findPassenger);
  });

export const SELECT_TOTAL_PASSENGERS = (tab: TabType) =>
  useAppSelector((state: AppState) => {
    const { departurePassengers, arrivalPassengers } = state.operations.passengers;
    let fs = [];
    if (tab === "arrival") {
      fs = [
        // ...arrivalPassengers.no_transfer,
        ...arrivalPassengers.normal,
        ...arrivalPassengers.vip,
      ];
    } else {
      fs = [
        // ...departurePassengers.no_transfer,
        ...departurePassengers.normal,
        ...departurePassengers.vip,
      ];
    }
    let sum = 0;

    fs.forEach((f) => {
      // sum = sum + f.active_pax;
      f.hotels.forEach((h) => {
        if (Array.isArray(h.passengers)) {
          sum += h.passengers.length;
        } else {
          sum++;
        }
      });
    });
    return sum;
  });

export const SELECT_ARRIVAL_LEADER = (passenger: PassengerType) =>
  useAppSelector((state: AppState) => {
    const { arrivalLeaders } = state.operations;
    const find = arrivalLeaders.find(
      (l) => l.user_id === passenger.user_id && l.voucher === passenger.voucher
    );
    return find;
  });

export default operations.reducer;
