import { useEffect, useState } from "react";
import RoomCard from "./components/RoomCard";
import { Button, Grid, Stack } from "@mui/material";
import { BATCH_JOBS } from "./components/const";
import BatchJobsDialog from "./components/BatchJobsDialog";
import { useFormContext } from "../FormContextProvide";
import { NEXT, PREVIOUS } from "../const";
import MDSnackbar from "components/MDSnackbar";
import { isEmpty } from "lodash";
import { useAddPassengerContext } from "../AddPassengerContextProvider";
import Post from "lib/api/post";
import { initialPassenger } from "../HotelTab/components/helper";
import { useRequirmentContext } from "../RequirmentContextProvider";
import { SELECT_USER } from "store/slices/profile";

type Props = {
  next: () => void;
  back: () => void;
  activeStep: number;
};

const PassengersTabContent = ({ next, back, activeStep }: Props) => {
  //hooks
  const { formContext, setFormContext } = useFormContext();
  const { requirmentContext } = useRequirmentContext();
  const user = SELECT_USER();
  const {
    addPassengerContext,
    setAddPassengerContext,
    setDataAddPassengerContext,
    dataAddPassengerContext,
  } = useAddPassengerContext();

  //states
  const [errors, setErrors] = useState<string[]>([]);
  const [isBatchJobOpen, setIsBatchJobOpen] = useState(false);
  const [openErrorMessage, setOpenErrorMessage] = useState<boolean>(false);
  const setInContext = (
    name: string,
    value: any,
    roomIndex: any,
    passengerIndex: any,
    initialPassenger: any
  ) => {
    const newRooms = formContext.rooms;
    const newPassengers = newRooms[roomIndex].passengers;
    if (name === "departureFilterValue")
      newPassengers[passengerIndex] = {
        ...initialPassenger,
        ...newPassengers[passengerIndex],
        departure_flight: value.flight_id,
        departure_flight_code: value.flight_code,
        departure_flight_origin: value.flight_origin,
        departure_flight_destination: value.flight_destination,
      };
    else if (name === "arrivalFilterValue")
      newPassengers[passengerIndex] = {
        ...initialPassenger,
        ...newPassengers[passengerIndex],
        arrival_flight: value.flight_id,
        arrival_flight_code: value.flight_code,
        arrival_flight_origin: value.flight_origin,
        arrival_flight_destination: value.flight_destination,
      };
    else if (name === "transferPriceCurrency") {
      const temp = requirmentContext?.currencies.filter(
        (item: { id: number; code: string }) => item.id === Number(value)
      ) as { id: number; code: string }[];

      newPassengers[passengerIndex] = {
        ...initialPassenger,
        ...newPassengers[passengerIndex],
        transferPriceCurrency: value,
        transferPriceCurrencyCode: temp[0]?.code,
      };
    } else {
      newPassengers[passengerIndex] = {
        ...initialPassenger,
        ...newPassengers[passengerIndex],
        [name]: value,
      };
    }
    setFormContext({ ...formContext });
  };
  const AddUsers = async (value: any) => {
    const payload = {
      company: "",
      contacts: "[]",
      fullname: value?.name,
      meta_tags: '{"birthday":null,"gender":"male","city":"","telegram":null}',
      user_id: value?.user_id,
      session_id: value?.session_id,
      user_type: "tourist",
    };
    await Post.usersAdd(payload)
      .then((res: any) => {
        setDataAddPassengerContext((prevData: any) => ({
          ...prevData,
          userList: [
            ...prevData.userList,
            {
              label: res.data.user?.fullname,
              user_id: res.data.user?.user_id,
            },
          ], // Add the new user to the list
        }));
        formContext?.rooms?.map((room: any, roomIndex: number) =>
          formContext?.rooms[roomIndex]?.passengers?.map((item: any, passengerIndex: number) => {
            if (item?.selectedUser?.label === res?.data?.user?.fullname) {
              // setDataAddPassengerContext({ initialPassenger, roomIndex, passengerIndex });
              setInContext(
                "selectedUser",
                {
                  label: res?.data?.user?.fullname,
                  user_id: res?.data?.user?.user_id,
                },
                roomIndex,
                passengerIndex,
                initialPassenger
              );
              setInContext(
                "label",
                res?.data?.user?.fullname,
                roomIndex,
                passengerIndex,
                initialPassenger
              );
              setInContext(
                "user_id",
                res?.data?.user?.user_id,
                roomIndex,
                passengerIndex,
                initialPassenger
              );
              setAddPassengerContext([]);
            }
          })
        );
      })
      .catch((err) => {});
  };
  useEffect(() => {
    let listUSers: any = [];
    formContext?.rooms?.forEach((passenger: any) => {
      passenger?.passengers?.forEach((allpassenger: any) => {
        allpassenger?.label &&
          allpassenger?.user_id &&
          listUSers.push({
            name: allpassenger?.label,
            user_id: allpassenger?.user_id,
            session_id: user?.session_id,
          });
      });
    });
    setAddPassengerContext(listUSers);
  }, [formContext]);
  return (
    <Grid container spacing={3}>
      <Grid
        xs={12}
        item
        style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem" }}
      >
        <Button variant="contained" color="primary" style={{ backgroundColor: "#1A73E8", color:"#fff", borderRadius: "8px", padding:"10px 24px" }} onClick={() => setIsBatchJobOpen(true)}>
          {BATCH_JOBS}
        </Button>
        <BatchJobsDialog open={isBatchJobOpen} onClose={() => setIsBatchJobOpen(false)} />
      </Grid>
      <Grid xs={12} item>
        {formContext?.rooms?.map((room: any, index: number) => (
          <RoomCard setInContext={setInContext} roomIndex={index} setErrors={setErrors} />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" py={2} justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={back}
            sx={{ mr: 1, backgroundColor: "#1A73E8", color:"#fff", borderRadius: "5px" }}
            style={{ backgroundColor: "#1A73E8", color:"#fff", borderRadius: "8px", padding:"10px 24px" }}
          >
            {PREVIOUS}
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ backgroundColor: "#1A73E8", color:"#fff", borderRadius: "5px" }}
            style={{ backgroundColor: "#1A73E8", color:"#fff", borderRadius: "8px", padding:"10px 24px" }}
            onClick={() => {
              if (isEmpty(errors)) {
                addPassengerContext?.length !== 0 &&
                  addPassengerContext?.forEach((addData: any) => {
                    if (addData?.name) {
                      const checkBeforeUser = dataAddPassengerContext?.userList.find(
                        (item: any) =>
                          item.user_id === addData?.user_id && item.label === addData?.name
                      );
                      if (!checkBeforeUser) {
                        AddUsers({
                          name: addData?.name,
                          user_id: addData?.user_id,
                          session_id: addData?.session_id,
                        });
                      }
                    }
                  });
                next?.();
              } else {
                setOpenErrorMessage(true);
              }
            }}
          >
            {NEXT}
          </Button>
        </Stack>
      </Grid>
      {openErrorMessage &&
        errors.map((error: string) => (
          <MDSnackbar
            color="error"
            icon="notifications"
            title="error from server"
            content={error}
            dateTime=""
            open={true}
            close={() => setOpenErrorMessage(false)}
          />
        ))}
    </Grid>
  );
};

export default PassengersTabContent;
