import styled from "@emotion/styled";
import { Dialog, DialogContent, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import TimePicker from "react-time-picker";
const defaultValue = "00:00";
interface PropsType {
  open: boolean;
  loading: boolean;
  value: string;
  onClose: (value: boolean) => void;
  confirm: (time: string) => void;
}

const GetClock = ({ value, open, onClose, loading, confirm }: PropsType) => {
  const [time, setTime] = useState<any>(defaultValue);
  useEffect(() => {
    setTime(value);
  }, [value]);
  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth maxWidth={"sm"}>
      <DialogContent>
        <MDBox mb={2} sx={{ height: "300px" }}>
          <TimeStyled>
            <TimePicker
              autoFocus
              className={"time-picker"}
              value={time}
              amPmAriaLabel="false"
              locale="sv-sv"
              onChange={setTime}
            />
          </TimeStyled>
        </MDBox>
        <MDButton fullWidth color="primary" onClick={() => !loading && confirm(time)}>
          confirm
        </MDButton>
      </DialogContent>
    </Dialog>
  );
};

export default GetClock;

const TimeStyled = styled.div`
  .time-picker > div {
    border-radius: 4px !important;
    padding: 5px;
  }
  input,
  .react-time-picker__inputGroup__leadingZero {
    padding: 0 6px;
    :focus {
      outline: none;
    }
  }
  .react-time-picker__clock {
    border-radius: 8px;
    margin-top: 10px;
  }
`;
