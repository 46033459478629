import { useCallback, useEffect, useMemo, useState } from "react";

// @mui material components
import { Grid, Icon, Card, Link, MenuItem, Menu } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import AddHotel from "components/Forms/AddHotel";
import RemoveItem from "./RemoveItem";
import EditHotel from "components/Forms/EditHotel";

interface OptionType {
  hotel_id: number;
  hotel_code: string;
  fullname: string;
  type_code: string;
  type_title: string;
  state_name: string;
  district_name: string;

  active: number;
  order: number;
  title: string;
  type_id: number;
  type_level: number;
}

function Hotels(): JSX.Element {
  const user = SELECT_USER();

  const [hotels, setHotels] = useState<readonly OptionType[]>([]);
  const [hotelId, setHotelId] = useState<number>();
  const [openAddHotel, setOpenAddHotel] = useState(false);
  const [openRemoveHotel, setOpenRemoveHotel] = useState(false);

  const [_searchedValue, _setSearchedValue] = useState<string>("");
  const [_openMenu, _setOpenMenu] = useState<any>(false);
  const [_updatePage, _setUpdatePage] = useState<number>(0);

  useEffect(() => {
    if (_searchedValue === "") return;
    Post.searchHotel(user.session_id, user.uid, _searchedValue).then((res) => {
      setHotels(res.data.items);
    });
  }, [_searchedValue, user.session_id, user.uid]);

  const handleCloseMenu = useCallback(() => _setOpenMenu(false), []);

  const handleOpenAddHotel = useCallback(() => {
    handleCloseMenu();
    setOpenAddHotel(true);
  }, [handleCloseMenu]);

  const handleOpenRemoveHotel = useCallback(() => {
    handleCloseMenu();
    setOpenRemoveHotel(true);
  }, []);

  const handleOpenMenu = useCallback((event: any) => {
    setHotelId(event.currentTarget.id);
    _setOpenMenu(event.currentTarget);
  }, []);

  const renderMenu = useMemo(() => {
    return (
      <Menu
        anchorEl={_openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(_openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
      >
        <MenuItem onClick={handleOpenAddHotel}>
          <Icon sx={{ mr: 1 }}>edit</Icon>Modify
          {/* <AddHotel hotelAdded={(v: any): void => {}} /> */}
        </MenuItem>
        <MenuItem onClick={handleOpenRemoveHotel}>
          <Icon sx={{ mr: 1 }}>delete</Icon>Remove
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          <Link href={`/hotels/${hotelId}`} onClick={handleCloseMenu}>
            <Icon sx={{ mr: 1 }}>hotel</Icon>Hotel Profile
          </Link>
        </MenuItem>
      </Menu>
    );
  }, [_openMenu, hotelId, handleOpenAddHotel, handleOpenRemoveHotel, handleCloseMenu]);

  const renderTable = useMemo(() => {
    return (
      <DataTable
        isSorted={false}
        table={{
          columns: [
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  Name
                </MDTypography>
              ),
              accessor: "name",
            },
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  Type
                </MDTypography>
              ),
              accessor: "type",
            },
            {
              Header: (
                <MDTypography variant="h6" fontWeight="bold">
                  Zone
                </MDTypography>
              ),
              accessor: "zone",
            },
            {
              Header: <Icon fontSize="medium">view_list</Icon>,
              accessor: "actions",
              width: "12%",
            },
          ],
          rows: hotels.map((hotel, i) => {
            const hotelObj = {
              name: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {hotel.hotel_code}
                  </MDTypography>
                  <MDTypography variant="Inherit">{hotel.fullname}</MDTypography>
                </>
              ),
              type: (
                <>
                  <MDTypography variant="inherit" color="text" fontWeight="bold">
                    {hotel.type_code}
                  </MDTypography>
                  <MDTypography variant="Inherit">{hotel.type_title}</MDTypography>
                </>
              ),
              zone: (
                <>
                  <MDTypography variant="Inherit">
                    {hotel.state_name}, {hotel.district_name}
                  </MDTypography>
                </>
              ),
              actions: (
                <>
                  <MDButton
                    id={hotel.hotel_id.toString()}
                    key={i}
                    variant="contained"
                    color="light"
                    onClick={handleOpenMenu}
                  >
                    <Icon>view_list</Icon>
                    &nbsp;Actions
                  </MDButton>
                </>
              ),
            };
            return hotelObj;
          }),
        }}
        entriesPerPage={false}
        pagination={{
          variant: "contained",
          color: "primary",
        }}
        showTotalEntries={false}
      />
    );
  }, [handleOpenMenu, hotels]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container>
            <Card sx={{ width: "100%" }}>
              <Grid container p={3} spacing={1}>
                <Grid item sx={{ width: "70%" }}>
                  <MDInput
                    sx={{ width: "100%" }}
                    label="Search"
                    value={_searchedValue}
                    onChange={(e: { target: { value: string } }) =>
                      _setSearchedValue(e.target.value)
                    }
                  ></MDInput>
                </Grid>
                <Grid item sx={{ marginLeft: "auto" }}>
                  <Grid container justifyContent="flex-end">
                    <AddHotel hotelAdded={(v: any): void => {}} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {renderMenu}
                {renderTable}
              </Grid>

              {openRemoveHotel ? (
                <Grid item xs={12}>
                  <RemoveItem
                    hotel_id={hotelId}
                    open={openRemoveHotel}
                    setOpen={setOpenRemoveHotel}
                    removedHotel={(v: any): void => {}}
                  />
                </Grid>
              ) : null}
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {openAddHotel ? (
        <Grid item xs={12}>
          <EditHotel
            open={openAddHotel}
            setOpen={setOpenAddHotel}
            hotelAdded={() => _setUpdatePage(_updatePage + 1)}
            hotelId={hotelId}
          />
        </Grid>
      ) : null}
    </>
  );
}

export default Hotels;
