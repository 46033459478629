import { Button, Grid, Stack } from "@mui/material";
import HotelItem from "./components/HotelItem";
import TotalPrices from "./components/TotalPrices";
import { useFormContext } from "../FormContextProvide";
import PrintIcon from "@mui/icons-material/Print";
import Post from "lib/api/post";
import { PREVIOUS, SUBMIT, MODIFY } from "../const";
import { useParams } from "react-router-dom";
import Put from "lib/api/put";

type Props = {
  next: () => void;
  back: () => void;
  activeStep: number;
  formSubmit: () => void;
};

const ConfirmationTabContent = ({ next, back, activeStep, formSubmit }: Props) => {
  const { formContext, setFormContext } = useFormContext();
  const { id } = useParams();

  const handleSubmit = async () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const form = {
      ...formContext,
      session_id: user?.session_id,
      user_id: user?.uid,
    };
    if (!id) {
      await Post.addReservation(form)
        .then((res) => {
          console.log("res", res);
          formSubmit();
          setFormContext({
            hotel_note: "",
            operation_note: "",
            status: 1,
            rooms: [],
            extras: [],
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      await Put.updateReservation({ ...form, reservation_id: id })
        .then((res) => {
          console.log("res", res);
          formSubmit();
          setFormContext({
            hotel_note: "",
            operation_note: "",
            status: 1,
            rooms: [],
            extras: [],
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  return (
    <Grid container>
      {formContext?.rooms.map((room: any) => (
        <Grid xs={12} item>
          <HotelItem key={room.id} room={room} />
        </Grid>
      ))}
      <Grid xs={12} item>
        <TotalPrices />
      </Grid>
      <Grid xs={12} item direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
        <Button variant="contained" size="small" color="secondary">
          <PrintIcon />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" py={2} justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={back}
            sx={{ mr: 1 }}
          >
            {PREVIOUS}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {id ? MODIFY : SUBMIT}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ConfirmationTabContent;
