import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import {
  SELL_EUR,
  SELL_TL,
  SELL_USD,
  BUY_EUR,
  BUY_USD,
  BUY_TL,
  SINGLE,
  DOUBLE,
  FAMILY,
  SUITE,
  FINANCE_STATISTICS,
  ROOM_STATISTICS,
  TRIPLE,
  TOTAL,
} from "./constants";
import { ReportsIProps } from "./interfaces";

const Reports = ({ financeData, roomData }: ReportsIProps) => {
  function numberWithCommas(x: number) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Grid mt={2} display="flex" gap={2}>
      <Card sx={{ p: 1, width: "50%" }}>
        <Typography fontSize={16} fontWeight={600}>
          {FINANCE_STATISTICS}
        </Typography>
        <Grid display="flex">
          <Box sx={{ width: "33%" }}>
            <Typography fontSize={14}>
              {SELL_EUR} : {numberWithCommas(financeData?.sell_eur)}
            </Typography>
          </Box>
          <Box sx={{ width: "33%" }}>
            <Typography fontSize={14}>
              {SELL_USD} : {numberWithCommas(financeData?.sell_usd)}
            </Typography>
          </Box>
          <Box sx={{ width: "33%" }}>
            <Typography fontSize={14}>
              {SELL_TL} : {numberWithCommas(financeData?.sell_tele)}
            </Typography>
          </Box>
        </Grid>

        <Grid display="flex">
          <Box sx={{ width: "33%" }}>
            <Typography fontSize={14}>
              {BUY_EUR} : {numberWithCommas(financeData?.buy_eur)}
            </Typography>
          </Box>
          <Box sx={{ width: "33%" }}>
            <Typography fontSize={14}>
              {BUY_USD} : {numberWithCommas(financeData?.buy_usd)}
            </Typography>
          </Box>
          <Box sx={{ width: "33%" }}>
            <Typography fontSize={14}>
              {BUY_TL} : {numberWithCommas(financeData?.buy_tele)}
            </Typography>
          </Box>
        </Grid>
      </Card>
      <Card sx={{ p: 1, width: "50%" }}>
        <Typography fontSize={16} fontWeight={600}>
          {ROOM_STATISTICS}
        </Typography>

        <Grid display="flex">
          <Box sx={{ width: "25%" }}>
            <Typography fontSize={14}>
              {SINGLE} : {roomData?.single}
            </Typography>
          </Box>
          <Box sx={{ width: "25%" }}>
            <Typography fontSize={14} color="red">
              {TRIPLE} : {roomData?.trp}
            </Typography>
          </Box>
          <Box sx={{ width: "25%" }}>
            <Typography fontSize={14}>
              {DOUBLE} : {roomData?.double}
            </Typography>
          </Box>
          <Box sx={{ width: "25%" }}>
            <Typography fontSize={14} color="red">
              {TOTAL} : {roomData?.total}
            </Typography>
          </Box>
        </Grid>
        <Grid display="flex">
          <Box sx={{ width: "50%" }}>
            <Typography fontSize={14}>
              {FAMILY} : {roomData?.family}
            </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography fontSize={14}>
              {SUITE} : {roomData?.suite}
            </Typography>
          </Box>
        </Grid>
      </Card>
    </Grid>
  );
};

export default Reports;
