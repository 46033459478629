import { PickTimeType } from "..";
import { PassengerType, TransferType } from "lib/types/api-type";
import _ from "lodash";
import { LeaderType } from "../types";
import { FlightPrintType } from "./types";

export const prepareTable = (transfers: TransferType[], pickTime: PickTimeType) => {
  let flights: FlightPrintType[] = [];
  //
  transfers.forEach((transfer, index) => {
    transfer.paxs.forEach((p) => {
      const index = flights?.findIndex((flight) => flight.flight_id === p.flight_id);
      const pax = Array.isArray(p.passengers) ? p.passengers.length : 1;
      const paxs = [p];
      if (index === -1) {
        flights.push({
          flight_time: pickTime("flight", p.flight_id),
          flight_code: p.flight_code,
          flight_origin: p.flight_origin,
          flight_destination: p.flight_destination,
          flight_id: p.flight_id,
          transfers: [{ ...transfer, paxs, pax }],
          height: 0,
          pax,
        });
      } else {
        flights[index].transfers.push({ ...transfer, paxs, pax });
        flights[index].pax = transfersPaxHandler(flights[index].transfers);
      }
    });
  });

  // set pax

  return _.sortBy([...flights], ["flight_time"], ["asc"]);
};

export const createLeaderTable = (flights: FlightPrintType[], date: string) => {
  //
  let newLeaders: LeaderType[] = [];
  flights.forEach((flight) => {
    flight.transfers.forEach((transfer) => {
      const leaderIndex = newLeaders?.findIndex(
        (leader) => leader.leader_id === transfer.leader_id && leader.side === transfer.side
      );

      const newFlight: FlightPrintType = {
        ...flight,
        pax: transfersPaxHandler([transfer]),
        transfers: [transfer],
      };

      if (leaderIndex === -1) {
        newLeaders.push({
          flights: [newFlight],
          leader_id: transfer.leader_id,
          leader_name: transfer.leader_name,
          side: transfer.side,
          page: 1,
          date,
        });
      } else {
        const flightIndex = newLeaders[leaderIndex]?.flights?.findIndex(
          (f) => f.flight_id === flight.flight_id
        );
        if (flightIndex === -1) {
          newLeaders[leaderIndex].flights.push(newFlight);
        } else {
          newLeaders[leaderIndex].flights[flightIndex].transfers.push(transfer);
          newLeaders[leaderIndex].flights[flightIndex].pax = transfersPaxHandler(
            newLeaders[leaderIndex].flights[flightIndex].transfers
          );
        }
      }
    });
  });

  return _.sortBy(newLeaders, ["leader_name", "side"], ["asc", "asc"]);
};

export const flightsPaxHandler = (flights: FlightPrintType[]) => {
  let totalPax = 0;
  flights.forEach((flight) => {
    totalPax = totalPax + transfersPaxHandler(flight.transfers);
  });
  return totalPax;
};

export const transferPaxHandler = (transfer: TransferType) => {
  let sum = 0;
  transfer?.paxs?.forEach((pax) => {
    sum = sum + getPassengersCount(pax.passengers);
  });
  return sum;
};

export const transfersPaxHandler = (transfers: TransferType[]) => {
  let sum: number = 0;
  transfers?.forEach((transfer) => {
    // pax = pax + transfer.pax;
    sum += transferPaxHandler(transfer);
  });
  return sum;
};

export const getPassengersCount = (passengers?: PassengerType | PassengerType[]) => {
  return Array.isArray(passengers) ? passengers.length : 1;
};

export const convertPxtoCm = (px: number) => px * 0.026458;
export const convertCmToPx = (cm: number) => cm * 37.7952755906;
