import { CircularProgress, Dialog, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Post, { SyncBatchFormType } from "lib/api/post";
import { useEffect, useState } from "react";
import { SELECT_USER } from "store/slices/profile";
import { justEnglish } from "utils/just-language";
import { prepareBatchData, prepareTable, SyncBatchDataType } from "utils/prepare-table";
import sortHotelsByName from "utils/sort-hotels-by-name";

import { FlightRecordsType, HotelRecordsType, HType, TableRowType } from "../../../../lib/types";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import { OnChangeType, RowsType, RowType } from "components/customs/Table";
import ExcelReader from "components/customs/ExelReader";
import AddHotel from "components/Forms/AddHotel";
import Table from "components/customs/Table";

// import XLSX from "xlsx";
const _ = require("lodash");

type WhereType = "table1" | "table2" | "rows";

const addTableToLocalStorage = (where: WhereType, table: RowsType | TableRowType[]) => {
  localStorage.setItem(where, JSON.stringify(table));
};

const getTableFromLocalStorage = (where: WhereType) => {
  const t = localStorage.getItem(where);
  return JSON.parse(t);
};

function Onlytransfer(): JSX.Element {
  const [rows, setRows] = useState<TableRowType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(0);
  const [table1, setTable1] = useState<RowsType>([]);
  const [table2, setTable2] = useState<RowsType>([]);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [openPermissionToRemoveCatch, setOpenPermissionToRemoveCatch] = useState<boolean>(false);
  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const user = SELECT_USER();

  const sendsyncBatchForm = (batchData: SyncBatchDataType) => {
    if (loading) return;
    const syncBatchForm: SyncBatchFormType = {
      flights: JSON.stringify(batchData.flights),
      hotels: JSON.stringify(batchData.hotels),
      indras: JSON.stringify(batchData.indras),
      user_id: user.uid,
      session_id: user.session_id,
    };

    setLoading(true);
    Post.syncBatch(syncBatchForm)
      .then((res) => {
        const {
          hotelRecords: hotel_records,
          flightRecords,
        }: { hotelRecords: HType[][] | HType[]; flightRecords: FlightRecordsType } = res.data;
        // setDefault value from localstorage
        const temp: RowsType = [...table1];
        const newTable1: RowsType = [];

        // bug from backend
        const hotelRecords: HType[][] = hotel_records.map((h) => (Array.isArray(h) ? h : [h]));

        const { hotels, flights } = batchData;

        let length = hotels?.length;
        if (hotels?.length < flights.length) {
          length = flights.length;
        }
        for (let index = 0; index < length; index++) {
          let newRow: RowType = {};
          if (temp[index]) {
            // setDefault value from localstorage
            newRow = { ...temp[index] };
          }
          newRow.index = { value: index, grid: { xs: 0.5 } };
          let newHotelRecords: HType;

          if (hotelRecords?.length && hotels[index]?.key) {
            newHotelRecords = sortHotelsByName(hotelRecords[index], hotels[index]?.key);
          }

          newRow.hotel = {
            value: hotels[index]?.key,
            grid: { xs: 7.5 },
            type: "autoComplete",
            options:
              hotelRecords[index]?.map((option) => ({
                label: option.fullname,
                id: option.hotel_id,
              })) || [],
            defaultValue: newRow?.hotel?.defaultValue?.id
              ? newRow.hotel.defaultValue
              : {
                  label: hotelRecords[index] ? newHotelRecords?.fullname : "",
                  id: hotelRecords[index] ? newHotelRecords?.hotel_id : "",
                },
          };
          newRow.flight = {
            value: flights[index]?.key,
            type: "autoComplete",
            grid: { xs: 3, md: 3 },
            options:
              flightRecords[index]?.map((option) => ({
                label: option?.flight_code,
                id: option?.flight_id,
              })) || [],
            defaultValue: newRow?.flight?.defaultValue?.id
              ? newRow.flight.defaultValue
              : {
                  label: flightRecords[index] ? flightRecords[index][0]?.flight_code : "",
                  id: flightRecords[index] ? flightRecords[index][0]?.flight_id : "",
                },
          };
          newTable1.push(newRow);
        }
        setTable1(newTable1);
        addTableToLocalStorage("table1", newTable1);
        setIsSaved(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onChangeTable1: OnChangeType = (indexRow, key, value) => {
    const newT1 = [...table1];
    newT1[indexRow][key].defaultValue = value;
    setTable1([...newT1]);
    setIsSaved(false);
    addTableToLocalStorage("table1", [...newT1]);
  };

  const onChangeTable2: OnChangeType = (indexRow, key, value) => {
    const newT2 = [...table2];
    newT2[indexRow][key].defaultValue = value;
    setTable2([...newT2]);
    setIsSaved(false);
  };

  const createUserTable = (t: TableRowType[], t2: RowsType) => {
    if (tabValue === 0) return;
    if (!t2.length) return;
    const newT2 = _.orderBy(_.cloneDeep(table2), ["index.value"], ["ask"]);
    const rows2: RowsType = [];

    t?.forEach((row, index) => {
      const row2: RowType = {};
      row2.index = { value: index, grid: { xs: 0.5 } };
      row2.fullname = {
        value: row?.fullname,
        defaultValue: newT2?.[index]?.fullname?.defaultValue
          ? newT2?.[index]?.fullname?.defaultValue
          : row?.fullname,
        type: "textField",
        justEnglish: true,
        grid: { xs: 2 },
      };
      row2.hotel_id = {
        value: row.hotel_id,
        defaultValue: t2?.find((t) => t?.hotel?.value === row?.hotel_id)?.hotel?.defaultValue,
        grid: { xs: 3 },
      };
      row2.arrival_info = {
        value: row?.arrival_info,
        defaultValue: t2?.find((t) => t?.flight?.value === row?.arrival_info)?.flight?.defaultValue,
        grid: { xs: 0.5 },
      };
      row2.arrival_time = {
        value: row?.arrival_time,
        grid: { xs: 1 },
      };
      row2.departure_info = {
        value: row?.departure_info,
        defaultValue: t2?.find((t) => t?.flight?.value === row?.departure_info)?.flight
          ?.defaultValue,
        grid: { xs: 0.5 },
      };
      row2.departure_time = { value: row?.departure_time, grid: { xs: 1 } };
      row2.age_type = { value: row.age_type, grid: { xs: 1 } };
      row2.phone = { value: row.phone, grid: { xs: 1 } };
      row2.indra = { value: row.indra, grid: { xs: 1 } };
      rows2.push(row2);
    });

    const sortedRows2 = _.orderBy(rows2, ["fullname.value"], ["desc"]);
    setTable2(sortedRows2);
    return sortedRows2;
  };

  const addNewHotel = (new_hotel: any) => {
    const newT: RowType[] = [...table1];
    newT.forEach((r, index) => {
      newT[index].hotel.options.push(new_hotel);
    });
    setTable1([...newT]);
  };

  const saveHandler = () => {
    const t: any = [];
    let errors: any = [];
    table2.forEach((row, index) => {
      setLoading(true);
      let hasError = false;
      if (!row?.hotel_id?.defaultValue?.id) {
        errors.push({ index: row.index.value, message: `hotel: ${row?.hotel?.value}` });
        hasError = true;
      }
      if (!row?.arrival_info?.defaultValue?.id) {
        errors.push({
          index: row.index.value,
          message: `arrival_info: ${row?.arrival_info?.value}`,
        });
        hasError = true;
      }
      if (!row?.departure_info?.defaultValue?.id) {
        errors.push({
          index: row.index.value,
          message: `departure_info: ${row?.departure_info?.value}`,
        });
        hasError = true;
      }
      if (!justEnglish(row.fullname?.defaultValue)) {
        errors.push({ index: row.index.value, message: `name: ${row.fullname.value}` });
        hasError = true;
      }
      if (!hasError)
        t.push({
          hotel_id: row.hotel_id.defaultValue.id,
          fullname: row.fullname.defaultValue,
          departure_info: row.departure_info.defaultValue.id,
          arrival_info: row.arrival_info.defaultValue.id,
          //
          arrival_time: row.arrival_time.value,
          departure_time: row.departure_time.value,
          phone: row.phone.value,
          age_type: row.age_type.value,
          indra: row.indra.value,
        });
    });

    if (errors.length) {
      const e = errors.map((e: any) => `${e.index}: ${e.message}\n`);
      alert(e);
      setLoading(false);
    } else {
      // send t
      Post.saveBatch(t)
        .then((res) => {
          alert("success!");
          setLoading(false);
          addTableToLocalStorage("rows", []);
          addTableToLocalStorage("table1", []);
          setIsSaved(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (loading) return;
    if (rows.length && !table1.length) {
      const BD = prepareBatchData(rows);
      sendsyncBatchForm(BD);
    }
  }, [rows]);

  useEffect(() => {
    if (tabValue === 1 && rows.length && table1.length) {
      // create user-table => passengers
      createUserTable(rows, table1);
    }
  }, [rows, table1, tabValue]);

  useEffect(() => {
    const t1 = getTableFromLocalStorage("table1");
    if (t1 && t1.length) {
      setTable1([...t1]);
    }
    const rs = getTableFromLocalStorage("rows");
    if (rs && rs.length) {
      setRows([...rs]);
    }
  }, []);

  // const table2_memorized = useMemo(() => createUserTable(rows, table1), [table1.length, rows]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={2} display="flex" justifyContent={"space-between"}>
        <ExcelReader
          setData={(data) => {
            const preparedData = prepareTable(data);
            setRows([...preparedData]);
            addTableToLocalStorage("rows", preparedData);
          }}
        />
      </MDBox>
      <MDBox p={2}>
        <Tabs
          value={tabValue}
          onChange={handleSetTabValue}
          sx={{
            ".MuiTabs-indicator": { background: "#000000" },
            ".MuiButtonBase-root.Mui-selected .MuiBox-root": { color: "#fff" },
            "@media(max-width:600px)": {
              ".MuiTabs-flexContainer": {
                display: "flex",
                flexDirection: "column",
              },
              ".MuiButtonBase-root": { transition: "0.3s" },
              ".MuiTabs-indicator": { display: "none" },
              ".Mui-selected": { background: "#000" },
            },
          }}
        >
          <Tab
            label={
              <MDBox py={0.5} px={2} color="inherit">
                Hotels
              </MDBox>
            }
          />
          <Tab
            label={
              <MDBox py={0.5} px={2} color="inherit">
                Passengers
              </MDBox>
            }
          />
        </Tabs>
      </MDBox>

      {tabValue === 0 ? (
        <>
          <MDBox p={2} display={"flex"} justifyContent="space-between">
            <AddHotel hotelAdded={addNewHotel} />
            {rows.length ? (
              <>
                <MDButton
                  disabled={loading}
                  color={"dark"}
                  variant="text"
                  onClick={() => {
                    const d = prepareBatchData([...rows]);
                    sendsyncBatchForm({ ...d });
                  }}
                >
                  {(loading && <CircularProgress size="20px" color="secondary" />) || (
                    <>
                      Get Data
                      <RestartAltRoundedIcon />
                    </>
                  )}
                </MDButton>
                <MDButton
                  disabled={loading}
                  color={"dark"}
                  variant="text"
                  onClick={() => {
                    setOpenPermissionToRemoveCatch(true);
                  }}
                >
                  Delete Data
                </MDButton>
              </>
            ) : null}
          </MDBox>
          <Table rows={table1} onChange={onChangeTable1} />
        </>
      ) : (
        <>
          <MDBox display="flex" justifyContent={"space-between"} alignItems={"center"}>
            <MDBox display="flex" p={2} gap={1}>
              <MDButton
                color="error"
                // variant="contained"
                onClick={() => {
                  const newT2 = _.orderBy(_.cloneDeep(table2), ["fullname.value"], ["desc"]);
                  setTable2(_.cloneDeep(newT2));
                }}
              >
                errors
              </MDButton>
              <MDButton
                onClick={() => {
                  const newT2 = _.orderBy(_.cloneDeep(table2), ["fullname.value"], ["ask"]);
                  setTable2(newT2);
                }}
              >
                sort name
              </MDButton>
              <MDButton
                onClick={() => {
                  const newT2 = _.orderBy(_.cloneDeep(table2), ["index.value"], ["ask"]);
                  setTable2(newT2);
                }}
              >
                sort index
              </MDButton>
            </MDBox>

            <MDButton
              sx={{ maxHeight: 40 }}
              disabled={loading || isSaved}
              color="primary"
              onClick={saveHandler}
            >
              {(loading && <CircularProgress size="14px" color="warning" />) || <div>save</div>}
            </MDButton>
          </MDBox>
          <Table rows={table2} onChange={onChangeTable2} />
        </>
      )}
      <Dialog
        open={openPermissionToRemoveCatch}
        onClose={() => setOpenPermissionToRemoveCatch(false)}
        fullWidth
        maxWidth="sm"
      >
        <MDBox textAlign={"center"} p={2}>
          <MDBox>are you sure?</MDBox>
          <MDBox mt={4} display="flex" justifyContent={"center"} gap={1}>
            <MDButton
              color="error"
              variant={"contained"}
              onClick={() => {
                addTableToLocalStorage("rows", []);
                addTableToLocalStorage("table1", []);
                setTable1([]);
                setRows([]);
                setOpenPermissionToRemoveCatch(false);
              }}
            >
              yes
            </MDButton>
            <MDButton
              color={"dark"}
              variant="outlined"
              onClick={() => {
                setOpenPermissionToRemoveCatch(false);
              }}
            >
              no
            </MDButton>
          </MDBox>
        </MDBox>
      </Dialog>
    </DashboardLayout>
  );
}

export default Onlytransfer;
