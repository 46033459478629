import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, FormControl, Stack, Tooltip } from "@mui/material";
import { CANCELED, CONFIRM, NO_SHOW, PENDING, STATUS } from "../const";
import { AccessAlarms, Check, Close, ThumbDownOffAlt } from "@mui/icons-material";
import { InputLabel } from "@material-ui/core";

interface StatusType {
  setFormContext: (T: any) => typeof T;
  formContext: any;
}

const Status = ({ formContext, setFormContext }: StatusType) => {
  const [activeStatus, setActiveStatus] = useState<number>(1);

  const useStyles = makeStyles((theme) => ({
    button: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const handleStatus = (activeKey: number) => {
    setActiveStatus(activeKey);
    setFormContext({ ...formContext, status: activeKey });
  };

  useEffect(()=>{
    setActiveStatus(formContext?.status)
  },[formContext])

  return (
    <FormControl fullWidth>
      <Box my={1}>
        <InputLabel htmlFor="demo-customized-select-native">{STATUS}</InputLabel>
      </Box>
      <Stack direction="row" alignSelf="flex-end">
        <Tooltip title={PENDING}>
          <Button
            className={classes.button}
            variant="contained"
            color="warning"
            size="small"
            startIcon={<AccessAlarms />}
            onClick={() => handleStatus(1)}
          >
            {activeStatus === 1 ? PENDING : ""}
          </Button>
        </Tooltip>
        <Tooltip title={CONFIRM}>
          <Button
            className={classes.button}
            variant="contained"
            color="warning"
            size="small"
            startIcon={<Check />}
            onClick={() => handleStatus(2)}
          >
            {activeStatus === 2 ? CONFIRM : ""}
          </Button>
        </Tooltip>
        <Tooltip title={CANCELED}>
          <Button
            className={classes.button}
            variant="contained"
            color="warning"
            size="small"
            startIcon={<Close />}
            onClick={() => handleStatus(3)}
          >
            {activeStatus === 3 ? CANCELED : ""}
          </Button>
        </Tooltip>
        <Tooltip title={NO_SHOW}>
          <Button
            className={classes.button}
            variant="contained"
            color="warning"
            size="small"
            startIcon={<ThumbDownOffAlt />}
            onClick={() => handleStatus(4)}
          >
            {activeStatus === 4 ? NO_SHOW : ""}
          </Button>
        </Tooltip>
      </Stack>
    </FormControl>
  );
};

export default Status;
