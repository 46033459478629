import { updateFlies } from "components/customs/Flies/utils";
import { FlightTimeType, GetPassengersType, HotelType } from "lib/types/api-type";
import _ from "lodash";
import { AddRowType, FlyPrintType, HotelPrintType, PageType } from "./types";

export const prepareData = (flies: GetPassengersType, flightTimes: FlightTimeType[]) => {
  // arrivals
  const arNorm: FlyPrintType[] = updateFlies(flies.arrivalPassengers.normal, flightTimes).map(
    (f) => ({ ...f, type: "fly", hotels: prepareHotelsData(f.hotels || [], false) })
  );
  const arVip: FlyPrintType[] = updateFlies(flies.arrivalPassengers.vip, flightTimes).map((f) => ({
    ...f,
    type: "fly",
    hotels: prepareHotelsData(f.hotels || [], true),
  }));
  // departures
  const dprNorm: FlyPrintType[] = updateFlies(flies.departurePassengers.normal, flightTimes).map(
    (f) => ({ ...f, type: "fly", hotels: prepareHotelsData(f.hotels || [], false) })
  );
  const dprVip: FlyPrintType[] = updateFlies(flies.departurePassengers.vip, flightTimes).map(
    (f) => ({
      ...f,
      type: "fly",
      hotels: prepareHotelsData(f.hotels || [], true),
    })
  );

  return {
    arrivals: _.sortBy([...arNorm, ...arVip], ["time"], ["asc"]),
    departures: _.sortBy([...dprNorm, ...dprVip], ["time"], ["asc"]),
  };
};

export const prepareHotelsData: (hotels: HotelType[], vip: boolean) => HotelPrintType[] = (
  hotels,
  vip
) => {
  return hotels.map((h) => ({ ...h, vip, type: "hotel" }));
};

export const addRow: AddRowType = (pageData, pageHeight, row, rowHeight, flyRow, flyHeight) => {
  const isFlyRow = row.type === "fly";
  let { adds, pageIndex, pages } = { ...pageData };

  if (adds + rowHeight < pageHeight) {
    adds = adds + rowHeight;
  } else {
    pageIndex++;
    adds = isFlyRow ? rowHeight : rowHeight + flyHeight;
  }
  if (pages[pageIndex]) {
    pages[pageIndex].push(row);
  } else {
    pages.push(isFlyRow ? [row] : [flyRow, row]);
  }
  return { adds, pageIndex, pages };
};

export const addEndLine = (
  pages: PageType[],
  pageHeight: number,
  endlineHeight: number,
  flightHeight: number,
  hotelHeight: number
) => {
  const newPages = [...pages];
  if (!newPages.length) return newPages;
  const lastPage = [...newPages[newPages.length - 1]];
  let adds = 0;
  lastPage.forEach((r) => {
    switch (r.type) {
      case "fly":
        adds += flightHeight;
        break;
      case "hotel":
        adds += hotelHeight;
        break;
      default:
        break;
    }
  });
  if (adds + endlineHeight <= pageHeight) {
    lastPage.push({ type: "endLine" });
    newPages[newPages.length - 1] = lastPage;
  }
  return newPages;
};
