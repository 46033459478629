/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Components

// @mui material components

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ReportFilter from "components/customs/ReportFilter";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Calendar application components

// Data

export interface ResponseReportsType {
  car_count: number;
  flight_count: number;
  passenger_count: number;
}

function Reports(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ResponseReportsType>();

  useEffect(() => {}, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%)" }}>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ mt: 2.4 }}>
            <ReportFilter updateData={setData} loading={loading} setLoading={setLoading} />
          </Grid>
          <Grid item xs={12} md={9} sx={{ mt: 2.4 }}>
            <MDBox
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                maxWidth: "400px",
                margin: "20px auto",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  height: "100px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <MDTypography>cars:</MDTypography>
                <MDTypography>{data?.car_count}</MDTypography>
              </Card>

              <Card
                sx={{
                  width: "100%",
                  height: "100px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <MDTypography>flights:</MDTypography>
                <MDTypography>{data?.flight_count}</MDTypography>
              </Card>

              <Card
                sx={{
                  width: "100%",
                  height: "100px",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <MDTypography>passengers:</MDTypography>
                <MDTypography>{data?.passenger_count}</MDTypography>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}

export default Reports;
