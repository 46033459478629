import { memo } from "react";
import { PickTimeType } from "../..";
import { FlightPrintType, TableStyleType } from "../types";

import FllightRow from "./FlightRow";
import HotelRow from "./HotelRow";
import PassengerRow from "./PassengerRow";

interface PropsType {
  flight: FlightPrintType;
  styles: TableStyleType;
  showTick: boolean;
  showDescription: boolean;
  showLeader: boolean;
  pickTime: PickTimeType;
}

const Row = ({ showTick, showLeader, showDescription, flight, styles, pickTime }: PropsType) => {
  // row

  return (
    <>
      <FllightRow styles={styles} flight={flight} />
      {flight.transfers.map((transfer, flightIndex) =>
        transfer.paxs.map((pax, transferIndex) => (
          <>
            <HotelRow
              showLeader={showLeader}
              pickTime={pickTime}
              flight={flight}
              transfer={transfer}
              pax={pax}
              styles={styles}
              key={transfer.id + flight.flight_id}
            />

            {Array.isArray(pax?.passengers) ? (
              pax?.passengers?.map((passenger, paxIndex) => (
                <PassengerRow
                  showDescription={showDescription}
                  showTick={showTick}
                  key={paxIndex}
                  passenger={passenger}
                  styles={styles}
                />
              ))
            ) : (
              <PassengerRow
                showDescription={showDescription}
                showTick={showTick}
                passenger={pax?.passengers}
                styles={styles}
              />
            )}
          </>
        ))
      )}
    </>
  );
};

export default memo(Row);

export const convertToHours = (date: string) => {
  return date.substring(11, 16);
};
