export const PASSENGER_NAME = "Passenger Name";
export const AGE = "age";
export const ADD = "Add";
export const CANCEL = "Cancel";
export const SUBMIT = "submit";
export const SEARCH = "Search";
export const NAME = "Name";
export const ARRIVAL = "Arrival";
export const DEPARTURE = "Departure";
export const SELECT_FLIGHT = "Select Flight";
export const ADD_FLIGHT = "Add Flight";
export const FLIGHT_CODE = "Flight Code";
export const FLIGHT_AIRLINE = "Flight Airline";
export const ORIGIN = "Origin";
export const DESTINATION = "Destination";
export const LOCAL_FLIGHT = "Local Flight";
export const INCOMING_FLIGHT = "Incoming Flight";
export const YES = "Yes";
export const NO = "No";
export const PATH = "Path";
export const SELECT_AIRLINE = "Select airline";
export const ADD_AIRLINE = "Add Airline";
export const AIRLINE_CODE = "Airline Code";
export const TITLE = "Title";
export const ORDER = "Order";
export const TRANSFER = "Transfer";
export const VIP = "VIP";
export const CURRENCY = "CURRENCY";
export const PRICE = "Price";
export const BUY_PRICE = "Buy Price";
export const SELL_PRICE = "Sell Price";
export const CHECK_IN_OUT = "Check in-out";
export const NIGHTS = "Night(s)";
export const SERVICE_TYPE = "Service Type";
export const VIP_TRANSFER = "VIP Transfer";
export const VIT_CONFIRM = "Are you sure to enable/disable VIP transfer?";
export const CONFIRM = "Confirm";
export const BATCH_JOBS = "Batch Jobs";
export const APPLY = "Apply";
export const ARRIVAL_TRANSFER = "Arrival Transfer";
export const DEPARTURE_TRANSFER = "Departure Transfer";
export const PASSENGERS = "Passengers:";
export const SELECT_TIME = "Select Time";
export const SELECT_NEW_ROOM_TYPE = "Select New Room Type";
export const UPDATE = "Update";
export const ADD_NIGHTS = "Add Night(s)";
export const REMOVE = "Remove";
export const SELECT_PASSENGER = "Select Passenger";
