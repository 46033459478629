import { useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  Divider,
  Grid,
  Button,
  Switch,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import { SELECT_USER } from "store/slices/profile";
import { MODAL_STYLE } from "../../../style";
import Post from "lib/api/post";
import {
  ADD_FLIGHT,
  CANCEL,
  DESTINATION,
  FLIGHT_CODE,
  INCOMING_FLIGHT,
  LOCAL_FLIGHT,
  NO,
  ORIGIN,
  SUBMIT,
  YES,
} from "../const";
import FlightAirline from "./FlightAirline";

interface AddUserDialogType {
  open: boolean;
  onClose: (T: any) => typeof T;
}

function AddFlightDialog({ open, onClose }: AddUserDialogType) {
  const [isLocalSwitch, setIsLocalSwitch] = useState(null);
  const [flightAirlineValue, setFlightAirlineValue] = useState(null);
  const user = SELECT_USER();

  const { handleSubmit, handleChange, handleBlur, values } = useFormik<any>({
    initialValues: {
      flight_code: "",
    },
    onSubmit: (value) => {
      const payload = {
        flight_code: value?.flight_code,
        flight_airline: flightAirlineValue?.airline_id,
        flight_origin: value?.flight_origin,
        flight_destination: value?.flight_destination,
        local: isLocalSwitch ? 1 : 0,
        session_id: user.session_id,
        user_id: user.uid,
      };
      Post.addFlight(payload).then((data) => {
        onClose(false);
      });
    },
  });

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={MODAL_STYLE} component={"form"} onSubmit={handleSubmit}>
          <Typography>{ADD_FLIGHT}</Typography>
          <Divider sx={{ borderWidth: "1px" }} />
          <Grid container>
            <Grid xs={6} p={1}>
              <FormControl fullWidth required>
                <TextField
                  required
                  label={FLIGHT_CODE}
                  variant="outlined"
                  name="flight_code"
                  value={values.flight_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Grid>
            <Grid xs={6} p={1}>
              <FormControl fullWidth required>
                <FlightAirline
                  flightAirlineValue={flightAirlineValue}
                  setFlightAirlineValue={setFlightAirlineValue}
                />
              </FormControl>
            </Grid>

            <Grid xs={6} p={1}>
              <FormControl fullWidth required>
                <TextField
                  required
                  label={ORIGIN}
                  variant="outlined"
                  name="flight_origin"
                  value={values.flight_origin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Grid>
            <Grid xs={6} p={1}>
              <FormControl fullWidth required>
                <TextField
                  required
                  label={DESTINATION}
                  variant="outlined"
                  name="flight_destination"
                  value={values.flight_destination}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormControl>
            </Grid>

            <Grid xs={6} p={1}>
              <FormControl fullWidth>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>{LOCAL_FLIGHT}</Typography>
                  <Typography>{NO}</Typography>
                  <Switch
                    //   {...label}
                    name="local"
                    value={values.local}
                    onChange={(e) => setIsLocalSwitch(e.target.checked)}
                  />
                  <Typography>{YES}</Typography>
                </Stack>
              </FormControl>
            </Grid>
            <Grid xs={6} p={1}>
              <FormControl fullWidth>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>{INCOMING_FLIGHT}</Typography>
                  <Typography>{NO}</Typography>
                  <Switch
                    //   {...label}
                    name="allow_session"
                    value={values.allow_session}
                    //   onChange={(e) => setIsSwitch(e.target.checked)}
                  />
                  <Typography>{YES}</Typography>
                </Stack>
              </FormControl>
            </Grid>
          </Grid>

          <Divider sx={{ borderWidth: "1px" }} />
          <Grid display="flex" justifyContent="flex-end">
            <Box sx={{ mr: 2 }}>
              <Button variant="contained" color="secondary" onClick={() => onClose(false)}>
                {CANCEL}
              </Button>
            </Box>

            <Box>
              <Button variant="contained" color="primary" type="submit">
                {SUBMIT}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default AddFlightDialog;
