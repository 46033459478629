import { useState } from "react";
import { Box, CircularProgress, Dialog, DialogContent, Grid } from "@mui/material";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { SELECT_USER } from "store/slices/profile";
import { useFormik } from "formik";

import Delete from "lib/api/delete";
import Post from "lib/api/post";

interface PropsType {
  hotel_id?: number;
  removedHotel?: (v: any) => void;

  contact_id?: number;
  removedContact?: (v: any) => void;

  room_id?: number;
  removedRoom?: (v: any) => void;

  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveItem = ({
  hotel_id,
  contact_id,
  room_id,
  open,
  setOpen,
  removedHotel,
  removedContact,
  removedRoom,
}: PropsType) => {
  const user = SELECT_USER();
  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit } = useFormik<any>({
    initialValues: {
      hotel_id: null,
      contact_id: null,
    },
    onSubmit: () => {
      setLoading(true);
      if (hotel_id) {
        Post.deleteHotel(user.uid, user.session_id, hotel_id)
          .then((res) => {
            setLoading(false);
            removedHotel(res.data);
            setOpen(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
        return;
      }
      if (contact_id) {
        Delete.deleteContact(user.uid, user.session_id, contact_id)
          .then((res) => {
            setLoading(false);
            removedContact(res.data);
            setOpen(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
        return;
      }
      if (room_id) {
        Delete.deleteRoom(user.uid, user.session_id, room_id)
          .then((res) => {
            setLoading(false);
            removedRoom(res.data);
            setOpen(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
        return;
      }
    },
  });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <MDTypography variant="h5">Remove Confirmation</MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="body2">
                      Are you sure to remove selected item?
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDButton color={"error"} type={"submit"}>
                      {(loading && <CircularProgress size="14px" color="error" />) || (
                        <div>Confirm</div>
                      )}
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      disabled={loading}
                      color={"light"}
                      onClick={() => setOpen(false)}
                    >
                      cancel
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RemoveItem;
