import { SxProps } from "@mui/material";
import { LeaderType } from "components/customs/Overview/types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { memo, useMemo } from "react";
import { TableStyleType } from "../../types";
import { titleStyle } from "../constant";

interface PropsType {
  leader: LeaderType | any;
  page: number;
  logo?: string;
  styles: TableStyleType;
  totalPax?: number;
}

const LeaderRow = ({ leader, page, logo, totalPax, styles }: PropsType) => {
  const txtStyle: SxProps = useMemo(
    () => ({ ...titleStyle, ...styles.titleStyle }),
    [styles?.titleStyle]
  );
  return (
    <MDBox position={"relative"}>
      <MDBox sx={{ borderBottom: "1px solid #aaa", ...txtStyle }}>
        <MDTypography textAlign="center" textTransform={"capitalize"}>
          {leader.leader_name}
        </MDTypography>
        {leader?.side ? (
          <MDTypography
            textAlign="center"
            className="options"
            textTransform={"capitalize"}
            sx={{ textTransform: "capitalize" }}
          >
            {leader?.date}({leader?.side})
          </MDTypography>
        ) : null}
        {totalPax ? (
          <MDTypography fontSize={"12px"} textAlign="center" className="options">
            TotalPax: {totalPax}
          </MDTypography>
        ) : null}
      </MDBox>
      <MDBox fontSize={"12px"} position="absolute" right={0} top={30}>
        page: {page}
      </MDBox>
      <MDBox fontSize={"12px"} position="absolute" left={0} top={5}>
        {logo ? <MDBox width="80px" height={"80px"} component={"img"} src={logo} /> : null}
      </MDBox>
    </MDBox>
  );
};

export default memo(LeaderRow);
