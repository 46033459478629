import {
  ContentType,
  DriverType,
  FlyType,
  PaxType,
  SideType,
  TransferType,
} from "lib/types/api-type";
import { useCallback, useEffect, useState } from "react";
import fliesDataHandler, { ElementsType } from "utils/flies-data-handler";
import uid from "utils/uuid";
import Put from "lib/api/put";
import getCircularReplacer from "utils/getCicularReplacer";
import {
  AddTransferType,
  ChangeDriverAndCar,
  CreateNewTransfer,
  OnSelectType,
  Props,
  RemoveFlyOrHotelFromTransfer,
  RemovePaxType,
  RemoveTransferType,
  UpdateTransferType,
} from "./types";

const useCar = ({
  date,
  user,
  flies,
  drivers: driversData,
  cars,
  leaders,
  updateContent,
}: Props) => {
  const [tabValue, setTabValue] = useState<SideType>("departure");

  const [arrival, setArrival] = useState<ElementsType>();
  const [departure, setDeparture] = useState<ElementsType>();

  const [content, setContent] = useState<ContentType>();
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  // current pax to add to transfer
  const [currentItem, setCurrentItem] = useState<PaxType | PaxType[]>();
  // all pax sellected
  const [selectedPax, setSelectedPax] = useState<PaxType[]>();

  // for print
  const [drivers, setDrivers] = useState<DriverType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openMessage, setOpenMessage] = useState<boolean>(false);

  const removePax: RemovePaxType = useCallback(({ paxIndex, itemIndex }) => {
    setContent((pre) => {
      const newContent = { ...pre };
      let items = [...newContent.items];
      items[itemIndex].paxs.splice(paxIndex, 1);
      return { ...newContent, items };
    });
  }, []);

  const onSelect: OnSelectType = useCallback((pax) => {
    setOpenAdd(true);
    setCurrentItem(pax);
  }, []);

  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const addTransfer: AddTransferType = useCallback((transfer) => {
    setContent((pre) => {
      const newContent = { ...pre };
      const newItems = [...newContent.items, transfer];
      return { ...newContent, items: newItems };
    });
  }, []);

  const updateTransfer: UpdateTransferType = useCallback((transfer) => {
    setContent((pre) => {
      const newContent = { ...pre };
      const { items } = { ...newContent };
      const index = items?.findIndex((item) => item.id === transfer.id);
      if (index === -1) return;
      items[index] = { ...transfer };
      return { ...newContent, items };
    });
  }, []);

  const removeTransfer: RemoveTransferType = useCallback((index) => {
    setContent((pre) => {
      const newContent = { ...pre };
      newContent.items.splice(index, 1);
      return { ...newContent };
    });
  }, []);

  const addHPToTransfer = (transfer: TransferType) => {
    const newContent = { ...content };
    const { items } = { ...newContent };
    const index = items?.findIndex((item) => item.id === transfer.id);
    if (index === -1) return;

    const { paxs, ...rest } = { ...items[index] };
    if (Array.isArray(currentItem)) {
      currentItem.forEach((p) => {
        items[index].paxs.push({
          ...rest,
          ...p,
          pax: Array.isArray(p.passengers) ? p.passengers.length : 1,
        });
        setContent({ ...newContent, items });
      });
    } else {
      items[index].paxs.push({
        ...rest,
        ...currentItem,
        pax: Array.isArray(currentItem.passengers) ? currentItem.passengers.length : 1,
      });
    }

    setContent({ ...newContent, items });
    setOpenAdd(false);
  };

  const save = () => {
    if (loading) return;
    setOpenMessage(false);
    setLoading(true);
    let newContent: any = { ...content };

    newContent = JSON.stringify(newContent, getCircularReplacer());

    Put.setOperation({
      content: newContent,
      date,
      session_id: user.session_id,
      user_id: user.uid,
    })
      .then((res) => {
        setLoading(false);
        updateContent(content);
        setOpenMessage(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const changeDriverAndCar: ChangeDriverAndCar = useCallback((driver, car, index) => {
    setContent((pre) => {
      const newContent = { ...pre };
      const currentTransfer = { ...newContent.items[index] };
      const newTransfer: TransferType = {
        ...createNewTransfer(driver, car, currentTransfer.side),
        paxs: currentTransfer.paxs.map((p) => ({
          ...p,
          car_code: car.car_code,
          car_id: car.car_id.toString(),
          car_type: car.car_type.toString(),
          driver_id: driver.user_id.toString(),
          driver_name: driver.fullname,
        })),
      };
      newContent.items[index] = newTransfer;

      return indexHandler(newContent);
    });
  }, []);

  const removeFlyOrHotelFromTransfer: RemoveFlyOrHotelFromTransfer = (fly, hotel) => {
    setContent((pre) => {
      let newContent = { ...pre };
      let items: TransferType[] = [];

      newContent.items.forEach((item) => {
        const paxs: PaxType[] = [];

        item.paxs.forEach((pax) => {
          if (hotel) {
            if (
              hotel.hotel_name === pax.hotel_name &&
              pax.flight_code === fly.flight_code &&
              checkPassenger(fly, pax)
            ) {
              // don't add pax here
            } else {
              paxs.push(pax);
            }
          } else if (pax.flight_code === fly.flight_code && checkPassenger(fly, pax)) {
            // don't add pax here
          } else {
            paxs.push(pax);
          }
        });

        const newItem: TransferType = {
          ...item,
          paxs,
        };
        items.push(newItem);
      });

      //  p.flight_code === fly.flight_code && p.hotel_name !== hotel.hotel_name

      return { ...newContent, items };
    });
  };

  /*
  
  
  
  
  
  
  */

  useEffect(() => {
    let ps: PaxType[] = [];
    content?.items?.forEach((item) => {
      item?.paxs?.forEach((p) => {
        ps.push({ ...p, side: item.side });
      });
    });
    setSelectedPax(ps);
    updateContent(undefined);
  }, [content]);

  useEffect(() => {
    if (!content?.items?.length) return;
    const newContent = indexHandler(content);
    setContent({ ...newContent });
  }, [content?.items?.length]);

  useEffect(() => {
    if (!driversData?.length || drivers?.length || !content) return;
    const newDrivers: DriverType[] = [];
    driversData.forEach((driver) => {
      const newDriver = { ...driver, tasks: 0, user_id: `${driver?.user_id}` };
      content?.items?.forEach((item) => {
        if (item.driver_id === driver?.user_id) {
          newDriver.tasks++;
        }
      });
      newDrivers.push(newDriver);
    });
    setDrivers(newDrivers);
  }, [driversData, content]);

  useEffect(() => {
    if (flies) {
      const f = fliesDataHandler(flies);
      setArrival({ ...f.arrivalPassengers });
      setDeparture({ ...f.departurePassengers });
      const content = flies.content;
      if (content) {
        setContent(JSON.parse(content));
      } else {
        const newContent: ContentType = {
          items: [],
          collapsed_hotels: [],
          loading: false,
        };
        setContent(newContent);
      }
    }
  }, [flies]);

  const { arrivalPassengers, departurePassengers } = flies || {};
  const isArrival = tabValue === "arrival";
  return {
    date,
    cars,
    leaders,
    openMessage,
    tabValue,
    selectedPax,
    arrivalPassengers,
    departurePassengers,
    arrival,
    departure,
    isArrival,
    content,
    drivers,
    loading,
    openAdd,
    changeDriverAndCar,
    addHPToTransfer,
    updateTransfer,
    removeTransfer,
    setCurrentItem,
    setOpenAdd,
    setOpenMessage,
    handleSetTabValue,
    onSelect,
    save,
    addTransfer,
    removePax,
    removeFlyOrHotelFromTransfer,
  };
};

export default useCar;

export const createNewTransfer: CreateNewTransfer = (driver, car, side) => {
  const newTransfer: TransferType = {
    car_id: car.car_id.toString(),
    car_code: car.car_code,
    side: side,
    car_type: car.car_type.toString(),
    driver_id: driver.user_id.toString(),
    driver_name: driver.fullname,
    leader_id: "",
    leader_name: "",
    leader_id2: "",
    leader_name2: "",
    flights: [],
    hidden: false,
    id: uid(),
    index: 0,
    pax: 0,
    paxs: [],
  };
  return newTransfer;
};

export const indexHandler = (content: ContentType) => {
  if (!content?.items?.length) return;
  const drivers: { driver_id: string; lastIndex: number }[] = [];
  const newItems: TransferType[] = [];
  content?.items.forEach((item) => {
    const index = drivers?.findIndex((d) => d.driver_id === item.driver_id);
    let lastIndex = 1;
    if (index === -1) {
      drivers.push({ driver_id: item.driver_id, lastIndex });
      newItems.push({ ...item, index: lastIndex });
    } else {
      lastIndex = drivers[index].lastIndex + 1;
      drivers[index].lastIndex = lastIndex;
      newItems.push({ ...item, index: lastIndex });
    }
  });
  return { ...content, items: newItems };
};

const checkPassenger = (fly: FlyType, pax: PaxType) => {
  let is = false;
  if (Array.isArray(pax.passengers)) {
    for (const flyHotel of fly.hotels) {
      for (const passenger of flyHotel.passengers) {
        if (pax.passengers.find((p) => p.user_id === passenger.user_id)) {
          return true;
        }
      }
    }
    return false;
  } else {
    for (const flyHotel of fly.hotels) {
      const { user_id } = pax.passengers;
      if (flyHotel.passengers.find((p) => p.user_id === user_id)) {
        return true;
      }
    }
  }
  return false;
};
