import { FormControl, Grid, Icon, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CarType, ContentType, DriverType, TransferType } from "lib/types/api-type";
import { useEffect, useMemo, useState } from "react";
import AirportShuttleRoundedIcon from "@mui/icons-material/AirportShuttleRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import FlightRoundedIcon from "@mui/icons-material/FlightRounded";
import MDBadge from "components/MDBadge";
import {
  ChangeDriverAndCar,
  RemovePaxType,
  RemoveTransferType,
  UpdateTransferType,
} from "../CarsAndLeaders/types";
import { transferPaxHandler } from "../Overview/Table/utils";
import AddTransfer from "components/Forms/AddTransfer";

interface PropsType {
  transfer?: TransferType;
  leaders: DriverType[];
  driver?: DriverType;
  itemIndex: number;
  content: ContentType;
  cars: CarType[];
  drivers: DriverType[];
  updateTransfer: UpdateTransferType;
  removePax: RemovePaxType;
  removeTransfer: RemoveTransferType;
  changeDriverAndCar: ChangeDriverAndCar;
}

const Transfer = ({
  leaders,
  transfer,
  itemIndex,
  content,
  cars,
  drivers,
  updateTransfer,
  removePax,
  removeTransfer,
  changeDriverAndCar,
}: PropsType) => {
  const [leader01, setLeader01] = useState<{ label: string; value: string }>();
  const [leader02, setLeader02] = useState<{ label: string; value: string }>();

  useEffect(() => {
    if (transfer?.leader_id && transfer?.leader_name) {
      setLeader01({ label: transfer.leader_name, value: transfer.leader_id });
    }
    if (transfer?.leader_id2 && transfer?.leader_name2) {
      setLeader02({ label: transfer.leader_name2, value: transfer.leader_id2 });
    }
  }, [transfer]);

  const changeHanlder = (user_id: string, leaderStatus: "" | "2") => {
    const leader = leaders?.find((d) => d?.user_id.toString() === user_id);
    if (!leader) return;
    const newLeader: { [key: string]: string } = {};

    newLeader[`leader_name${leaderStatus}`] = leader.fullname;
    newLeader[`leader_id${leaderStatus}`] = leader.user_id;

    let { paxs, ...rest } = transfer;

    const newTransfer: TransferType = {
      paxs: paxs.map((p) => ({ ...p, ...newLeader })),
      ...rest,
      ...newLeader,
    };
    updateTransfer(newTransfer);
  };

  const totalPax = useMemo(() => transferPaxHandler(transfer), [transfer?.paxs.length]);

  return (
    <MDBox borderRadius={"8px"} border={"1px solid #ddd"} overflow="hidden">
      <MDBox p={1} display={"flex"} justifyContent="space-between" gap="20px" bgColor="#dddd">
        <MDBox display={"flex"} alignItems={"center"} gap="20px">
          <AddTransfer
            onConfirm={({ driver, car }) => changeDriverAndCar(driver, car, itemIndex)}
            transfers={content?.items}
            drivers={drivers}
            cars={cars}
            transfer={transfer}
            button={
              <>
                <AirportShuttleRoundedIcon />
              </>
            }
          />
          <MDTypography fontSize={"12px"}>{transfer.driver_name}</MDTypography>
          <MDTypography fontSize={"12px"}>{transfer?.car_code}</MDTypography>
          <MDTypography fontSize={"12px"}>#{transfer?.index}</MDTypography>
          <MDTypography fontSize={"12px"}> TotalPax: {totalPax}</MDTypography>
        </MDBox>
        <IconButton onClick={() => removeTransfer(itemIndex)}>
          <Icon>close</Icon>
        </IconButton>
      </MDBox>
      <Grid p={1} container spacing={1}>
        <Grid xs={6} item>
          <FormControl fullWidth>
            <InputLabel>leader.01</InputLabel>
            <Select
              sx={{
                ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                  minHeight: "46px",
                },
              }}
              value={leader01?.value || ""}
              label="leader.01"
              onChange={(e) => changeHanlder(e.target.value, "")}
            >
              {leaders?.map((leader, index) => (
                <MenuItem key={index} value={leader.user_id.toString()}>
                  {leader.fullname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={6} item>
          <FormControl fullWidth>
            <InputLabel>leader.02</InputLabel>
            <Select
              sx={{
                ".MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                  minHeight: "46px",
                },
              }}
              value={leader02?.value || ""}
              label="leader.02"
              onChange={(e) => changeHanlder(e.target.value, "2")}
            >
              {leaders?.map((leader, index) => (
                <MenuItem key={index} value={leader?.user_id.toString()}>
                  {leader.fullname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} item>
          {transfer?.paxs?.map((pax, paxIndex) => (
            <MDBox key={paxIndex} mt={2} borderBottom="1px solid #ddd">
              <MDBox display="flex" justifyContent={"space-between"} alignItems="center" gap="10px">
                <MDBox display="flex" gap="10px">
                  <FlightRoundedIcon />
                  <MDTypography fontSize="12px">{pax.flight_code}</MDTypography>
                  <MDBadge
                    size="xs"
                    color={"info"}
                    badgeContent={`${pax.flight_origin}`}
                    container
                  />
                  <MDBadge
                    size="xs"
                    color={"info"}
                    badgeContent={`${pax.flight_destination}`}
                    container
                  />
                  <MDBadge size="xs" color={"dark"} badgeContent={`${pax.pax} pax`} container />
                </MDBox>
                <IconButton onClick={() => removePax({ paxIndex, itemIndex })}>
                  <Icon>close</Icon>
                </IconButton>
              </MDBox>
              {pax.type === "hotel" ? (
                <MDBox display="flex" gap="10px">
                  <>
                    <HotelOutlinedIcon />
                    <MDTypography fontSize="12px">{pax.hotel_name}</MDTypography>
                  </>
                </MDBox>
              ) : (
                <MDBox>
                  <MDBox display="flex" gap="10px">
                    <PersonOutlineRoundedIcon />
                    <MDTypography fontSize="12px">{pax.passenger_name}</MDTypography>
                  </MDBox>
                  <MDBox display="flex" gap="10px">
                    <MDTypography fontSize="12px">in</MDTypography>
                    <HotelOutlinedIcon />
                    <MDTypography fontSize="12px">{pax.hotel_name}</MDTypography>
                  </MDBox>
                </MDBox>
              )}
            </MDBox>
          ))}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default Transfer;
