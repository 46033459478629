/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

import { FormikProps, useFormik } from "formik";
import Post, { SignInFormType } from "lib/api/post";
import { setToken } from "utils/axios";
import { useDispatch } from "react-redux";
import { updateUser } from "store/slices/profile";

function Cover(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    errors,
  }: FormikProps<SignInFormType> = useFormik<SignInFormType>({
    initialValues: { passwd: "bobiagah", username: "babak@agah.com" },
    // validationSchema: mobileSchema,

    //
    onSubmit: async (values, actions) => {
      const v = { ...values };

      Post.signIn({ passwd: v.passwd, username: v.username })
        .then((res) => {
          actions.resetForm();
          // res.data.accessToken
          setToken(res.data.accessToken);
          localStorage.user = JSON.stringify(res.data);
          dispatch(updateUser(res.data));
          navigate("/dashboards", { replace: true });
          // setData({ step: 2, mobile: v.mobile });
        })
        .catch((err) => {});
    },
  });

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit}>
            <MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  placeholder="john@example.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  name="username"
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="passwd"
                  value={values.passwd}
                  fullWidth
                  placeholder="************"
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton type={"submit"} variant="gradient" color="info" fullWidth>
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
