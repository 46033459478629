import { HType } from "lib/types";
import _ from "lodash";
var stringSimilarity = require("string-similarity");

const sortHotelsByName = (hotels: HType[], keyword: string) => {
  let arr = [...hotels];
  if (!hotels[0]) return { fullname: "", hotel_code: "", hotel_id: 0 };
  let res = stringSimilarity.findBestMatch(
    keyword,
    arr.map((s) => s?.fullname.toLowerCase() || "")
  );

  const result = arr?.find((a) => a.fullname.toLowerCase() === res.bestMatch.target.toLowerCase());

  return result;
};

export default sortHotelsByName;
