/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useCallback, useEffect, useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Calendar application components

// Data
import Post from "lib/api/post";
import { SELECT_USER } from "store/slices/profile";
import { CalendarEventType } from "lib/types/calenderType";
import { AppBar, Box, Card, CardContent, Tab, Tabs } from "@mui/material";

import {
  ContentType,
  GetPassengersType,
  GetRequirements,
  Operations2Type,
} from "lib/types/api-type";
import Calendar from "components/customs/Calendar";
import Passengers from "components/customs/Passengers";
import CarsAndLeaders from "components/customs/CarsAndLeaders";
import Overview from "components/customs/Overview";
import { useDispatch } from "react-redux";
import {
  updateDescriptions,
  updateFlies,
  updateFlightTimes,
  updateHotelTimes,
  updateVouchers,
  updateArrivalLeaders,
} from "store/slices/operations";
import { DateClickArg } from "@fullcalendar/interaction";

function Operations(): JSX.Element {
  const [flies, setFlies] = useState<GetPassengersType>();
  const [data, setData] = useState<GetRequirements>();
  const [events, setEvents] = useState<CalendarEventType[]>([]);
  const [tabValue, setTabValue] = useState<0 | 1 | 2 | 3>(0);
  const [overViewData, setOverViewData] = useState<ContentType>();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const user = SELECT_USER();
  const [currentDate, setCurrentDate] = useState<string>();
  useEffect(() => {
    if (loading) return;
    Post.operations({ session_id: user.session_id, user_id: user.uid })
      .then((res) => {
        // prepare data
        const { data } = res;
        let newData = { ...data };
        newData.drivers = newData.drivers.map((d: any) => ({
          ...d,
          user_id: `${d?.user_id}`,
          tasks: 0,
        }));
        setData(newData);
      })
      .catch((err) => console.log(err));
  }, []);

  const prepareEvents = (operations: Operations2Type[]) => {
    let newEvents: CalendarEventType[] = [];
    operations.forEach((operation) => {
      let newEvent: CalendarEventType = {
        title: `${operation.arrival_pax}/${operation.departure_pax}`,
        start: operation.date,
        end: operation.date,
        className: "dark",
      };
      if (operation.arrival_pax_vip || operation.departure_pax_vip) {
        let vipEvent: CalendarEventType = {
          title: `${operation.arrival_pax_vip}/${operation.departure_pax_vip}`,
          start: operation.date,
          end: operation.date,
          className: "info",
        };
        newEvents.push(vipEvent);
      }
      newEvents.push(newEvent);
    });
    setEvents(newEvents);
  };

  useEffect(() => {
    if (data?.operations2) prepareEvents(data.operations2);
  }, [data?.operations2]);

  const handleSetTabValue = (event: any, newValue: any) => {
    if (newValue === 3 && !overViewData) return;
    setTabValue(newValue);
  };

  const getFlies = (date: string) => {
    setLoading(true);
    Post.getPassengers({ date, session_id: user.session_id, user_id: user.uid })
      .then((res) => {
        if (res.data) {
          setFlies(res.data);
          dispatch(updateDescriptions(res.data.descriptions));
          dispatch(updateFlightTimes(res.data.flightTimes));
          dispatch(updateHotelTimes(res.data.hotelTimes));
          dispatch(updateVouchers(res.data.voucher_info));
          dispatch(updateArrivalLeaders(res.data.arr_leads));
          dispatch(
            updateFlies({
              arrivalPassengers: res.data.arrivalPassengers,
              departurePassengers: res.data.departurePassengers,
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (currentDate && tabValue) {
      getFlies(currentDate);
    }
  }, [currentDate, tabValue]);

  const dateClickHandler = useCallback((e: DateClickArg) => {
    setCurrentDate(e.dateStr);
    setTabValue(1);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox minWidth={{ xs: "22rem", md: "25rem" }} mx="auto" mt={6}>
        <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{
              ".MuiTabs-indicator": { background: "#000000" },
              ".MuiButtonBase-root.Mui-selected .MuiBox-root": { color: "#fff" },
              "@media(max-width:600px)": {
                ".MuiTabs-flexContainer": {
                  display: "flex",
                  flexDirection: "column",
                },
                ".MuiButtonBase-root": { transition: "0.3s" },
                ".MuiTabs-indicator": { display: "none" },
                ".Mui-selected": { background: "#000" },
              },
            }}
          >
            <Tab
              label={
                <MDBox py={0.5} px={2} color="inherit">
                  01.Date
                </MDBox>
              }
            />
            <Tab
              disabled={!currentDate}
              label={
                <MDBox py={0.5} px={2} color="inherit">
                  02.Passengers
                </MDBox>
              }
            />
            <Tab
              disabled={!currentDate || !flies}
              label={
                <MDBox py={0.5} px={2} color="inherit">
                  03.Cars/Leaders
                </MDBox>
              }
            />
            <Tab
              disabled={!currentDate}
              label={
                <MDBox py={0.5} px={2} color="inherit">
                  04.Overviews
                </MDBox>
              }
            />
          </Tabs>
        </AppBar>
      </MDBox>

      <MDBox pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
            {useMemo(
              () =>
                tabValue === 0 ? <Calendar dateClick={dateClickHandler} events={events} /> : null,
              [events, tabValue]
            )}
            {tabValue ? (
              <Card>
                <Grid item xs={12} textAlign="center">
                  <MDBox p={1}>
                    <h5>{currentDate}</h5>
                  </MDBox>
                </Grid>
                <CardContent>
                  <Box py={2}>
                    {tabValue === 1 ? (
                      currentDate && <Passengers flies={flies} date={currentDate} user={user} />
                    ) : tabValue === 2 ? (
                      <>
                        {!loading ? (
                          <CarsAndLeaders
                            cars={data.cars}
                            drivers={data.drivers}
                            leaders={data.leaders}
                            flies={flies}
                            date={currentDate}
                            user={user}
                            updateContent={setOverViewData}
                          />
                        ) : null}
                      </>
                    ) : tabValue === 3 && overViewData ? (
                      <Overview
                        cars={data.cars}
                        drivers={data.drivers}
                        leaders={data.leaders}
                        flies={flies}
                        date={currentDate}
                        data={overViewData}
                        user={user}
                      />
                    ) : null}
                  </Box>
                </CardContent>
              </Card>
            ) : null}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Operations;
