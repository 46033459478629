import {
  Box,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AGES, NIGHTS, TOTALS, TOTAL } from "./const";
import { HOTEL } from "../../const";
import { diffDaysOfTwoDate } from "utils/helpers";
import { useFormContext } from "../../FormContextProvide";
interface IHotelItemProps {
  room: any;
}
const HotelItem = ({ room }: IHotelItemProps) => {
  const { formContext } = useFormContext();

  return (
    <Card variant="outlined" sx={{ p: 1, mb: 1 }}>
      <Grid container spacing={3} sx={{ fontSize: "14px" }}>
        <Grid item xs={12}>
          <Stack direction="column" spacing={1.25} alignItems="flex-end">
            #{formContext.voucher}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" spacing={1.25} alignItems="flex-start">
              <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                {formContext?.agency_name}
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                {formContext?.resseller_name}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1.25} alignItems="flex-end">
              <Typography variant="subtitle1" sx={{ fontSize: "1.2em", fontWeight: "bold" }}>
                {HOTEL}
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
                {room?.hotel_title}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1.25} alignItems="flex-start">
            <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
              {`${room?.room_type1_title} > ${room?.room_type2_title}`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" spacing={1.25} alignItems="flex-start">
            <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "300" }}>
              {`${room.checkIn} to ${room.checkOut}`}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "1em", fontWeight: "bold" }}>
              {`${
                room?.checkIn &&
                room?.checkOut &&
                diffDaysOfTwoDate(new Date(room?.checkIn), new Date(room?.checkOut))
              } ${NIGHTS}`}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" sx={{ px: 1 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ display: "contents" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">{AGES}</TableCell>
                  <TableCell align="center">{TOTALS}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {room?.passengers?.map((passenger: any) => (
                  <TableRow
                    key={passenger.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {passenger.label}
                    </TableCell>
                    <TableCell align="center"> {passenger.age_type} </TableCell>
                    <TableCell align="center">-</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={1} />
                  <TableCell align="center">
                    <Typography variant="subtitle1" sx={{ fontSize: "1.2em", fontWeight: "bold" }}>
                      {TOTAL}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {room.financial &&
                      room.financial?.length > 0 &&
                      room.financial.map((item: any) => {
                        return (
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: "1em", fontWeight: "300" }}
                          >
                            {`${Number(item?.sellPrice).toLocaleString()} ${
                              item?.sellPriceCurrencyCode
                            }`}
                          </Typography>
                        );
                      })}
                  </TableCell>
                </TableRow>
                {room.extras && room?.extras.length && (
                  <TableRow>
                    <TableCell colSpan={12}>
                      {room.extras.map((extra: any) => {
                        return (
                          <Stack direction="row" justifyContent="space-evenly">
                            <Box />
                            <Box>
                              <i>(Arac Vip)</i> {extra?.title}
                            </Box>
                            <Box>
                              {`${Number(extra.sellPrice).toLocaleString()} ${
                                extra?.sellPriceCurrencyCode
                              }`}
                            </Box>
                          </Stack>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Stack>
        </Grid>
      </Grid>
      <Box>
        <Box>
          <TableContainer component={Paper}></TableContainer>
        </Box>
      </Box>
    </Card>
  );
};

export default HotelItem;
