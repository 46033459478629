import { SxProps } from "@mui/material";
import { FlyPrintType } from "components/customs/Overview/PreOrganized/types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { HotelType } from "lib/types/api-type";
import { memo, useMemo } from "react";
import clockHandler from "utils/clock-handler";
import { TableStyleType } from "../../types";
import { getPassengersCount } from "../../utils";
import { columnStyle, rowStyle, textStyle } from "../constant";

interface PropsType {
  flight: FlyPrintType;
  styles: TableStyleType;
}

const FllightRow = ({ styles, flight }: PropsType) => {
  const r: SxProps = useMemo(() => ({ ...rowStyle, ...styles?.rowStyle }), [styles?.rowStyle]);

  const c1: SxProps = useMemo(
    () => ({ ...columnStyle, ...styles?.columnStyle }),
    [styles?.columnStyle]
  );
  const t1: SxProps = useMemo(() => ({ ...textStyle, ...styles?.textStyle }), [styles?.textStyle]);

  return (
    <MDBox sx={r}>
      <MDBox sx={c1} />
      <MDBox sx={c1} />
      <MDBox sx={{ ...c1, gridColumn: "2/5" }}>
        <MDTypography sx={t1}>
          {flight.flight_code}({`${flight.flight_origin} to ${flight.flight_destination}`})
        </MDTypography>
      </MDBox>

      <MDBox sx={c1}>
        <MDTypography sx={t1}>{clockHandler(flight.time)}</MDTypography>
      </MDBox>

      <MDBox sx={{ ...c1 }}>
        <MDTypography sx={t1}>{paxCount(flight.hotels)}</MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default memo(FllightRow);

const paxCount = (hotels: HotelType[]) => {
  let paxCount = 0;
  hotels.forEach((hotel) => {
    paxCount = paxCount + getPassengersCount(hotel.passengers);
  });
  return paxCount;
};

// <MDBox sx={r}>
// <MDBox sx={{ ...c1 }}>
//   <MDTypography
//     sx={t1}
//   >{`${flight.flight_origin} to ${flight.flight_destination}`}</MDTypography>
// </MDBox>
// <MDBox sx={{ ...c1 }}>
//   <MDTypography sx={t1}>{flight.flight_code}</MDTypography>
// </MDBox>
// <MDBox sx={{ ...c1 }}>
//   <MDTypography sx={t1}>{paxCount(flight.hotels)}</MDTypography>
// </MDBox>
// <MDBox sx={{ ...c1 }}>
//   <MDTypography sx={t1}>{clockHandler(flight.time)}</MDTypography>
// </MDBox>
// </MDBox>
