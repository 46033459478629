import { Accordion, AccordionDetails, AccordionSummary, Icon, IconButton } from "@mui/material";

import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { FlyType, HotelType, PaxType, SideType } from "lib/types/api-type";
import { useCallback, useState } from "react";
import clockHandler from "utils/clock-handler";
import Hotel from "../Hotel";
import LocalAirportRoundedIcon from "@mui/icons-material/LocalAirportRounded";
import AirportShuttleRoundedIcon from "@mui/icons-material/AirportShuttleRounded";
import MDButton from "components/MDButton";
import { StatusType } from "../Flies";
import { SELECT_FLIGHT_TIME } from "store/slices/operations";
import { OnSelectType, RemoveFlyOrHotelFromTransfer } from "../CarsAndLeaders/types";

interface PropsType {
  isAction?: boolean;
  date: string;
  selectedPax?: PaxType[];
  fly: FlyType;
  side?: SideType;
  setCurrentItem: (f: FlyType | null) => void;
  onSelect?: OnSelectType;
  removeFlyOrHotelFromTransfer?: RemoveFlyOrHotelFromTransfer;
}

const Fly = ({
  side,
  isAction = true,
  date,
  selectedPax,
  fly,
  setCurrentItem,
  onSelect,
  removeFlyOrHotelFromTransfer,
}: PropsType) => {
  const [hotelsStatus, setHotelsStatus] = useState<{ hotel: HotelType; status: StatusType }[]>([]);
  const [flyStatus, setFlyStatus] = useState<StatusType>("");
  const flightTime = SELECT_FLIGHT_TIME(fly.flight_id)?.flight_time;

  const hotelsStatusHandler = useCallback((hotel: HotelType, status: StatusType) => {
    setHotelsStatus((pre) => {
      const newHotelsStatus = [...pre];
      const index = newHotelsStatus?.findIndex((a) => a.hotel.hotel_id === hotel.hotel_id);
      if (index === -1) {
        newHotelsStatus.push({ hotel, status });
      } else {
        newHotelsStatus[index].status = status;
      }
      let d: any[] = [];
      // hotel Status
      newHotelsStatus.forEach((hs) => {
        if (hs.status === "darkCyan") {
          d.push(hs);
        }
      });
      // fly status
      if (d.length === newHotelsStatus.length) {
        setFlyStatus("darkCyan");
      } else if (d.length) {
        setFlyStatus("orange");
      } else {
        setFlyStatus("red");
      }

      return newHotelsStatus;
    });
  }, []);

  const addHotelsToTransfer = useCallback(
    (d: FlyType) => {
      let paxs: PaxType[] = [];
      d.hotels.forEach((hotel) => {
        paxs.push({
          id: "112",
          index: 0,
          flight_code: d.flight_code,
          flight_id: d.flight_id,
          flight_origin: d.flight_origin,
          flight_destination: d.flight_destination,
          time: flightTime,
          hotel_name: hotel.hotel_name,
          type: "hotel",
          pax: d.active_pax,
          updated_pax: d.updated_pax,
          passengers: hotel.passengers,
        });
      });
      onSelect(paxs);
    },
    [flightTime]
  );

  return (
    <>
      <AccordionDetails>
        <Accordion sx={{ boxShadow: "none", border: "1px solid #eee" }}>
          <MDBox display="flex" justifyContent={"space-between"}>
            <AccordionSummary style={{ width: "100%" }}>
              <MDBox
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                gap={1}
                py={1}
              >
                <MDBox display="flex" gap={1} maxHeight={20}>
                  <LocalAirportRoundedIcon />
                  <MDTypography
                    comopnenet="span"
                    fontSize={12}
                    sx={{ color: selectedPax && flyStatus }}
                  >
                    {fly.flight_code}
                  </MDTypography>
                  <MDBadge size="xs" color={"info"} badgeContent={fly.flight_origin} container />
                  <MDBadge
                    size="xs"
                    color={"info"}
                    badgeContent={fly.flight_destination}
                    container
                  />
                  <MDBadge
                    size="xs"
                    color={"dark"}
                    badgeContent={`${fly.active_pax} pax`}
                    container
                  />
                </MDBox>
              </MDBox>
            </AccordionSummary>
            <MDBox
              onClick={() => {
                if (isAction) {
                  setCurrentItem(fly);
                }
              }}
              minWidth={80}
              display="flex"
              alignItems={"center"}
              gap={1}
              sx={{ cursor: "pointer" }}
            >
              <>
                <AccessTimeIcon />
                <MDTypography fontSize="14px">{clockHandler(flightTime)}</MDTypography>
              </>
            </MDBox>

            {selectedPax ? (
              <>
                <MDBox display="flex" alignItems={"center"} gap={1} sx={{ cursor: "pointer" }}>
                  {flyStatus !== "red" ? (
                    <IconButton
                      size={"small"}
                      sx={{ color: "red" }}
                      onClick={() => removeFlyOrHotelFromTransfer(fly)}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  ) : null}
                  <MDButton
                    sx={{ color: flyStatus, width: 70 }}
                    onClick={() => flyStatus === "red" && addHotelsToTransfer(fly)}
                  >
                    {flyStatus === "red" ? <Icon>add</Icon> : null}
                    <AirportShuttleRoundedIcon />
                  </MDButton>
                </MDBox>
              </>
            ) : null}
          </MDBox>

          <AccordionDetails>
            {fly.hotels.map((hotel, index) => (
              <Hotel
                addHotelToTransfer={() => {
                  onSelect({
                    id: "112",
                    index: 0,
                    flight_code: fly.flight_code,
                    flight_id: fly.flight_id,
                    flight_origin: fly.flight_origin,
                    flight_destination: fly.flight_destination,
                    time: flightTime,
                    hotel_name: hotel.hotel_name,
                    type: "hotel",
                    pax: fly.active_pax,
                    updated_pax: fly.updated_pax,
                    passengers: hotel.passengers,
                  });
                }}
                addPassengerToTransfer={(passenger) => {
                  onSelect({
                    id: "112",
                    index: 0,
                    flight_code: fly.flight_code,
                    flight_id: fly.flight_id,
                    flight_origin: fly.flight_origin,
                    flight_destination: fly.flight_destination,
                    time: flightTime,
                    hotel_name: hotel.hotel_name,
                    type: "passenger",
                    pax: fly.active_pax,
                    updated_pax: fly.updated_pax,
                    passengers: passenger,
                    passenger_name: passenger.fullname,
                  });
                }}
                updateHotelStatus={hotelsStatusHandler}
                selectedHotel={
                  selectedPax && selectedPax?.find((p) => p?.hotel_name == hotel?.hotel_name)
                }
                selectedPax={selectedPax}
                isAction={isAction}
                date={date}
                side={side}
                hotel={hotel}
                key={index}
                fly={fly}
                flight_time={flightTime}
                removeFlyOrHotelFromTransfer={removeFlyOrHotelFromTransfer}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </>
  );
};

export default Fly;
