import { memo, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Icon, IconButton } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FlyType, HotelType, PassengerType, PaxType, SideType } from "lib/types/api-type";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import clockHandler from "utils/clock-handler";
import GetClock from "../GetClock";
import Put from "lib/api/put";
import { SELECT_USER } from "store/slices/profile";
import dateHandler from "utils/dateHandler";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import MDButton from "components/MDButton";
import AirportShuttleRoundedIcon from "@mui/icons-material/AirportShuttleRounded";
import { StatusType } from "../Flies";
import { SELECT_DESCRIPTIONS, SELECT_HOTEL_TIME, updateHotelTimes } from "store/slices/operations";
import { useDispatch } from "react-redux";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import GetVoucherDescription from "../GetVoucherDescription";
import { RemoveFlyOrHotelFromTransfer } from "../CarsAndLeaders/types";

const _ = require("lodash");
interface PropsType {
  hotel: HotelType;
  side?: SideType;
  // hotelTimes?: HotelTimeType[];
  date?: string;
  isAction: boolean;
  fly: FlyType;
  flight_time: string;
  selectedPax?: PaxType[];
  selectedHotel?: PaxType;
  updateHotelStatus?: (h: HotelType, status: StatusType) => void;
  addHotelToTransfer?: () => void;
  addPassengerToTransfer?: (passenger: PassengerType) => void;
  removeFlyOrHotelFromTransfer?: RemoveFlyOrHotelFromTransfer;
}

const Hotel = ({
  fly,
  flight_time,
  hotel,
  // hotelTimes,
  date,
  isAction,
  selectedHotel,
  selectedPax,
  side,
  updateHotelStatus,
  addHotelToTransfer,
  addPassengerToTransfer,
  removeFlyOrHotelFromTransfer,
}: PropsType) => {
  const dispatch = useDispatch();
  const [newHotel, setNewHotel] = useState<HotelType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<PassengerType[]>([]);
  const [passenger, setPassenger] = useState<PassengerType>(null);
  const user = SELECT_USER();
  const hotelTime = SELECT_HOTEL_TIME(hotel.hotel_id, fly.flight_id);
  const time = hotelTime?.flight_time;
  useEffect(() => {
    setNewHotel(undefined);
    if (hotel) {
      setNewHotel({ ...hotel });
    }
  }, [hotel]);

  const confirm = (time: string) => {
    if (!time) return;
    setLoading(true);

    Put.setHotelTime({
      flight_id: fly.flight_id,
      session_id: user.session_id,
      user_id: user.uid,
      hotel_id: hotel.hotel_id,
      time,
      date,
    })
      .then((res) => {
        const date = dateHandler(flight_time);
        dispatch(updateHotelTimes(res.data.hotelTimes));

        setOpen(false);
      })
      .finally(() => setLoading(false));
  };

  const generatePassengersStatus = (h: HotelType, paxs: PaxType[]) => {
    if (!h?.passengers || !h?.passengers?.length || !paxs) return;
    let data: PassengerType[] = [];
    paxs?.forEach((pax) => {
      for (const p of h?.passengers) {
        let find;
        if (Array.isArray(pax.passengers)) {
          find = pax.passengers.find(
            (p1) => p1?.user_id === p?.user_id && p1.flight_code === p.flight_code
          );
        } else if (
          p?.user_id === pax.passengers?.user_id &&
          p.flight_code === pax.passengers.flight_code
        ) {
          find = p;
        }
        if (find) {
          data.push(find);
        }
      }
    });

    setUserSelected(data);
  };

  useEffect(() => {
    if (newHotel && selectedHotel) {
      generatePassengersStatus(newHotel, selectedPax);
    } else {
      setUserSelected([]);
    }
  }, [newHotel, selectedPax]);

  const status: StatusType = !newHotel?.passengers?.length
    ? ""
    : userSelected?.length === newHotel?.passengers?.length
    ? "darkCyan"
    : userSelected?.length
    ? "orange"
    : "red";

  useEffect(() => {
    if (newHotel) updateHotelStatus(newHotel, status);
  }, [status]);

  const descriptions = SELECT_DESCRIPTIONS();

  return (
    <div>
      {newHotel && (
        <>
          <Accordion sx={{ boxShadow: "none", border: "1px solid #eee" }}>
            <MDBox width="100%" display="flex" justifyContent={"space-between"} alignItems="center">
              <AccordionSummary>
                <MDBox width="100%" gap={1} display="flex" justifyContent={"space-between"}>
                  <HotelOutlinedIcon />
                  <MDTypography sx={{ color: selectedPax && status }} fontSize="14px">
                    {newHotel.hotel_name}
                  </MDTypography>
                  <MDBadge
                    size="xs"
                    color={"dark"}
                    badgeContent={`${newHotel.active_pax} pax`}
                    container
                  />
                </MDBox>
              </AccordionSummary>
              <MDBox display="flex">
                {side === "departure" ? (
                  <MDBox
                    onClick={() => {
                      isAction && setOpen(true);
                    }}
                    minWidth={80}
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    sx={{ cursor: "pointer" }}
                  >
                    <>
                      <AccessTimeIcon />
                      {time ? (
                        <MDTypography fontSize="14px">{clockHandler(time)}</MDTypography>
                      ) : null}
                    </>
                  </MDBox>
                ) : null}

                {status !== "red" ? (
                  <IconButton
                    size={"small"}
                    sx={{ color: "red" }}
                    onClick={() => {
                      removeFlyOrHotelFromTransfer(fly, hotel);
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                ) : null}
                {/* car */}
                {addHotelToTransfer && selectedPax && (
                  <MDBox mr={1}>
                    <MDButton
                      sx={{ color: status, width: 60 }}
                      onClick={() => !userSelected.length && addHotelToTransfer()}
                    >
                      {userSelected.length ? null : <Icon>add</Icon>}
                      <AirportShuttleRoundedIcon />
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
            </MDBox>

            <AccordionDetails>
              {newHotel.passengers.map((passenger, index) => (
                <MDBox
                  key={index}
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <MDTypography minWidth={"60%"} fontSize={"12px"}>
                    {passenger.fullname}
                  </MDTypography>
                  <MDTypography fontSize={"12px"}>{passenger.age_type}</MDTypography>
                  <MDTypography ml="5px" fontSize={"12px"}>
                    {passenger.voucher}
                  </MDTypography>
                  <IconButton sx={{ ml: "5px" }} onClick={() => setPassenger(passenger)}>
                    <DescriptionRoundedIcon
                      fontSize="small"
                      color={
                        descriptions?.find(
                          (d) =>
                            d.voucher_id === passenger.voucher &&
                            d.desc_user_id === passenger.user_id
                        )?.description
                          ? "success"
                          : "error"
                      }
                    />
                  </IconButton>
                  {addPassengerToTransfer && selectedPax && (
                    <MDBox mr={1}>
                      {userSelected?.find((p) => p?.user_id == passenger?.user_id) ? (
                        <MDButton>
                          <AirportShuttleRoundedIcon sx={{ color: "darkCyan" }} />
                        </MDButton>
                      ) : (
                        <MDButton onClick={() => addPassengerToTransfer(passenger)}>
                          <Icon sx={{ color: "red" }}>add</Icon>
                          <AirportShuttleRoundedIcon sx={{ color: "red" }} />
                        </MDButton>
                      )}
                    </MDBox>
                  )}
                </MDBox>
              ))}
            </AccordionDetails>
          </Accordion>

          <GetClock
            open={open}
            onClose={setOpen}
            confirm={confirm}
            value={clockHandler(time)}
            loading={loading}
          />

          {side ? (
            <GetVoucherDescription
              side={side}
              date={date}
              passenger={passenger}
              onClose={setPassenger}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default memo(Hotel);
