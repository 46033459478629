import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete as MuiAtocomplete } from "@mui/material";

export interface AutoCompleteType {
  title: string;
  value: any;
}

interface PropsType {
  label: string;
  value: AutoCompleteType;
  onChange: (value: AutoCompleteType) => void;
  getData: (key: string) => Promise<AutoCompleteType[]>;
}

const Autocomplete = ({ label, value, onChange, getData }: PropsType) => {
  const [options, setOpetions] = useState<AutoCompleteType[]>([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!inputValue) return;
    // getData
    getData(inputValue).then((res: AutoCompleteType[]) => {
      setOpetions(res);
    });
  }, [inputValue]);

  return (
    <>
      <MuiAtocomplete
        getOptionLabel={(o) => o.title}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue as AutoCompleteType);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        renderInput={(params) => <TextField label={label} {...params} />}
      />
    </>
  );
};

export default Autocomplete;
