import { Accordion, AccordionSummary, Icon } from "@mui/material";

import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FlightTimeType, FlyType, PaxType, SideType } from "lib/types/api-type";
import { memo, useEffect, useMemo, useState } from "react";
import clockHandler from "utils/clock-handler";
import GetClock from "../GetClock";
import { SELECT_USER } from "store/slices/profile";
import dateHandler from "utils/dateHandler";
import Put from "lib/api/put";
import Fly from "../Fly";
import { useDispatch } from "react-redux";
import { SELECT_FLIGHT_TIME, SELECT_FLIGHT_TIMES, updateFlightTime } from "store/slices/operations";
import { OnSelectType, RemoveFlyOrHotelFromTransfer } from "../CarsAndLeaders/types";
import { updateFlies } from "./utils";
const _ = require("lodash");

export type StatusType = "" | "darkCyan" | "orange" | "red";

interface PropsType {
  side?: SideType;
  data: FlyType[];
  title: { text: string; flights: number; pax: number };
  isAction?: boolean;
  date: string;
  selectedPax?: PaxType[];
  onSelect?: OnSelectType;
  removeFlyOrHotelFromTransfer?: RemoveFlyOrHotelFromTransfer;
}

const Flies = ({
  side,
  data,
  title,
  isAction = true,
  date,
  selectedPax,
  removeFlyOrHotelFromTransfer,
  onSelect,
}: PropsType) => {
  const dispatch = useDispatch();
  const user = SELECT_USER();
  const flightTimes = SELECT_FLIGHT_TIMES();

  const [openClock, setOpenClock] = useState<boolean>(false);
  const [newData, setNewData] = useState<FlyType[]>();
  const [currentItem, setCurrentItem] = useState<FlyType | null>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    updateData(data, flightTimes);
  }, [data, flightTimes]);

  const updateData = (data: FlyType[], flightTimes: FlightTimeType[]) => {
    if (data?.length && flightTimes?.length) {
      const newData = updateFlies(data, flightTimes);
      setNewData(() => _.orderBy(newData, ["time"], ["asc"]));
    } else {
      const sorted = _.orderBy(data, ["time"], ["asc"]);
      setNewData(() => [...sorted]);
    }
  };

  useEffect(() => {
    if (!data?.length) return;
    if (newData?.length) {
      setNewData(undefined);
      setTimeout(() => {
        updateData(data, flightTimes);
      }, 1);
    } else {
      updateData(data, flightTimes);
    }
  }, [side]);

  const confirm = (time: string) => {
    if (!currentItem) return;
    if (loading) return;

    setLoading(true);
    const date = dateHandler(currentItem.time);
    Put.setFlightTime({
      time,
      session_id: user.session_id,
      user_id: user.uid,
      flight_id: currentItem.flight_id,
      date,
    })
      .then((res) => {
        setOpenClock(false);
        dispatch(
          updateFlightTime({ flight_id: currentItem.flight_id, flight_time: `${date}T${time}` })
        );
        setCurrentItem(null);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const time = SELECT_FLIGHT_TIME(currentItem?.flight_id)?.flight_time;

  const selected = useMemo(() => {
    if (selectedPax) {
      let data: PaxType[] = [];
      selectedPax?.forEach((s) => {
        if (s.side === side) {
          data.push(s);
        }
      });
      return data;
    }
  }, [side, selectedPax]);

  return (
    <>
      {newData?.length ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<Icon>expand_less</Icon>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MDBox display={"flex"} gap={2} alignItems={"center"}>
              <MDTypography fontSize="14px" color="secondary">
                {title.text}
              </MDTypography>
              <MDBox>
                <MDBadge
                  size="xs"
                  color={"info"}
                  badgeContent={`${title.flights} ${
                    title.flights ? (title.flights > 1 ? "flights" : "flight") : "no flight"
                  }`}
                  container
                />
              </MDBox>
              <MDBox>
                <MDBadge size="xs" color={"dark"} badgeContent={`${title.pax} pax`} container />
              </MDBox>
            </MDBox>
          </AccordionSummary>
          {newData?.map((d, index) => (
            <Fly
              side={side}
              key={index}
              fly={d}
              isAction={isAction}
              date={date}
              onSelect={onSelect}
              selectedPax={selected}
              setCurrentItem={(fly) => {
                setCurrentItem(fly);
                setOpenClock(true);
              }}
              removeFlyOrHotelFromTransfer={removeFlyOrHotelFromTransfer}
            />
          ))}
        </Accordion>
      ) : null}

      <GetClock
        confirm={confirm}
        open={openClock}
        onClose={setOpenClock}
        loading={loading}
        value={clockHandler(time || "00:00")}
      />
    </>
  );
};

export default memo(Flies);
