// const clockHandler: (time: Date) => string = (time) => {
//   const newTime = new Date(time);
//   const h = newTime.getHours();
//   const m = newTime.getMinutes();
//   const d = `${h > 9 ? h : `0${h}`}:${m < 10 ? `0${m}` : m}`;
//   return d;
// };
// export default clockHandler;

const clockHandler: (time: any) => string = (time) => {
  const t = time ? time.slice(11, 16) || "" : "";

  return t === "null" ? "" : t;
};
export default clockHandler;
