import React, { useEffect, useState } from "react";
import { ADD, CANCEL, NAME, PATH, SEARCH, SELECT_FLIGHT } from "../const";
import FilterAlt from "@mui/icons-material/FilterAlt";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import { Clear } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddFlightDialog from "./AddFlightDialog";
import { MODAL_STYLE } from "../../../style";

interface FlightFilterType {
  setFlightValue: (T: any) => typeof T;
  flightValue: any;
  title: string;
  callbackFunction?: (T: any) => typeof T;
}
const FlightFilter = ({
  flightValue,
  setFlightValue,
  title,
  callbackFunction,
}: FlightFilterType) => {
  const [open, setOpen] = useState(false);
  const [flightList, setFlightList] = useState(null);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isOpenAddFlight, setIsOpenAddFlight] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInputValue("");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "gray",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const user = SELECT_USER();
  useEffect(() => {
    setIsLoading(true);
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      key: inputValue,
      page: page,
      count: 5,
    };
    Post.searchFlight(payload)
      .then((res: any) => {
        setFlightList(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [inputValue, page, user?.session_id, user?.uid]);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleRemove = () => {
    setFlightValue?.(null);
    title === "Arrival"
      ? callbackFunction({
          arrival_flight: "",
          arrival_flight_code: "",
          arrival_flight_origin: "",
          arrival_flight_destination: "",
        })
      : callbackFunction({
          departure_flight: "",
          departure_flight_code: "",
          departure_flight_origin: "",
          departure_flight_destination: "",
        });
  };

  return (
    <>
      <Grid item display="flex" py={2}>
        <Typography onClick={handleOpen} sx={{ cursor: "pointer" }}>
          {title}
        </Typography>
        <Box mx={1} onClick={handleOpen} sx={{ cursor: "pointer" }}>
          <FilterAlt />
        </Box>
        {flightValue?.flight_code && (
          <>
            <Typography fontSize="14px" fontWeight="bold" ml={2} mt={0.8}>
              {flightValue?.flight_code}
            </Typography>
            <Box ml={2} sx={{ cursor: "pointer" }} onClick={handleRemove}>
              <Clear color="error" />
            </Box>
          </>
        )}
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={MODAL_STYLE}>
          <Typography>{SELECT_FLIGHT}</Typography>
          <Divider sx={{ borderWidth: "1px" }} />
          <Box sx={{ display: "flex" }}>
            <TextField
              placeholder={SEARCH}
              fullWidth
              value={inputValue}
              onChange={(e: any) => setInputValue(e.target.value)}
            />
            <Button
              style={{ marginLeft: "1rem" }}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setIsOpenAddFlight(true)}
            >
              {ADD}
            </Button>
          </Box>
          {isLoading && (
            <Box sx={{ position: "relative" }}>
              <CircularProgress
                size="2.5rem"
                color="secondary"
                sx={{ position: "absolute", left: "50%" }}
              />
            </Box>
          )}
          <Box my={1}>
            <TableContainer component={Paper} sx={{ maxHeight: "40vh" }}>
              <Table sx={{ minWidth: 500 }} aria-label="customized table">
                <TableHead sx={{ display: "contents" }}>
                  <TableRow>
                    <StyledTableCell>{NAME}</StyledTableCell>
                    <StyledTableCell>{PATH}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {flightList?.items?.map((item: any) => (
                    <StyledTableRow
                      key={item?.flight_id}
                      onClick={() => {
                        setFlightValue?.(item);
                        callbackFunction?.(item);
                        handleClose();
                      }}
                    >
                      <StyledTableCell sx={{ cursor: "pointer" }}>
                        {item?.flight_code}
                      </StyledTableCell>
                      <StyledTableCell sx={{ cursor: "pointer" }}>
                        <Chip
                          label={item?.flight_origin}
                          color="secondary"
                          sx={{ mr: 1 }}
                          size="small"
                        />
                        <Chip label={item?.flight_destination} color="info" size="small" />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Pagination
            count={Math.ceil(flightList?.count / 10)}
            showFirstButton
            showLastButton
            onChange={handlePaginationChange}
            disabled={isLoading}
          />
          <Divider sx={{ borderWidth: "1px" }} />
          <Grid display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={handleClose}>
              {CANCEL}
            </Button>
          </Grid>
        </Box>
      </Modal>

      <AddFlightDialog open={isOpenAddFlight} onClose={() => setIsOpenAddFlight(false)} />
    </>
  );
};

export default FlightFilter;
