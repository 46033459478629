/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Components

// @mui material components

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Avatar, Card, Grid, IconButton, Pagination } from "@mui/material";
import { useState } from "react";
import FilterReservation, { STATUS } from "components/customs/ReservationFilter";
import { SearchReservationResponseType } from "lib/types/api-type";
import dateHandler, { convertDate } from "utils/dateHandler";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";

// Calendar application components

// Data

function Reports(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SearchReservationResponseType>();
  const [page, setPage] = useState<number>(1);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%)" }}>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ mt: 2.4 }}>
            <FilterReservation
              page={page}
              setPage={setPage}
              updateData={setData}
              loading={loading}
              setLoading={setLoading}
            />
          </Grid>
          <Grid item xs={12} md={9} sx={{ maxWidth: "100%", overflow: "hidden" }}>
            <DataTable
              canSearch={true}
              table={{
                columns: [
                  { Header: "(#)", accessor: "voucher", width: "4%" },
                  { Header: "Created by", accessor: "adder_name", width: "10%" },
                  { Header: "", accessor: "avatar", width: "0" },
                  {
                    Header: "Reseller",
                    accessor: "reseller_name",
                    width: "10%",
                  },
                  { Header: "Date", accessor: "time", width: "6%" },
                  { Header: "Status", accessor: "status", width: "8%" },
                  { Header: "", accessor: "link", width: "4%" },
                ],
                rows:
                  data?.items.map((d) => ({
                    ...d,
                    status: STATUS.find((s) => s.value === d.status)?.title,
                    avatar: <Avatar src={d.adder_avatar} />,
                    time: dateHandler(`${d.time}`),
                    link: (
                      <IconButton>
                        <a
                          href={`https://test.tigroup.com.tr/reservation/${d.id}`}
                          target={"_blank"}
                        >
                          <LinkRoundedIcon />
                        </a>
                      </IconButton>
                    ),
                  })) || [],
              }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent={"center"} sx={{ p: 2 }}>
            <Pagination
              onChange={(e, v) => setPage(v)}
              color={"primary"}
              page={page}
              count={data?.count}
            />
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}

export default Reports;
