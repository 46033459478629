import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from "axios";
import { BASE_URL } from "lib/constants/urls";

export const Axios = axios.create({});
Axios.defaults.baseURL = BASE_URL;

export const setTokenToAxiosHeader = (token: string) => {
  if (token) {
    Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    Axios.defaults.headers.common.Authorization = "";
    delete Axios.defaults.headers.common.Authorization;
  }
};

export const setLocalStorage = (token: string) => {
  localStorage.setItem("token", token);
};

export const setToken = (token: string) => {
  setTokenToAxiosHeader(token);
  setLocalStorage(token);
};

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
export const setupAxios = ({
  axiosInstance,
  token,
  // setLoading,
  errorHandler,
}: {
  axiosInstance: AxiosInstance;
  token: string;
  errorHandler: (error: AxiosError) => void;
}) => {
  setTokenToAxiosHeader(token);

  const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    // console.info(`[request] [${JSON.stringify(config)}]`);
    return config;
  };

  const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    errorHandler(error);
    // console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
  };

  const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    return response;
  };

  const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    // console.error(`[response error] [${JSON.stringify(error)}]`);
    // console.log(error);
    errorHandler(error);
    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
};

// Step-4: Export the newly created Axios instance to be used in different locations.
