import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { TransferType } from "lib/types/api-type";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Table from "../Table";
import Settings from "../Table/Settings";
import useSetting from "../Table/Settings/useSetting";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import {
  createLeaderTable,
  prepareTable,
  flightsPaxHandler,
  transfersPaxHandler,
} from "../Table/utils";
import { PickTimeType } from "..";
import { LeaderType } from "../types";
import usePrint from "./usePrint";
import { Box } from "@mui/material";
import FlightRow from "../Table/row/FlightRow";
import HotelRow from "../Table/row/HotelRow";
import PassengerRow from "../Table/row/PassengerRow";
import LeaderRow from "../Table/row/LeaderRow";
import { defaultStyle, ENDLINE_HEIGHT, TableStyle } from "../Table/row/constant";
import HeaderRow from "../Table/row/HeaderRow";
import EndLine from "../Table/row/EndLine";

const SAVE_TO = "overal";
const LOGO_SAVE_TO = "logo-url";

interface PropsType {
  transfers: TransferType[];
  date: string;
  pickTime: PickTimeType;
}

const Overal = ({ transfers, date, pickTime }: PropsType) => {
  const [leaders, setLeaders] = useState<LeaderType[]>([]);
  const [settings, setSettings] = useState<boolean>(false);
  const [filter, setFilter] = useState<"Arrival" | "Departure" | null>(null);

  const componentRef = useRef(null);

  const updateLeader = useCallback(
    (filter: string) => {
      const newLeaders: LeaderType[] = createLeaderTable(
        prepareTable(transfers || [], pickTime),
        date
      );
      if (filter) {
        const filterLeaders: LeaderType[] = [];
        newLeaders.forEach((leader) => {
          if (leader.side === filter.toLowerCase()) filterLeaders.push(leader);
        });
        setLeaders([...filterLeaders]);
      } else {
        setLeaders([...newLeaders]);
      }
    },
    [transfers, date]
  );

  const setting = useSetting({ s: defaultStyle, saveTo: SAVE_TO, logoSaveTo: LOGO_SAVE_TO });
  const { values } = setting;

  const print = usePrint({ styles: values.styles, leaders });

  const tableStyle = useMemo(
    () => ({ ...TableStyle, ...values?.styles?.tableStyle }),
    [values.styles?.tableStyle, TableStyle]
  );

  useEffect(() => {
    updateLeader(filter);
    print.reset();
  }, [filter]);

  return (
    <MDBox width={"100%"}>
      <MDBox my={1} display={"flex"} justifyContent={"space-between"}>
        <MDBox>
          <MDButton variant="contained" onClick={() => setSettings((v) => !v)}>
            <SettingsRoundedIcon />
          </MDButton>
          <ReactToPrint
            trigger={() => (
              <MDButton variant="contained">
                print
                <PrintRoundedIcon />
              </MDButton>
            )}
            content={() => componentRef.current}
          />
        </MDBox>

        <MDBox maxWidth={"fit-content"}>
          <MDButton
            size="small"
            color={filter === "Departure" ? "primary" : "secondary"}
            variant={filter === "Departure" ? "contained" : "text"}
            onClick={() => {
              setFilter((pre) => (pre === "Departure" ? null : "Departure"));
            }}
          >
            only Departure
          </MDButton>
          <MDButton
            size="small"
            color={filter === "Arrival" ? "primary" : "secondary"}
            variant={filter === "Arrival" ? "contained" : "text"}
            onClick={() => {
              setFilter((pre) => (pre === "Arrival" ? null : "Arrival"));
            }}
          >
            only Arrival
          </MDButton>
        </MDBox>

        <MDBox>
          <MDButton
            varinat={"text"}
            onClick={() => {
              print.data ? print.reset() : print.prepareTable();
            }}
          >
            {print.data ? "reset" : "prepare to print"}
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox>
        {settings ? <Settings {...setting} /> : null}
        <MDBox ref={componentRef}>
          {print.data ? (
            print.data.map((leader, leaderIndex) => (
              <Box key={leaderIndex} sx={{ width: "100%", mx: "auto" }}>
                {leader.pages.map((page, pageIndex) => (
                  <Box py={2} key={pageIndex}>
                    <Box sx={tableStyle}>
                      <LeaderRow
                        leader={pageIndex === 0 ? leader?.leader : ""}
                        totalPax={pageIndex === 0 ? flightsPaxHandler(leader.leader.flights) : 0}
                        styles={values.styles}
                        logo={setting.values.logo}
                        page={page.page}
                      />
                      <HeaderRow
                        {...{
                          hotel: true,
                          voucher: true,
                          flight: true,
                          passenger: true,
                          "flight-time": true,
                          pax: true,
                          "check-in": true,
                          "depar.": leader.leader.side === "departure",
                          "check-out": true,
                          car: true,
                          // "room-type": true,
                          "arr.guide": leader.leader.side === "departure",
                          info: true,
                        }}
                      />
                      {page.row.map((row, rowIndex) => (
                        <Box key={rowIndex}>
                          {row.type === "flight" ? (
                            <FlightRow flight={row} styles={values.styles} />
                          ) : row.type === "hotel" ? (
                            <HotelRow
                              showLeader={false}
                              pickTime={pickTime}
                              {...row}
                              styles={values.styles}
                            />
                          ) : row.type === "passenger" ? (
                            <PassengerRow
                              showDescription={true}
                              showTick={false}
                              passenger={row}
                              styles={values.styles}
                            />
                          ) : row.type === "endLine" ? (
                            <EndLine height={ENDLINE_HEIGHT} />
                          ) : (
                            ""
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            ))
          ) : leaders?.length ? (
            leaders?.map((leader, index) => (
              <MDBox key={index}>
                <Table
                  showDescription={true}
                  showTick={false}
                  totalCount={transfersPaxHandler}
                  page={index + 1}
                  leader={leader}
                  flights={leader.flights}
                  values={values}
                  pickTime={pickTime}
                  showLeader={false}
                  titles={{
                    hotel: true,
                    voucher: true,
                    flight: true,
                    passenger: true,
                    "flight-time": true,
                    pax: true,
                    "check-in": true,
                    "depar.": leader.side === "departure",
                    "check-out": true,
                    car: true,
                    // "room-type": true,
                    "arr.guide": leader.side === "departure",
                    info: true,
                  }}
                />
              </MDBox>
            ))
          ) : (
            <></>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default Overal;
