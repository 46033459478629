export const contactTypeData = [
  {
    label: "CALL",
    id: "1",
    data: [
      { label: "Mobile", id: "mobile" },
      { label: "Phone", id: "phone" },
    ],
  },
  {
    label: "E-MAIL",
    id: "2",
    data: [{ label: "E-mail", id: "mail" }],
  },
  {
    label: "SOCIAL",
    id: "3",
    data: [
      { label: "Instagram", id: "instagram" },
      { label: "Facebook", id: "facebook" },
    ],
  },

  {
    label: "OTHER",
    id: "4",
    data: [{ label: "Other", id: "other" }],
  },
];

export const userTypeData = [
  {
    label: "EXTERNAL",
    id: "1",
    data: [
      { label: "Partners", id: "partner" },
      { label: "Passenger", id: "tourist" },
      { label: "Companies", id: "company" },
    ],
  },

  {
    label: "INTERNAL",
    id: "2",
    data: [
      { label: "Driver", id: "driver" },
      { label: "Reservation Agent", id: "reservation" },
      { label: "Operation Agent", id: "operation" },
      { label: "Support Agent", id: "support" },
      { label: "Leader", id: "leader" },
      { label: "Administrator", id: "admin" },
    ],
  },
];

export const initialPassenger = {
  label: "",
  user_id: "",
  fullname: "",
  company: "",
  user_type: "",
  age_type: "adult",
  sell_price: 0,
  extras: [] as any,
  arrival_flight: "",
  arrival_flight_code: "",
  arrival_flight_origin: "",
  arrival_flight_destination: "",
  arrival_time: "",
  gender: "",
  departure_flight: "",
  departure_flight_code: "",
  departure_flight_origin: "",
  departure_flight_destination: "",
  departure_time: "",
  arrival_transfer: false,
  departure_transfer: false,
  arrival_transfer_vip: false,
  departure_transfer_vip: false,
  arrival_transfer_price: 0,
  departure_transfer_price: 0,
  transferPriceCurrency: 2,
  transferPriceCurrencyCode: "USD",
};
