export const CHECK_IN = "Check-in";
export const CHECK_OUT = "Check-out";
export const IN_HOUSE = "In House";
export const RESERVATIONS = "Reservations";
export const RESELLER_HOTEL = "Reseller/Hotel";
export const ROOMS = "Rooms";
export const STATUS = "Status";
export const TRANSFER = "Transfer";
export const UPDATE = "Update";
export const ARRIVAL_FLIGHT = "Arrival Flight";
export const DEPARTURE_FLIGHT = "Departure Flight";
export const NO_TRANSFER = "No Transfer";
export const CLOSE = "Close";
export const SELL_EUR = "Sell EUR";
export const SELL_USD = "Sell USD";
export const SELL_TL = "Sell TL";
export const BUY_EUR = "Buy EUR";
export const BUY_USD = "Buy USD";
export const BUY_TL = "Buy TL";
export const SINGLE = "Single";
export const DOUBLE = "Double";
export const FAMILY = "Family";
export const SUITE = "Suite";
export const FINANCE_STATISTICS = "Finance Statistics";
export const ROOM_STATISTICS = "Room Statistics";
export const TOTAL_PAX = "Total Pax";
export const TRIPLE = "Triple";
export const TOTAL = "Total";
export const TOTAL_NIGHTS = "Total Nights";
