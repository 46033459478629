import {
  Grid,
  TextField,
  Autocomplete as MuiAutocomplete,
  Select,
  MenuItem,
  SxProps,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import Post, { SearchPasssengersFormType } from "lib/api/post";

import { useCallback, useEffect, useState } from "react";
import { SELECT_USER } from "store/slices/profile";
import { convertDate } from "utils/dateHandler";
import Autocomplete, { AutoCompleteType } from "../core/AutoComplete";
import { REPORT_FOR, SIDES } from "./constants";
import { ResponseReportsType } from "layouts/pages/dashboard/reservation/reports";

export const STATUS: AutoCompleteType[] = [
  { title: "All", value: null },
  { title: "Pending", value: 0 },
  { title: "Confirmed", value: 1 },
  { title: "Canceled", value: 2 },
  { title: "No Show", value: 3 },
];

interface FilterType {
  report_for: number;
  side: number;
  from_date: Date; //can be null
  to_date: Date; //can be null
  car_id: AutoCompleteType;
  leader_id: AutoCompleteType;
  flight_id: AutoCompleteType; //can be null
}

interface PropsType {
  loading: boolean;
  updateData: (data: ResponseReportsType) => void;
  setLoading: (value: boolean) => void;
}

const ReservationFilter = ({ loading, updateData, setLoading }: PropsType) => {
  const user = SELECT_USER();
  const initialFilter: FilterType = {
    report_for: REPORT_FOR[0].id,
    side: SIDES[0].id,
    from_date: null,
    to_date: null,
    leader_id: { title: "", value: "" },
    flight_id: { title: "", value: "" },
    car_id: { title: "", value: "" },
  };
  const [filter, setFilter] = useState<FilterType>(initialFilter);
  const [cars, setCars] = useState<AutoCompleteType[]>([]);
  const [leaders, setLeaders] = useState<AutoCompleteType[]>([]);

  const updateFilter = useCallback((key: string, value: any) => {
    setFilter((pre) => ({ ...pre, [key]: value }));
  }, []);

  const searchFlights = (key: string) => {
    const form: SearchPasssengersFormType = {
      session_id: user.session_id,
      user_id: user.uid,
      count: 30,
      key: key,
      page: 1,
    };

    return Post.searchFlight(form).then((res) => {
      const users: { flight_code: string; flight_id: number }[] = res.data.items;
      return users.map((u) => ({ title: u.flight_code, value: u.flight_id }));
    });
  };

  const getCarsAndLeaders = () => {
    Post.loadCarLeader({ session_id: user.session_id, user_id: user.uid })
      .then((res) => {
        const { data } = res as { data: LoadCarLeadResult };
        setCars(
          (data.cars?.map((c) => ({
            value: c.car_id,
            title: `${c.car_code} - ${c.car_type}`,
          })) as AutoCompleteType[]) || ([] as AutoCompleteType[])
        );
        setLeaders(
          (data.leaders?.map((l) => ({
            value: l.user_id,
            title: l.fullname,
          })) as AutoCompleteType[]) || ([] as AutoCompleteType[])
        );
      })
      .catch(console.log);
  };

  // SearchFlightFormType

  const searchHandler = () => {
    const newFilter = prepareData(filter);

    setLoading(true);
    const data: ResponseReportsType = { car_count: 0, flight_count: 0, passenger_count: 0 };
    updateData(data);

    Post.oprationReport({ ...newFilter, session_id: user.session_id, user_id: user.uid })
      .then((res) => {
        const data: ResponseReportsType = res.data;
        updateData(data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCarsAndLeaders();
  }, []);

  const isSearchForLeader = filter.report_for === 1;

  useEffect(() => {
    if (isSearchForLeader) {
      updateFilter("leader_id", null);
    } else {
      updateFilter("car_id", null);
    }
  }, [filter.report_for]);

  const isValid = isSearchForLeader ? filter.leader_id : filter.car_id;

  return (
    <>
      <Grid spacing={1} container sx={{ p: 1 }}>
        <Grid item xs={12}>
          <Select
            sx={SELECT_STYLE}
            fullWidth
            value={filter.report_for}
            placeholder="report for"
            label="Report for"
            onChange={(e) => updateFilter("report_for", e.target.value)}
          >
            {REPORT_FOR.map((r) => (
              <MenuItem key={r.id} value={r.id}>
                <em>{r.name}</em>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Select
            sx={SELECT_STYLE}
            fullWidth
            value={filter.side}
            placeholder="side"
            label="side"
            onChange={(e) => updateFilter("side", e.target.value)}
          >
            {SIDES.map((r) => (
              <MenuItem key={r.id} value={r.id}>
                <em>{r.name}</em>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <MDDatePicker
            label={"Date"}
            value={[filter.from_date || "", filter.to_date || ""]}
            input={{ placeholder: "Date", sx: { width: "100%" } }}
            options={{ mode: "range" }}
            onChange={(e: any) => {
              updateFilter("from_date", e[0]);
              updateFilter("to_date", e[1]);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            value={filter.flight_id}
            onChange={(e) => updateFilter("flight_id", e)}
            label={"Flight"}
            getData={searchFlights}
          />
        </Grid>
        <Grid item xs={12}>
          {isSearchForLeader ? (
            <MuiAutocomplete
              placeholder="select leader"
              getOptionLabel={(o) => o.title}
              value={filter.leader_id}
              onChange={(e, value) => updateFilter("leader_id", value)}
              options={leaders}
              renderInput={(params) => (
                <TextField label={"Leader"} placeholder="select Leader" {...params} />
              )}
            />
          ) : (
            <MuiAutocomplete
              placeholder="select car"
              getOptionLabel={(o) => o.title}
              value={filter.car_id}
              onChange={(e, value) => updateFilter("car_id", value)}
              options={cars}
              renderInput={(params) => (
                <TextField label={"Car"} placeholder="select a car" {...params} />
              )}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <MDButton
            disabled={isValid ? loading : true}
            color={"primary"}
            variant={"contained"}
            onClick={searchHandler}
            fullWidth
          >
            search
          </MDButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ReservationFilter;

const SELECT_STYLE: SxProps = {
  "&.Mui-focused legend": { visibility: "initial!important", height: 20 },
  height: "44px",
  "& input": { height: "50px" },
  "& legend": {
    transition: ".3s",
    visibility: "initial!important",
    height: 20,
  },
  "& legend span": { background: "#fff", transition: ".3s" },
};

const prepareData = (filter: FilterType) => {
  const newFilter: {
    report_for: number;
    side: number;
    from_date: string | null;
    to_date: string | null;
    car_id: number;
    leader_id: number;
    flight_id: number | null;
  } = {
    //

    report_for: filter?.report_for,
    side: filter?.side,
    from_date: convertDate(filter?.from_date) || null,
    to_date: convertDate(filter?.to_date) || null,
    car_id: filter?.car_id?.value,
    leader_id: filter?.leader_id?.value,
    flight_id: filter?.flight_id?.value || null,
  };
  return newFilter;
};

interface CarType {
  car_id: number;
  car_code: string;
  car_type: number;
}

interface LeaderType {
  fullname: string;
  user_id: number;
  company: string;
}

interface LoadCarLeadResult {
  cars: CarType[];
  leaders: LeaderType[];
}
