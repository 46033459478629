import React, { useEffect, useState } from "react";
import { CANCEL, DESCRIPTION, HOTELS, NAME, SEARCH, SELECT_HOTEL, SUBMIT } from "../const";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilterAlt from "@mui/icons-material/FilterAlt";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import { Clear } from "@mui/icons-material";
import { MODAL_STYLE } from "../../style";
import { useFormContext } from "../../FormContextProvide";

interface HotelFilterType {
  setFilerHotelValue: (T: any) => typeof T;
  filerHotelValue: any;
}

const HotelFilter = ({ setFilerHotelValue, filerHotelValue }: HotelFilterType) => {
  const { formContext } = useFormContext();

  const [open, setOpen] = useState(false);
  const [hotelList, setHotelList] = useState(null);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInputValue("");
    setSelectedList(filerHotelValue);
  };
  const handleSubmit = () => {
    setFilerHotelValue(selectedList);
    setOpen(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "gray",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const user = SELECT_USER();
  useEffect(() => {
    setIsLoading(true);
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      type_level: 0,
      key: inputValue,
      page: page,
      count: 10,
    };
    Post.hotelList(payload)
      .then((res) => {
        setHotelList(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [inputValue, page, user?.session_id, user?.uid]);
  useEffect(() => {
    filerHotelValue?.length !== 0 &&
      filerHotelValue?.map((hotelsId: any) => {
        const containsObj = selectedList.includes(hotelsId);
        !containsObj && setSelectedList((prev) => [...prev, hotelsId]);
      });
  }, [filerHotelValue]);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleDelete = (item: any) => {
    const filteredArray = selectedList?.filter((obj) => obj.hotel_id !== item.hotel_id);
    setSelectedList(filteredArray);
    setFilerHotelValue(filteredArray);
  };
  useEffect(() => {
    if (!formContext?.hotels) {
      setSelectedList([]);
      setFilerHotelValue([])
    }
  }, [formContext]);
  return (
    <>
      <Grid item display="flex" py={2} flexWrap="wrap">
        <Typography onClick={handleOpen} sx={{ cursor: "pointer" }}>
          {HOTELS}
        </Typography>
        <Box ml={0.5} onClick={handleOpen} sx={{ cursor: "pointer" }}>
          <FilterAlt />
        </Box>

        {selectedList.length > 0 &&
          selectedList?.map((item, key: number) => (
            <Box sx={{ display: "flex" }} mx={1} key={key}>
              <Typography fontSize="14px" ml={2} mt={0.8}>
                {item?.fullname}
              </Typography>
              <Box ml={0.5} sx={{ cursor: "pointer" }} onClick={() => handleDelete(item)}>
                <Clear color="error" />
              </Box>
            </Box>
          ))}
      </Grid>
      <Modal open={open} onClose={handleClose}>
        <Box sx={MODAL_STYLE}>
          <Typography>{SELECT_HOTEL}</Typography>
          <Divider sx={{ borderWidth: "1px" }} />

          <TextField
            placeholder={SEARCH}
            fullWidth
            value={inputValue}
            onChange={(e: any) => setInputValue(e.target.value)}
          />
          <Box>
            {selectedList?.map((item, key: number) => (
              <Chip
                key={key}
                label={item.fullname}
                variant="outlined"
                color="error"
                onDelete={() => handleDelete(item)}
              />
            ))}
          </Box>
          {isLoading && (
            <Box sx={{ position: "relative" }}>
              <CircularProgress
                size="2.5rem"
                color="secondary"
                sx={{ position: "absolute", left: "50%" }}
              />
            </Box>
          )}
          <Box my={1}>
            <TableContainer component={Paper} sx={{ maxHeight: "40vh" }}>
              <Table sx={{ minWidth: 500 }} aria-label="customized table" stickyHeader>
                <TableHead sx={{ display: "contents" }}>
                  <TableRow>
                    <StyledTableCell>{NAME}</StyledTableCell>
                    <StyledTableCell>{DESCRIPTION}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hotelList?.items?.map((hotel: any) => (
                    <StyledTableRow
                      key={hotel?.hotel_id}
                      onClick={() => {
                        const listID: { hotel_id: number }[] = [];
                        selectedList.forEach((item: any) => listID.push(item.hotel_id));
                        const containsObj = listID.includes(hotel?.hotel_id);
                        !containsObj && setSelectedList([...selectedList, hotel]);
                      }}
                    >
                      <StyledTableCell sx={{ cursor: "pointer" }}>
                        {hotel?.fullname}
                      </StyledTableCell>
                      <StyledTableCell>
                        {hotel?.district_name} - {hotel?.state_name}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Pagination
            count={Math.ceil(hotelList?.count / 10)}
            showFirstButton
            showLastButton
            onChange={handlePaginationChange}
            disabled={isLoading}
          />
          <Divider sx={{ borderWidth: "1px" }} />
          <Grid display="flex" justifyContent="flex-end">
            <Box sx={{ mr: 2 }}>
              <Button variant="contained" color="secondary" onClick={handleClose}>
                {CANCEL}
              </Button>
            </Box>
            <Box>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                {SUBMIT}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default HotelFilter;
