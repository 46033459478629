import * as yup from "yup";
import { Box, CircularProgress, Dialog, DialogContent, Grid, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import Post, { HotelTypeFormType } from "lib/api/post";
import { useState } from "react";
import { SELECT_USER } from "store/slices/profile";

interface PropsType {
  typeAdded: (v: any) => void;
}

const validationSchema = () =>
  yup.object().shape({
    title: yup.string().required("required"),
    order: yup.number().required("required"),
    type_code: yup.string().required("required"),
  });

const AddHotelType = ({ typeAdded }: PropsType) => {
  const user = SELECT_USER();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue } =
    useFormik<HotelTypeFormType>({
      validationSchema,
      initialValues: {
        order: "",
        title: "",
        type_code: "",
        type_level: 0,
      },
      onSubmit: (values) => {
        setLoading(true);
        const newValue: any = { ...values, user_id: user.uid, session_id: user.session_id };
        Post.hotelType(newValue)
          .then((res) => {
            setLoading(false);
            typeAdded(res.data);
            setOpen(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      },
    });

  return (
    <>
      <MDButton onClick={() => setOpen(true)} color={"info"}>
        + add hotel type
      </MDButton>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="title"
                      value={values.title}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && errors.title ? true : false}
                      helperText={touched.title && errors.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="type code"
                      value={values.type_code}
                      name="type_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.type_code && errors.type_code ? true : false}
                      helperText={touched.type_code && errors.type_code && errors.type_code}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="order"
                      value={values.order}
                      name="order"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.order && errors.order ? true : false}
                      helperText={touched.order && errors.order && errors.order}
                    />
                  </Grid>
                  <Grid item>
                    <MDButton loading={loading} color={"dark"} type={"submit"}>
                      {(loading && <CircularProgress size="14px" color="primary" />) || (
                        <div>+ add hotel type</div>
                      )}
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="outlined"
                      disabled={loading}
                      color={"error"}
                      onClick={() => setOpen(false)}
                    >
                      cancel
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddHotelType;
