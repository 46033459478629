import { SxProps } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { PassengerType } from "lib/types/api-type";
import { memo, useMemo } from "react";
import {
  IS_VIP_PASSENGER,
  SELECT_ARRIVAL_LEADER,
  SELECT_DESCRIPTION,
  SELECT_VOUCHER,
} from "store/slices/operations";
import { TableStyleType } from "../../types";
import { passengersStyle, passengerStyle, textStyle } from "../constant";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import useStyles from "components/customs/core/useStyles";
import dateHandler from "utils/dateHandler";
interface PropsType {
  passenger: PassengerType;
  styles: TableStyleType;
  showTick: boolean;
  showDescription: boolean;
}

const PassengerRow = ({ passenger, styles, showTick, showDescription }: PropsType) => {
  const d = SELECT_DESCRIPTION(passenger);
  const classes = useStyles({ linesToShow: 1 });
  const ps: SxProps = useMemo(
    () => ({ ...passengersStyle, ...styles?.passengersStyle }),
    [styles?.passengersStyle]
  );
  const p: SxProps = useMemo(
    () => ({ ...passengerStyle, ...styles?.passengerStyle }),
    [styles?.passengerStyle]
  );
  const t3: SxProps = useMemo(
    () => ({ ...textStyle, ...styles?.passengerTextStyle }),
    [styles?.passengerTextStyle]
  );
  const voucher = SELECT_VOUCHER(passenger.voucher);
  const isVip = IS_VIP_PASSENGER(passenger);

  const arrivalLeader = SELECT_ARRIVAL_LEADER(passenger);

  return (
    <MDBox sx={ps}>
      <MDBox sx={{ ...p }}>
        {d?.description && showTick ? <CheckRoundedIcon fontSize={"small"} /> : ""}
      </MDBox>
      <MDBox sx={{ ...p }}>
        <MDTypography sx={t3} className={classes.multiLineEllipsis}>
          {passenger?.voucher}
        </MDTypography>
      </MDBox>
      <MDBox sx={{ ...p, gridColumn: "3/6" }}>
        <MDTypography sx={t3} className={classes.multiLineEllipsis}>
          {passenger?.fullname}
        </MDTypography>
      </MDBox>
      <MDBox sx={{ ...p, gridColumn: "7/8" }}>
        <MDTypography sx={t3} className={classes.multiLineEllipsis}>
          {dateHandler(voucher?.check_in)}
        </MDTypography>
      </MDBox>
      <MDBox sx={{ ...p, gridColumn: "8/9" }}>
        <MDTypography sx={t3} className={classes.multiLineEllipsis}>
          {dateHandler(voucher?.check_out)}
        </MDTypography>
      </MDBox>

      <MDBox sx={{ ...p, gridColumn: "11/12" }}>
        <MDTypography sx={t3} className={classes.multiLineEllipsis}>
          {voucher?.type_code ? `room-type: ${voucher?.type_code}` : null}
        </MDTypography>
      </MDBox>

      <MDBox sx={{ ...p, gridColumn: "12/13" }}>
        <MDTypography sx={t3} className={classes.multiLineEllipsis}>
          {arrivalLeader?.leader_name}
        </MDTypography>
      </MDBox>

      <MDBox sx={{ ...p, gridColumn: "13/15", textAlign: "right" }}>
        <MDTypography sx={t3} className={classes.multiLineEllipsis}>
          {isVip ? "VIP" : null}
          {d?.description && showDescription ? `${isVip ? "- " : ""} ${d.description}` : ""}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default memo(PassengerRow);
