import { Dialog, DialogContent, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Post, { VoucherDescriptionType } from "lib/api/post";
import { PassengerType, SideType } from "lib/types/api-type";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DescriptionType, SELECT_DESCRIPTION, updateDescription } from "store/slices/operations";
import { SELECT_USER } from "store/slices/profile";

interface PropsType {
  side: SideType;
  passenger: PassengerType | null;
  date: string;
  onClose: (value: null) => void;
}

const GetVoucherDescription = ({ date, side, passenger, onClose }: PropsType) => {
  const [description, setDescription] = useState<string>("");
  const storeDescription = SELECT_DESCRIPTION(passenger);

  useEffect(() => {
    setDescription(storeDescription?.description);
  }, [storeDescription?.description]);

  const [loading, setLoading] = useState<boolean>(false);
  const admin = SELECT_USER();
  const dispatch = useDispatch();
  const confirm = () => {
    if (!description && !passenger.voucher) return;
    const form: VoucherDescriptionType = {
      user_id: admin.uid,
      session_id: admin.session_id,
      voucher_id: passenger.voucher,
      desc_user_id: passenger.user_id,
      date,
      description,
      side,
    };
    Post.VoucherDescription(form)
      .then((res) => {
        const newDescription: DescriptionType = {
          id: storeDescription?.id || -1,
          voucher_id: passenger.voucher,
          desc_user_id: passenger.user_id,
          date,
          description,
        };
        dispatch(updateDescription(newDescription));
        onClose(null);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={!!passenger?.voucher} onClose={() => !loading && onClose(null)} fullWidth maxWidth={"sm"}>
      <DialogContent>
        <MDBox mb={2}>
          <TextField
            fullWidth
            value={description || ""}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </MDBox>
        <MDButton fullWidth color="primary" onClick={() => !loading && confirm()}>
          confirm
        </MDButton>
      </DialogContent>
    </Dialog>
  );
};

export default GetVoucherDescription;
