import { AddEndlineType, AddRowType } from "./types";

export const addRow: AddRowType = (
  pageData,
  pageHeight,
  row,
  rowHeight,
  flightRow,
  flightHeight
) => {
  const isFlightRow = row.type === "flight";
  let { table, adds, index, page } = { ...pageData };
  if (adds + rowHeight < pageHeight) {
    adds = adds + rowHeight;
    table.pages[index].row.push(row);
  } else {
    adds = isFlightRow ? rowHeight : rowHeight + flightHeight;
    index++;
    page++;
    table.pages.push({ page, row: isFlightRow ? [row] : [flightRow, row] });
  }
  return { table, page, index, adds };
};

export const addEndline: AddEndlineType = (
  PageDataType,
  pageHeight,
  endLineHeight,
  flightHeight,
  hotelHeight,
  passengerHeight
) => {
  const newPageDataType = { ...PageDataType };
  if (!newPageDataType.table.pages.length) return newPageDataType;
  const lastPage = newPageDataType.table.pages[newPageDataType.table.pages.length - 1];

  let adds = 0;
  lastPage.row.forEach((row) => {
    switch (row.type) {
      case "flight":
        adds += flightHeight;
        break;
      case "hotel":
        adds += hotelHeight;
        break;
      case "passenger":
        adds += passengerHeight;
        break;
      default:
        break;
    }
  });

  if (adds + endLineHeight <= pageHeight) {
    lastPage.row.push({ type: "endLine" });
    newPageDataType.table.pages[newPageDataType.table.pages.length - 1] = lastPage;
  }
  return newPageDataType;
};
