import { SxProps } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { memo, useMemo } from "react";
import { FlightPrintType, TableStyleType } from "../../types";
import { transfersPaxHandler } from "../../utils";
import { columnStyle, rowStyle, textStyle } from "../constant";

interface PropsType {
  time?: string;
  flight: FlightPrintType;
  styles: TableStyleType;
}

const FllightRow = ({ styles, flight, time }: PropsType) => {
  const r: SxProps = useMemo(() => ({ ...rowStyle, ...styles?.rowStyle }), [styles?.rowStyle]);

  const c1: SxProps = useMemo(
    () => ({ ...columnStyle, ...styles?.columnStyle }),
    [styles?.columnStyle]
  );
  const t1: SxProps = useMemo(() => ({ ...textStyle, ...styles?.textStyle }), [styles?.textStyle]);

  return (
    <MDBox sx={r}>
      <MDBox sx={{ ...c1, gridColumn: "1/5" }}>
        <MDTypography sx={t1}>
          {flight.flight_code}({`${flight.flight_origin} to ${flight.flight_destination}`})
        </MDTypography>
      </MDBox>
      <MDBox sx={c1}>
        <MDTypography sx={t1}>{time ? time : flight.flight_time}</MDTypography>
      </MDBox>
      <MDBox sx={c1}>
        <MDTypography sx={t1}>{flight?.transfers && transfersPaxHandler(flight.transfers)}</MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default memo(FllightRow);
