import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Box, Dialog, DialogContent, Divider, Grid, Icon } from "@mui/material";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { SELECT_USER } from "store/slices/profile";
import Post from "lib/api/post";
import DataTable from "examples/Tables/DataTable";
import AddRoomType from "./AddRoomType";

interface PropsType {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type_level: number;
  roomTypeSelected: (v: any) => void;
}

interface HotelTypes {
  count: number;
  page: number;
  per_page: number;
  items: [];
}

interface HotelTypesItem {
  type_id: number;
  type_level: number;
  type_code: string;
  title: string;
  order: number;
  active: number;
}

const SelectRoomType = ({ open, setOpen, type_level, roomTypeSelected }: PropsType) => {
  const user = SELECT_USER();
  const [_searched_value, _set_searched_value] = useState<string>("");
  const [_room_types, set_room_types] = useState<HotelTypes>();
  const [open_add_room_type, set_open_add_room_type] = useState<boolean>(false);

  const { handleSubmit } = useFormik({
    initialValues: {
      type_level: type_level,
      key: "",
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    Post.searchHotelType(
      user.session_id,
      user.uid
      //  _searched_value, type_level
    ).then((res) => {
      set_room_types(res.data);
    });
  }, [_searched_value, user.session_id, user.uid, type_level]);

  const handleSelectRoomType = useCallback(
    (item: any) => {
      roomTypeSelected(item);
      setOpen(false);
    },
    [roomTypeSelected, setOpen]
  );

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box component={"form"} onSubmit={handleSubmit}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <MDTypography variant="h5" fontWeight="bold">
                      {type_level === 1
                        ? `Select Room Type`
                        : type_level === 2
                        ? `Select Room Sub-Type`
                        : null}
                    </MDTypography>
                    <Divider orientation="horizontal" flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={9}>
                        <MDInput
                          sx={{ width: "100%" }}
                          label="Search"
                          value={_searched_value}
                          onChange={(e: { target: { value: string } }) =>
                            _set_searched_value(e.target.value)
                          }
                        ></MDInput>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container justifyContent="flex-end">
                          <MDButton
                            size="small"
                            variant="gradient"
                            color="info"
                            onClick={() => set_open_add_room_type(true)}
                          >
                            <Icon>add</Icon>
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <DataTable
                      isSorted={false}
                      pagination={{
                        variant: "contained",
                        color: "primary",
                      }}
                      table={{
                        columns: [
                          {
                            Header: (
                              <MDTypography variant="h6" fontWeight="bold">
                                Name
                              </MDTypography>
                            ),
                            accessor: "name",
                            width: "5%",
                          },
                        ],
                        rows:
                          _room_types && _room_types.items
                            ? _room_types.items.map((item: HotelTypesItem) => {
                                const TableObj = {
                                  name: (
                                    <>
                                      <MDTypography
                                        variant="inherit"
                                        color="text"
                                        fontWeight="bold"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => handleSelectRoomType(item)}
                                        type={"submit"}
                                      >
                                        {item.title}
                                      </MDTypography>
                                    </>
                                  ),
                                };
                                return TableObj;
                              })
                            : [],
                      }}
                      entriesPerPage={false}
                      showTotalEntries={false}
                    />
                  </Grid>
                  <Grid item>
                    <MDButton variant="outlined" color={"error"} onClick={() => setOpen(false)}>
                      cancel
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {open_add_room_type ? (
        <Grid item xs={12}>
          <AddRoomType
            open={open_add_room_type}
            setOpen={set_open_add_room_type}
            type_level={type_level}
          />
        </Grid>
      ) : null}
    </>
  );
};

export default SelectRoomType;
