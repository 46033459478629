import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { TransferType } from "lib/types/api-type";
import { useEffect, useMemo, useRef, useState } from "react";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import ReactToPrint from "react-to-print";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Table from "../Table";
import MDButton from "components/MDButton";
import Settings from "../Table/Settings";
import useSetting from "../Table/Settings/useSetting";
import { FlightPrintType } from "../Table/types";
import { prepareTable, flightsPaxHandler } from "../Table/utils";
import { PickTimeType } from "..";
import { defaultStyle, ENDLINE_HEIGHT, TableStyle } from "../Table/row/constant";
import { usePrint } from "./usePrint";
import FlightRow from "../Table/row/FlightRow";
import PassengerRow from "../Table/row/PassengerRow";
import HotelRow from "../Table/row/HotelRow";
import LeaderRow from "../Table/row/LeaderRow";
import HeaderRow from "../Table/row/HeaderRow";
import EndLine from "../Table/row/EndLine";

export type TabType = "arrival" | "departure";

interface PropsType {
  tab: TabType;
  arrivals: TransferType[];
  departures: TransferType[];
  date: string;
  pickTime: PickTimeType;
}

const SAVE_TO = "arival-departure";

interface FilterType {
  name: string;
  id: string;
  type: "leader_id" | "driver_id" | "car_id";
}
interface FiltersType {
  leaders: FilterType[];
  drivers: FilterType[];
  cars: FilterType[];
}

const LOGO_SAVE_TO = "ARRIVAL_AND_DEPARTURE";

const ArrivalAndDeparture = ({ tab, arrivals, date, departures, pickTime }: PropsType) => {
  const [ar, setAr] = useState<FlightPrintType[]>([]);
  const [depa, setDepa] = useState<FlightPrintType[]>([]);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersType>();
  const [filter, setFilter] = useState<FilterType>();

  const generateFilters = (flights: FlightPrintType[]) => {
    let newFilter: FiltersType = { leaders: [], drivers: [], cars: [] };
    let index = -1;
    flights.forEach((flight) => {
      for (const transfer of flight.transfers) {
        index = newFilter?.leaders?.findIndex((l) => l.id === transfer.leader_id);
        if (index === -1) {
          newFilter.leaders.push({
            name: transfer.leader_name,
            id: transfer.leader_id,
            type: "leader_id",
          });
        }
        index = newFilter.drivers?.findIndex((d) => d.id === transfer.driver_id);
        if (index === -1) {
          newFilter.drivers.push({
            id: transfer.driver_id,
            name: transfer.driver_name,
            type: "driver_id",
          });
        }
        index = newFilter.cars?.findIndex((c) => c.id === transfer.car_id);
        if (index === -1) {
          newFilter.cars.push({ name: transfer.car_id, id: transfer.car_id, type: "car_id" });
        }
      }
    });

    setFilters(() => ({ ...newFilter }));
  };

  const componentRef = useRef(null);

  const setting = useSetting({
    s: defaultStyle,
    saveTo: SAVE_TO,
    logoSaveTo: LOGO_SAVE_TO,
  });

  const { values } = setting;
  const tableStyle = useMemo(
    () => ({ ...TableStyle, ...values?.styles?.tableStyle }),
    [values.styles?.tableStyle, TableStyle]
  );

  const print = usePrint({ styles: values.styles, flies: tab === "arrival" ? ar : depa });

  useEffect(() => {
    if (print.data) {
      print.reset();
    }
    if (tab === "arrival") {
      const a = [...prepareTable(arrivals, pickTime)];
      setAr(() => a);
      if (!a.length) return;
      if (filter) {
        const newData: FlightPrintType[] = [];
        a.forEach((f) => {
          for (const t of f?.transfers) {
            if (t[filter?.type] === filter.id) {
              newData.push(f);
              break;
            }
          }
        });
        setAr(newData);
      } else {
      }
    } else if (tab === "departure") {
      const d = [...prepareTable(departures, pickTime)];
      setDepa(() => d);
      if (!d.length) return;
      if (filter) {
        const newData: FlightPrintType[] = [];
        d.forEach((f) => {
          for (const t of f?.transfers) {
            if (t[filter.type] === filter.id) {
              newData.push(f);
              break;
            }
          }
        });
        setDepa(newData);
      } else {
      }
    }
  }, [filter]);

  useEffect(() => {
    if (arrivals.length) {
      setAr([...prepareTable(arrivals, pickTime)]);
    }
    if (departures.length) {
      setDepa([...prepareTable(departures, pickTime)]);
    }
  }, [arrivals, departures]);

  useEffect(() => {
    print.reset();
    setFilter(() => null);
  }, [tab]);

  useEffect(() => {
    if (tab === "arrival" || tab === "departure") {
      generateFilters(tab === "arrival" ? ar : depa);
    }
  }, [tab, ar, depa]);

  return (
    <MDBox>
      <MDBox>
        <MDBox display="flex" gap={1} px={4}>
          <MDButton variant="contained" onClick={() => setShowSettings((v) => !v)}>
            <SettingsRoundedIcon />
          </MDButton>
          <Grid>{showSettings ? <Settings {...setting} /> : null}</Grid>
          <ReactToPrint
            trigger={() => (
              <MDButton variant="contained">
                print
                <PrintRoundedIcon />
              </MDButton>
            )}
            content={() => componentRef.current}
          />

          <MDButton
            varinat={"text"}
            onClick={() => {
              print.data ? print.reset() : print.prepareTable(tab === "arrival" ? ar : depa);
            }}
            sx={{ ml: "auto" }}
          >
            {print.data ? "reset" : "prepare to print"}
          </MDButton>
        </MDBox>
        <MDBox
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          flexWrap={"wrap"}
          maxWidth="100%"
          px={3}
          justifyContent="center"
          margin="auto"
        >
          <Filters>
            leaders:
            {filters?.leaders?.map((leader, index) => (
              <Filter key={index} filter={leader} value={filter} setFilter={setFilter} />
            ))}
          </Filters>
          <Filters>
            drivers:
            {filters?.drivers?.map((driver, index) => (
              <Filter key={index} filter={driver} value={filter} setFilter={setFilter} />
            ))}
          </Filters>
          <Filters>
            cars:
            {filters?.cars?.map((car, index) => (
              <Filter key={index} filter={car} value={filter} setFilter={setFilter} />
            ))}
          </Filters>
        </MDBox>
      </MDBox>

      {/*  */}
      {/*  */}
      {/*  */}

      <MDBox ref={componentRef}>
        {print?.data?.length ? (
          <>
            {print.data.map((page, pageIndex) => (
              <MDBox py={2}>
                <MDBox key={pageIndex} sx={tableStyle}>
                  <LeaderRow
                    totalPax={
                      pageIndex === 0 ? flightsPaxHandler(tab === "arrival" ? ar : depa) : 0
                    }
                    leader={{
                      leader_name: tab,
                      side: filter?.type
                        ? `
                        date:${date},
                        ${
                          filter?.type === "leader_id"
                            ? "leader"
                            : filter?.type === "car_id"
                            ? "car"
                            : filter?.type === "driver_id"
                            ? "driver"
                            : ""
                        }: ${filter.name}`
                        : `date:${date}`,
                    }}
                    styles={{
                      ...values.styles,
                      titleStyle: { ...values.styles.titleStyle, "& .options": { fontSize: 14 } },
                    }}
                    logo={values.logo}
                    page={pageIndex + 1}
                  />
                  <HeaderRow
                    {...{
                      hotel: true,
                      flight: true,
                      "flight-time": true,
                      pax: true,
                      "depar.": tab === "departure",
                      leader: true,
                      car: true,
                      // "arr.guide": tab === "departure",
                    }}
                  />
                  {page.map((row, rowIndex) => (
                    <MDBox key={rowIndex + pageIndex}>
                      {row.type === "flight" ? (
                        <FlightRow flight={row} styles={{ ...values.styles }} />
                      ) : row.type === "hotel" ? (
                        <HotelRow
                          flight={row.flight}
                          transfer={row.transfer}
                          pax={row.pax}
                          styles={values.styles}
                          pickTime={pickTime}
                          showLeader={true}
                        />
                      ) : row.type === "passenger" ? (
                        <PassengerRow
                          showDescription={true}
                          showTick={false}
                          passenger={row}
                          styles={values.styles}
                        />
                      ) : row.type === "endLine" ? (
                        <EndLine height={ENDLINE_HEIGHT} />
                      ) : null}
                    </MDBox>
                  ))}
                </MDBox>
              </MDBox>
            ))}
          </>
        ) : /*













          
          */
        tab === "arrival" ? (
          <Table
            showDescription={true}
            showTick={false}
            flights={ar}
            values={values}
            pickTime={pickTime}
            showLeader={true}
            titles={{
              hotel: true,
              flight: true,
              "flight-time": true,
              pax: true,
              "depar.": false,
              leader: true,
              car: true,
            }}
          />
        ) : (
          <Table
            showDescription={true}
            showTick={false}
            flights={depa}
            values={values}
            pickTime={pickTime}
            showLeader={true}
            titles={{
              hotel: true,
              flight: true,
              "flight-time": true,
              pax: true,
              "depar.": true,
              leader: true,
              car: true,
              "arr.guide": true,
            }}
          />
        )}
      </MDBox>
    </MDBox>
  );
};

export default ArrivalAndDeparture;

const Filters = ({ children }: { children: React.ReactNode }) => {
  return (
    <MDBox
      fontSize="16px"
      maxWidth={"fit-content"}
      fontWeight={"bold"}
      alignItems="center"
      display={"flex"}
      gap={0.2}
    >
      {children}
    </MDBox>
  );
};

const Filter = ({
  filter,
  value,
  setFilter,
}: {
  filter: FilterType;
  value: FilterType;
  setFilter: (v: FilterType) => void;
}) => {
  const active = filter?.id === value?.id;
  return (
    <MDButton
      variant="text"
      color={active ? "primary" : "secondary"}
      onClick={() => (active ? setFilter(null) : setFilter(filter))}
      sx={{ minWidth: "20px", padding: "8px" }}
    >
      {filter.name}
    </MDButton>
  );
};
