import { makeStyles } from '@material-ui/core/styles';

interface PropsType {
  linesToShow?: number;
}

export const multiLineEllipsis = (linesToShow: number) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': linesToShow,
  '-webkit-box-orient': 'vertical',
});

const useStyles = ({ linesToShow = 1 }: PropsType) =>
  makeStyles({
    multiLineEllipsis: multiLineEllipsis(linesToShow),
  })();

export default useStyles;
