import { SxProps } from "@mui/material";
import { DispatchKeyType } from "../types";

export const ENDLINE_HEIGHT = 30;

export const defaultStyle: { [key in DispatchKeyType]: SxProps } = {
  tableStyle: {},
  titleStyle: {},
  rowStyle: {},
  columnStyle: {},
  textStyle: { fontSize: 10, fontWeight: 800 },
  hotelTextStyle: { fontSize: 10, fontWeight: 600 },
  hotelStyle: {},
  hotelColumnStyle: {},
  passengersStyle: {},
  passengerStyle: {},
  passengerTextStyle: {
    fontSize: 10,
    //
    fontWeight: 500,
  },
};
export const TableStyle: SxProps = {
  border: "1px solid #ccc",
  borderRadius: 4,
  px: 1,
  maxWidth: "96%",
  margin: "auto",
};

export const GRID_TEMPLATE_COLUMNS =
  "30px 60px 1fr 1fr 1fr 40px 60px 60px 40px 1fr 1fr 1fr 1fr 20px";

export const headerRow: SxProps = {
  display: "grid",
  gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
  "& .HeaderRow-column": {
    textAlign: "center",
    fontSize: 11,
    fontWeight: "bold",
  },
};

export const titleStyle: SxProps = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  height: 90,
  fontWeight: "bold",
  justifyContent: "center",
};

export const rowStyle: SxProps = {
  borderTop: "1px solid #aaa",
  display: "grid",
  gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
};

export const columnStyle: SxProps = {
  textAlign: "center",
  overflow: "hidden",
};

export const textStyle: SxProps = { fontSize: 12 };
export const hotelStyle: SxProps = {
  display: "grid",
  gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
};
export const passengersStyle: SxProps = {
  display: "grid",
  gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
};
export const passengerStyle: SxProps = {
  textAlign: "center",
};

export const ROW_TITLE = [
  "hotel",
  "voucher",
  "flight",
  "passenger",
  "flight-time",
  "pax",
  "check-in",
  "check-out",
  "depar.",
  "leader",
  "car",
  "arr.guide",
  "room-type",
  "info",
] as const;
