/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @fullcalendar components
import FullCalendar, { CustomContentGenerator, EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { Box, SxProps, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";

// Declaring props types for the Calender
interface Props {
  header?: {
    title?: string;
    date?: string;
  };
  [key: string]: any;
  sx?: SxProps;
  dateClick?: (arg: DateClickArg) => void;
  eventClicked?: (e: any) => void;
}

function Calendar({ eventClicked = () => {}, sx, dateClick, header, ...rest }: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const calendarRef = useRef(null);
  const { pathname } = useLocation();

  const validClassNames = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];
  useEffect(() => {
    if (pathname === "/reservation/board") {
      const fromDate = sessionStorage.getItem("from");
      calendarRef.current
        .getApi()
        .gotoDate(fromDate ? moment(fromDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"));
    }
  }, [sessionStorage.getItem("from")]);
  const events = rest.events
    ? rest.events.map((el: any) => ({
        ...el,
        className: validClassNames.find((item) => item === el.className)
          ? `event-${el.className}`
          : "event-info",
      }))
    : [];
  function renderEventContent(eventInfo: any) {
    return (
      <div style={{ width: "100%" }} onClick={() => eventClicked(eventInfo?.event?.start)}>
        {/* <b>{eventInfo?.title}</b> */}
        <Box textAlign="center" fontSize={11}>
          {eventInfo?.event?.title}
        </Box>
      </div>
    );
  }
  return (
    <Card sx={{ height: "100%", ...sx }}>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          ref={calendarRef}
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          eventContent={renderEventContent}
          height="100%"
          dateClick={dateClick}
        />
      </CalendarRoot>
    </Card>
  );
}

// Declaring default props for Calendar
Calendar.defaultProps = {
  // header: {
  //   title: "",
  //   date: "",
  // },
  headerToolbar: {
    start: "dayGridMonth,timeGridWeek,timeGridDay",
    center: "title",
    end: "prev,next",
  },
  footerToolbar: {
    center: "",
    end: "prevYear,nextYear",
  },
};

export default Calendar;
