import { useCallback, useState } from "react";
import { LeaderType } from "../types";
import { FlightRowType, HotelRowType, PageTableType, PassengerRowType } from "../Table/types";
import { convertCmToPx } from "../Table/utils";
import { addEndline, addRow } from "./utils";
import { PageDataType } from "./types";
import { ENDLINE_HEIGHT } from "../Table/row/constant";

interface PropsType {
  styles: any;
  leaders: LeaderType[];
  headHeight?: number;
  endLineHeight?: number;
}

const usePrint = ({
  styles,
  leaders,
  headHeight = 90 + 30,
  endLineHeight = ENDLINE_HEIGHT,
}: PropsType) => {
  const [data, setData] = useState<PageTableType[]>();
  const prepareTable = useCallback(() => {
    const rowHeight = styles.textStyle.fontSize * 1.625;
    const hotelHeight = styles.hotelTextStyle.fontSize * 1.625;
    const passengerHeight = styles.passengerTextStyle.fontSize * 1.625;
    const pageHeight = convertCmToPx(+styles.tableStyle.height.replace("cm", "")) - headHeight;
    const newLeaders = [...leaders];
    let tables: PageTableType[] = [];
    var page = 0;
    newLeaders.forEach((leader) => {
      let pageData: PageDataType = {
        adds: 0,
        index: 0,
        page: page + 1,
        table: { leader, pages: [{ page: page + 1, row: [] }] },
      };

      leader.flights.forEach((flight) => {
        const newFlightRow: FlightRowType = { ...flight, type: "flight" };

        pageData = addRow(pageData, pageHeight, newFlightRow, rowHeight, newFlightRow, rowHeight);

        flight.transfers.forEach((transfer) => {
          transfer.paxs.forEach((pax) => {
            const newHotelRow: HotelRowType = { pax, transfer, flight, type: "hotel" };

            pageData = addRow(
              pageData,
              pageHeight,
              newHotelRow,
              hotelHeight,
              newFlightRow,
              rowHeight
            );

            if (Array.isArray(pax.passengers)) {
              pax.passengers.forEach((passenger) => {
                const newPassengerRow: PassengerRowType = { ...passenger, type: "passenger" };

                pageData = addRow(
                  pageData,
                  pageHeight,
                  newPassengerRow,
                  passengerHeight,
                  newFlightRow,
                  rowHeight
                );
              });
            } else {
              const newPassengerRow: PassengerRowType = { ...pax.passengers, type: "passenger" };

              pageData = addRow(
                pageData,
                pageHeight,
                newPassengerRow,
                passengerHeight,
                newFlightRow,
                rowHeight
              );
            }
          });
        });
      });

      pageData = addEndline(
        pageData,
        pageHeight,
        endLineHeight,
        rowHeight,
        hotelHeight,
        passengerHeight
      );

      tables.push(pageData.table);
    });

    setData(tables);
  }, [styles, leaders]);

  const reset = () => {
    setData(undefined);
  };

  return {
    data,
    reset,
    prepareTable,
  };
};

export default usePrint;
