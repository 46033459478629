import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";
import { useAppSelector } from "../hooks";

export interface CommonStateTypes {
  scroll: number;
}

const initialState: CommonStateTypes = {
  scroll: 0,
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateScroll: (state: CommonStateTypes, action: PayloadAction<number>) => {
      state.scroll = action.payload;
    },
  },
});

//
export const { updateScroll } = common.actions;
//
// call in component
export const SELECT_SCROLL = () => useAppSelector((state: AppState) => state.common.scroll);

export default common.reducer;
