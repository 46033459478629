import { SxProps } from "@mui/material";
import useStyles from "components/customs/core/useStyles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { RowTitleType } from "../../types";
import { headerRow, ROW_TITLE } from "../constant";

interface PropsType extends RowTitleType {
  sx?: SxProps;
}

const HeaderRow = ({ sx, ...items }: PropsType) => {
  const classes = useStyles({ linesToShow: 1 });

  return (
    <MDBox sx={headerRow}>
      {ROW_TITLE.map((v, index) => (
        <MDTypography key={index} className={`HeaderRow-column ${classes.multiLineEllipsis}`}>
          {items[v] ? v : null}
        </MDTypography>
      ))}
    </MDBox>
  );
};

export default HeaderRow;
