import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Post from "lib/api/post";
import { SELECT_USER } from "store/slices/profile";
import { ageData } from "./helper";
import FlightFilter from "./FlightFilter";
import {
  AGE,
  ARRIVAL,
  CANCEL,
  CONFIRM,
  CURRENCY,
  DEPARTURE,
  PRICE,
  SELECT_TIME,
  SELL_PRICE,
  TRANSFER,
  VIP,
  VIP_TRANSFER,
  VIT_CONFIRM,
} from "./const";
import MDDatePicker from "components/MDDatePicker";
import { Switch } from "@material-ui/core";
import { useFormContext } from "../../FormContextProvide";
import { useRequirmentContext } from "../../RequirmentContextProvider";
import moment from "moment";
import { initialPassenger } from "../../HotelTab/components/helper";
import { useAddPassengerContext } from "../../AddPassengerContextProvider";

type Props = {
  id: string | number;
  roomIndex: number;
  passengerIndex: number;
  setInContext: any;
};

const PassengerInfo = ({ id, roomIndex, passengerIndex, setInContext }: Props) => {
  //redux
  const user = SELECT_USER();

  /**********************hooks *************/
  //context
  const { formContext, setFormContext } = useFormContext();
  const { requirmentContext } = useRequirmentContext();
  const {
    addPassengerContext,
    setAddPassengerContext,
    dataAddPassengerContext,
    setDataAddPassengerContext,
  } = useAddPassengerContext();
  //states
  const [userState, setuserState] = useState("");
  const [userList, setUserList] = useState<{ label: string; user_id: string }[] | []>([]);
  const [confirmVIPData, setConfirmVIPData] = useState<{ isOpen: boolean; name: string }>({
    isOpen: false,
    name: "",
  });
  //formik
  const formik = useFormik({
    initialValues: {
      sell_price: 0,
      selectedUser: { user_id: null, label: "" },
      age_type: "adult",
      arrivalFilterValue: null,
      arrival_time: "",
      arrival_transfer: false,
      arrival_transfer_vip: false,
      departure_transfer_vip: false,
      departure_transfer: false,
      departureFilterValue: "" as any,
      departure_time: "",
      arrival_transfer_price: null,
      transferPriceCurrency: "2",
      transferPriceCurrencyCode: "USD",
      departure_transfer_price: "",
    },
    onSubmit: (value) => {},
  });
  //lifecycle
  useEffect(() => {
    const passengerValues = formContext?.rooms?.[roomIndex]?.passengers?.[passengerIndex];
    if (!passengerValues?.age_type) {
      setInContext("age_type", "adult", roomIndex, passengerIndex, initialPassenger);
    }
    formik.setValues({
      ...passengerValues,
      selectedUser: { user_id: passengerValues.user_id, label: passengerValues.label },
      arrival_time: passengerValues?.arrival_time,
      departure_time: passengerValues?.departure_time,
      passengerSellPrice: passengerValues?.passengerSellPrice,
      arrivalFilterValue: {
        flight_id: passengerValues?.arrival_flight,
        flight_code: passengerValues?.arrival_flight_code,
        flight_origin: passengerValues?.arrival_flight_origin,
        flight_destination: passengerValues?.arrival_flight_destination,
      },
      departureFilterValue: {
        flight_id: passengerValues?.departure_flight,
        flight_code: passengerValues?.departure_flight_code,
        flight_origin: passengerValues?.departure_flight_origin,
        flight_destination: passengerValues?.departure_flight_destination,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext, passengerIndex, roomIndex]);
  /***************handlers ****************/
  const loadUsers = async (value: string) => {
    const payload = {
      user_id: user?.uid,
      session_id: user?.session_id,
      Box,
      key: value,
      page: 1,
      count: 15,
    };
    await Post.usersList(payload)
      .then((res: any) => {
        setUserList(() =>
          res?.data?.users.map((element: any) => {
            return {
              label: element.fullname,
              user_id: element.user_id,
            };
          })
        );
      })
      .catch((err) => {});
  };

  const handleOpenVip = (name: string) => {
    setConfirmVIPData({ isOpen: true, name: name });
  };
  const handleConfirmVip = (name: string) => {
    if (name === "arrival_transfer_vip") {
      formik.setFieldValue("arrival_transfer_vip", !formik.values.arrival_transfer_vip);
      setInContext(
        "arrival_transfer_vip",
        !formik.values.arrival_transfer_vip,
        roomIndex,
        passengerIndex,
        initialPassenger
      );
    } else if (name === "departure_transfer_vip") {
      formik.setFieldValue("departure_transfer_vip", !formik.values.departure_transfer_vip);
      setInContext(
        "departure_transfer_vip",
        !formik.values.departure_transfer_vip,
        roomIndex,
        passengerIndex,
        initialPassenger
      );
    }
  };

  return (
    <Card sx={{ mb: 2, p: 1, border: "1px solid #f1f1f1" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl required fullWidth>
              <Autocomplete
                sx={{ "& .MuiAutocomplete-endAdornment": { top: 0 } }}
                value={formik?.values?.selectedUser}
                isOptionEqualToValue={(option, value) => option?.user_id === value?.user_id}
                id="select_passenger"
                onChange={(e, newValue: any) => {
                  formik.setFieldValue("selectedUser", newValue);
                }}
                onInputChange={(event: any, newInputValue: string) => loadUsers(newInputValue)}
                options={userList}
                onBlur={() => {
                  setInContext(
                    "label",
                    formik.values.selectedUser.label,
                    roomIndex,
                    passengerIndex,
                    initialPassenger
                  );
                  setInContext(
                    "user_id",
                    formik.values.selectedUser.user_id,
                    roomIndex,
                    passengerIndex,
                    initialPassenger
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Passenger Name"
                    variant="outlined"
                    type="text"
                    name="userState"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                    onChange={(e: any) => {
                      formik.setFieldValue("selectedUser", {
                        label: e.target.value,
                        user_id: user?.session_id,
                      });
                    }}
                    onBlur={(e: any) => {
                      setuserState(e.target.value);
                      const usersFound =
                        dataAddPassengerContext !== undefined &&
                        dataAddPassengerContext?.userList?.length !== 0
                          ? dataAddPassengerContext?.userList.map((user: any) => {
                              return user && user;
                            })
                          : [];
                      usersFound &&
                        setDataAddPassengerContext({
                          initialPassenger,
                          userList: [...usersFound, ...userList],
                        });
                      const checkAdd = formContext.rooms[roomIndex].passengers.find(
                        (item: any) => item?.selectedUser?.label === e.target.value
                      );
                      const checkBeforeUser = userList.find(
                        (item: any) => item.user_id === user?.uid && item.name === e.target.value
                      );
                      if (!checkAdd) {
                        if (
                          addPassengerContext.length >=
                          formContext?.rooms[roomIndex]?.passengers?.length
                        ) {
                          setInContext(
                            "selectedUser",
                            {
                              label: e.target.value,
                              user_id: formik.values.selectedUser.user_id
                                ? formik.values.selectedUser.user_id
                                : user?.uid,
                            },
                            roomIndex,
                            passengerIndex,
                            initialPassenger
                          );
                        } else {
                          setInContext(
                            "selectedUser",
                            {
                              label: e.target.value,
                              user_id: formik.values.selectedUser.user_id
                                ? formik.values.selectedUser.user_id
                                : user?.uid,
                            },
                            roomIndex,
                            passengerIndex,
                            initialPassenger
                          );
                        }
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="age_type">{AGE}</InputLabel>
              <Select
                native
                open={true}
                label={AGE}
                fullWidth
                name="age_type"
                id="age_type"
                value={formik.values.age_type}
                defaultValue="adult"
                onChange={(e: any) => formik.handleChange(e)}
                onBlur={() =>
                  setInContext(
                    "age_type",
                    formik.values.age_type,
                    roomIndex,
                    passengerIndex,
                    initialPassenger
                  )
                }
              >
                {ageData?.map((item: any) => (
                  <option value={item.id}>{item.label}</option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {formContext?.rooms[roomIndex]?.passengerSellPrice && (
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  required
                  label={SELL_PRICE}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  name="sell_price"
                  id="sell_price"
                  value={formik.values.sell_price}
                  onBlur={() =>
                    setInContext(
                      "sell_price",
                      formik.values.sell_price,
                      roomIndex,
                      passengerIndex,
                      initialPassenger
                    )
                  }
                  onChange={formik.handleChange}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <Stack direction="column" sx={{ px: 1 }} spacing={1.25}>
              <Stack>
                <Box display="flex">
                  <FlightFilter
                    setFlightValue={(item) => {
                      formik.setFieldValue("arrivalFilterValue", item);
                      setInContext(
                        "arrivalFilterValue",
                        item,
                        roomIndex,
                        passengerIndex,
                        initialPassenger
                      );
                    }}
                    flightValue={formik.values.arrivalFilterValue}
                    title={ARRIVAL}
                  />
                  {formik.values.arrivalFilterValue?.flight_id && (
                    <Box pt={1.2} ml={2}>
                      <Chip
                        label={formik.values.arrivalFilterValue?.flight_origin}
                        color="secondary"
                        sx={{ mr: 1 }}
                        size="small"
                      />
                      <Chip
                        label={formik.values.arrivalFilterValue?.flight_destination}
                        color="info"
                        size="small"
                      />
                    </Box>
                  )}
                </Box>

                {formik.values.arrivalFilterValue?.flight_id && (
                  <MDDatePicker
                    label={SELECT_TIME}
                    input={{
                      placeholder: SELECT_TIME,
                      sx: { width: "100%" },
                    }}
                    value={formik.values.arrival_time}
                    name="arrival_time"
                    options={{
                      mode: "single",
                      onChange: (e: any) => {
                        if (e?.[0]) {
                          setInContext(
                            "arrival_time",
                            moment(e?.[0]).format("YYYY-MM-DD"),
                            roomIndex,
                            passengerIndex,
                            initialPassenger
                          );
                          formik.setFieldValue("arrival_time", moment(e?.[0]).format("YYYY-MM-DD"));
                        }
                      },
                    }}
                  />
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.arrival_transfer}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setInContext(
                          "arrival_transfer",
                          e.target.checked,
                          roomIndex,
                          passengerIndex,
                          initialPassenger
                        );
                      }}
                      name="arrival_transfer"
                      color="primary"
                    />
                  }
                  label={TRANSFER}
                />
                {formik.values.arrival_transfer && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.arrival_transfer_vip}
                        name="arrival_transfer_vip"
                        color="primary"
                        onChange={() => handleOpenVip("arrival_transfer_vip")}
                      />
                    }
                    label={VIP}
                  />
                )}
              </Stack>
              {Boolean(formik.values.arrival_transfer) && (
                <Stack direction="column" spacing={1.25}>
                  <FormControl fullWidth required sx={{ mb: 1 }}>
                    <TextField
                      required
                      label={PRICE}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="number"
                      name="arrival_transfer_price"
                      id="arrival_transfer_price"
                      value={formik.values.arrival_transfer_price}
                      onBlur={() =>
                        setInContext(
                          "arrival_transfer_price",
                          formik.values.arrival_transfer_price,
                          roomIndex,
                          passengerIndex,
                          initialPassenger
                        )
                      }
                      onChange={formik.handleChange}
                    />
                  </FormControl>

                  <FormControl fullWidth required>
                    <InputLabel id="transferPriceCurrency1">{CURRENCY}</InputLabel>
                    <Select
                      disabled={!Boolean(formik.values.arrival_transfer)}
                      native
                      required
                      label={CURRENCY}
                      fullWidth
                      name="transferPriceCurrency"
                      id="transferPriceCurrency1"
                      value={formik.values.transferPriceCurrency}
                      onChange={formik.handleChange}
                      onBlur={() =>
                        setInContext(
                          "transferPriceCurrency",
                          formik.values.transferPriceCurrency,
                          roomIndex,
                          passengerIndex,
                          initialPassenger
                        )
                      }
                    >
                      {requirmentContext?.currencies?.map((item: any) => (
                        <option value={item.id}>{item.code}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="column" sx={{ px: 1 }} spacing={1.25}>
              <Stack>
                <Box display="flex">
                  <FlightFilter
                    setFlightValue={(item) => {
                      formik.setFieldValue("departureFilterValue", item);
                      setInContext(
                        "departureFilterValue",
                        item,
                        roomIndex,
                        passengerIndex,
                        initialPassenger
                      );
                    }}
                    flightValue={formik.values.departureFilterValue}
                    title={DEPARTURE}
                  />
                  {formik.values.departureFilterValue?.flight_id && (
                    <Box pt={1.2} ml={2}>
                      <Chip
                        label={formik.values.departureFilterValue?.flight_origin}
                        color="secondary"
                        sx={{ mr: 1 }}
                        size="small"
                      />
                      <Chip
                        label={formik.values.departureFilterValue?.flight_destination}
                        color="info"
                        size="small"
                      />
                    </Box>
                  )}
                </Box>
                {formik.values.departureFilterValue?.flight_id && (
                  <MDDatePicker
                    label={SELECT_TIME}
                    input={{ sx: { width: "100%" } }}
                    name="departure_time"
                    id="departure_time"
                    value={formik.values.departure_time}
                    options={{
                      mode: "single",
                      onChange: (e: any) => {
                        if (e?.[0]) {
                          setInContext(
                            "departure_time",
                            moment(e?.[0]).format("YYYY-MM-DD"),
                            roomIndex,
                            passengerIndex,
                            initialPassenger
                          );
                          formik.setFieldValue(
                            "departure_time",
                            moment(e?.[0]).format("YYYY-MM-DD")
                          );
                        }
                      },
                    }}
                  />
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.departure_transfer}
                      // value={values.arrival_transfer}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setInContext(
                          "departure_transfer",
                          e.target.checked,
                          roomIndex,
                          passengerIndex,
                          initialPassenger
                        );
                      }}
                      name="departure_transfer"
                      color="primary"
                    />
                  }
                  label={TRANSFER}
                />
                {formik.values.departure_transfer && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.departure_transfer_vip}
                        name="departure_transfer_vip"
                        color="primary"
                        onChange={() => handleOpenVip("departure_transfer_vip")}
                      />
                    }
                    label={VIP}
                  />
                )}
              </Stack>
              {Boolean(formik.values.departure_transfer) && (
                <Stack direction="column" spacing={1.25}>
                  <FormControl fullWidth required sx={{ mb: 1 }}>
                    <TextField
                      required
                      label={PRICE}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="number"
                      variant="outlined"
                      name="departure_transfer_price"
                      value={formik.values.departure_transfer_price}
                      onChange={formik.handleChange}
                      onBlur={() =>
                        setInContext(
                          "departure_transfer_price",
                          formik.values.departure_transfer_price,
                          roomIndex,
                          passengerIndex,
                          initialPassenger
                        )
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth required>
                    <InputLabel id="transferPriceCurrency2">{CURRENCY}</InputLabel>
                    <Select
                      native
                      label={CURRENCY}
                      disabled={!Boolean(formik.values.departure_transfer)}
                      required
                      fullWidth
                      name="transferPriceCurrency"
                      id="transferPriceCurrency2"
                      value={formik.values.transferPriceCurrency}
                      onChange={formik.handleChange}
                      onBlur={() =>
                        setInContext(
                          "transferPriceCurrency",
                          formik.values.transferPriceCurrency,
                          roomIndex,
                          passengerIndex,
                          initialPassenger
                        )
                      }
                    >
                      {requirmentContext?.currencies?.map((item: any) => (
                        <option value={item.id}>{item.code}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={confirmVIPData?.isOpen}
        onClose={() => setConfirmVIPData({ isOpen: false, name: "" })}
      >
        <DialogTitle>{VIP_TRANSFER}</DialogTitle>
        <DialogContent>
          <DialogContentText>{VIT_CONFIRM}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmVIPData({ isOpen: false, name: "" })}>{CANCEL}</Button>
          <Button
            onClick={() => {
              handleConfirmVip(confirmVIPData?.name);
              setConfirmVIPData({ isOpen: false, name: "" });
            }}
          >
            {CONFIRM}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default PassengerInfo;
