export const HOTEL_NOTE = "Hotel Note";
export const OPERATION_NOTE = "Operation Note";
export const AGENCY_NOTE = "Agency Note";
export const VOUCHER_IMAGE = "Voucher Image";
export const IMAGES = "Images";
export const TITLE = "Title";
export const BUY_PRICE = "Buy Price";
export const SELL_PRICE = "Sell Price";
export const RESERVATION_EXTRA_COSTS = "Reservation Extra Costs";
export const SELECT = "Select";
export const TYPE = "Type";
export const CURRENCY = "Currency";
export const HOTEL = "Hotel";
