import { Axios } from "utils/axios";

export interface DeleteItem {
  hotel_id?: number;
  contact_id?: number;
}

const deleteContact = (session_id: number, user_id: number, contact_id: number) => {
  // Doesn't work !!!
  return Axios.delete(`hotels/removeContact`, {
    method: "delete",
    url: "hotels/removeContact",
    data: {
      contact_id,
      session_id,
      user_id,
    },
  });
};

const deleteRoom = (session_id: number, user_id: number, room_id: number) => {
  // Doesn't work !!!
  return Axios.delete(`hotels/removeRoom`, {
    method: "delete",
    url: "hotels/removeRoom",
    data: {
      room_id,
      session_id,
      user_id,
    },
  });
};

const Delete = {
  deleteRoom,
  deleteContact,
};

export default Delete;
