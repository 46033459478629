import { DateClickArg } from "@fullcalendar/interaction";
import { SxProps } from "@mui/material";
import MDBox from "components/MDBox";
import EventCalendar from "examples/Calendar";
import { CalendarEventType } from "lib/types/calenderType";

interface PropsType {
  events: CalendarEventType[];
  sx?: SxProps;
  dateClick?: (arg: DateClickArg) => void;
  eventClicked?: (arg: DateClickArg) => void;
}

const Calendar = ({ events, dateClick, eventClicked, sx }: PropsType) => {
  return (
    <EventCalendar
      sx={{ ...sx }}
      initialView="dayGridMonth"
      initialDate={new Date()}
      events={events}
      selectable
      editable
      eventClicked={eventClicked}
      dateClick={dateClick}
    />
  );
};

export default Calendar;
