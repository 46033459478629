import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useFormContext } from "../../FormContextProvide";

const TotalPrices = () => {
  const { formContext } = useFormContext();

  

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ display: "contents" }}>
            <TableRow>
              <TableCell align="left">#</TableCell>
              <TableCell align="left">{"Title"}</TableCell>
              <TableCell align="right">{"Totals"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formContext?.extras?.map((extra: any, key: number) => (
              <TableRow
                key={"passenger.id"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">#{key + 1}</TableCell>
                <TableCell align="left"> {extra?.title} </TableCell>
                <TableCell align="right">
                  {`${Number(extra?.sellPrice).toLocaleString()} ${extra?.sellPriceCurrencyCode}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TotalPrices;
