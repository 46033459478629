import React, { useState, createContext, Dispatch, SetStateAction, useContext } from "react";

type AddPassengerContextProps = any;

type ContextValue = {
  addPassengerContext: AddPassengerContextProps;
  setAddPassengerContext: Dispatch<SetStateAction<AddPassengerContextProps>>;
  dataAddPassengerContext: AddPassengerContextProps;
  setDataAddPassengerContext: Dispatch<SetStateAction<AddPassengerContextProps>>;
};

const AddPassengerContext = createContext<ContextValue | undefined>(undefined);

const AddPassengerContextProvider: React.FC = ({ children }) => {
  const [addPassengerContext, setAddPassengerContext] = useState<AddPassengerContextProps>([]);
  const [dataAddPassengerContext, setDataAddPassengerContext] = useState<AddPassengerContextProps>({userList:[]});

  return (
    <AddPassengerContext.Provider value={{ addPassengerContext, setAddPassengerContext, dataAddPassengerContext, setDataAddPassengerContext }}>
      {children}
    </AddPassengerContext.Provider>
  );
};

export default AddPassengerContextProvider;

export const useAddPassengerContext = () => {
  const context = useContext(AddPassengerContext);
  if (!context) {
    throw new Error("useaddPassengerContext must be used inside the AddPassengerContextProvider");
  }
  return context;
};
