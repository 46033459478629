import { Axios } from "utils/axios";
import { RoomType, UpdateStatusFormType } from "./types";

export interface setFlightTime {
  date: string;
  flight_id: number | string;
  session_id: number;
  time: string;
  user_id: number;
}
const setFlightTime = (form: setFlightTime) => Axios.put("/operations/setFlightTime", form);

export interface SetHotelTime {
  date: string;
  flight_id: number;
  hotel_id: number;
  session_id: number;
  time: string;
  user_id: number;
}

const setHotelTime = (form: SetHotelTime) => Axios.put("/operations/setHotelTime", form);

export interface SetOperationForm {
  date: string;
  session_id: number;
  user_id: number;
  content: string;
}

const setOperation = (form: SetOperationForm) => Axios.put("/operations/setOperation", form);

const updateContact = (form: RoomType) => Axios.put(`/hotels/updateContact`, form);

const updateRoom = (form: RoomType) => Axios.put(`/hotels/updateRoom`, form);

const updateHotel = (form: any) => Axios.put(`/hotels/updateHotel`, form);

const updateReservation = (form: any) => Axios.put(`/reservations/updateReservation`, form);

export const updateStatus = (form: UpdateStatusFormType) =>
  Axios.put("/reservations/updatestatus", form);

const Put = {
  setFlightTime,
  setHotelTime,
  setOperation,
  updateContact,
  updateRoom,
  updateHotel,
  updateStatus,
  updateReservation,
};

export default Put;
