import { FlightTimeType, FlyType } from "lib/types/api-type";
import _ from "lodash";

export const updateFlies = (data: FlyType[], flightTimes: FlightTimeType[]) => {
  if (data?.length && flightTimes.length) {
    const newdata = data.map((d) => {
      const flightTime = flightTimes?.find((f) => f.flight_id == d.flight_id);
      let newD = { ...d };
      if (flightTime) {
        newD = { ...newD, time: flightTime.flight_time };
      }
      return newD;
    });
    return [...newdata];
  } else {
    return [...data];
  }
};
